import React, { useState } from 'react'
import { Box, Typography, TextField, Checkbox, FormControlLabel, Button } from '@mui/material'
import { styled, useTheme } from '@mui/styles'
import Spinner from 'core/Spinner'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'

const Input = styled(TextField)(({ theme }) => ({
  width: '100%',
  borderRadius: 0,
  marginTop: 4
}))

const SignupSchema = Yup.object().shape({
  email: Yup.string().email('Please enter a valid email').required('Please enter a valid email'),
  'privacy-policy': Yup.boolean().test('is true', 'You must accept T&Cs, cookies and privacy policy before continuing', (boolean) => boolean === true).required('You must accept T&Cs, cookies and privacy policy before continuing')
})
const EmailInputForm = ({ sendEmail, loading, emailSent }) => {
  const theme = useTheme()

  return (
    <Box sx={{ mb: 2, p: 2, border: '2px solid #2E2A26', borderRadius: '20px', minHeight: 110, position: 'relative', display: 'flex', flexDirection: 'column' }}>
        <Typography sx={{ color: '#575757', pb: 1 }} fontFamily="MundialLight" fontSize={18}>Please enter your email so that you can receive a list of the wines you’ve just tasted</Typography>

        <Formik initialValues={{
          email: '',
          'privacy-policy': false,
          'receive-marketing': false
        }}
            validationSchema={SignupSchema}
            onSubmit={(values) => {
              sendEmail(values)
            }}
        >
            {
                ({ errors, touched, values, handleChange }) => (
                    <Form>
                        <Box sx={{ mb: !errors['privacy-policy'] && 3 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography fontFamily="KaiseiDecolBold" fontSize={18} sx={{ color: '#2e2a26' }}>Email</Typography>
                                {
                                    touched.email && errors.email &&
                                    <Box sx={{ display: 'flex', width: 1, alignItems: 'center', mt: 0.2 }}>
                                        <Box component="img" src="/email/errorIcon.svg" alt="error-icon" sx={{ mx: 1 }} />
                                        <Typography fontFamily="MundialDemiBold" fontSize={16} sx={{ color: '#ea4928' }}>{errors.email}</Typography>
                                    </Box>
                                }
                            </Box>
                            <Input name="email"
                            onChange={handleChange}
                            value={values.email}
                            id="outlined-basic" placeholder="Type in..." sx={{
                              '& .MuiOutlinedInput-root': {
                                input: {
                                  padding: 1.5,
                                  fontSize: 16,
                                  fontFamily: 'MundialLight'
                                },
                                fieldset: {
                                  border: '2px solid gray',
                                  padding: 0
                                },
                                '&:hover fieldset': {
                                  border: '2px solid gray'
                                },
                                '&.Mui-focused fieldset': {
                                  border: '2px solid gray'
                                }
                              }
                            }}/>
                        </Box>

                        {
                            touched['privacy-policy'] && errors['privacy-policy'] &&
                            <Box sx={{ display: 'flex', width: 1, alignItems: 'center', position: 'relative', minHeight: 50, mt: -1, mb: 1 }}>
                                <Box component="img" src="/email/errorIcon.svg" alt="error-icon" sx={{ mr: 1 }} />
                                <Typography fontFamily="MundialDemiBold" fontSize={16} sx={{ color: '#ea4928', position: 'absolute', top: 13, left: 20 }}>{errors['privacy-policy']}</Typography>
                            </Box>
                        }
                        <FormControlLabel sx={{ position: 'relative', width: 1, [theme.breakpoints.up('sm')]: { mt: 0 }, mt: 2 }} control={
                            <Checkbox sx={{
                              '& .MuiSvgIcon-root': { fontSize: 28 },
                              '&.Mui-checked': {
                                color: 'secondary.main'
                              }
                            }}
                            onChange={handleChange}
                            value={values['privacy-policy']}
                            name="privacy-policy"/>
                        } label={<Typography sx={{ position: 'absolute', left: 45, top: 9 }} fontFamily="MundialLight" fontSize={18}>I’ve read and accepted the T&Cs, Cookies and Privacy Policy</Typography>} />
                        <FormControlLabel sx={{
                          [theme.breakpoints.up('sm')]: { mt: 3 },
                          mt: 6,
                          position: 'relative'
                        }} control={
                            <Checkbox sx={{
                              '& .MuiSvgIcon-root': { fontSize: 28 },
                              '&.Mui-checked': {
                                color: 'secondary.main'
                              }
                            }}
                            onChange={handleChange}
                            value={values['receive-marketing']}
                            name="receive-marketing"/>
                        } label={<Typography sx={{ }} fontFamily="MundialLight" fontSize={18}>I want to receive marketing emails</Typography>} />

                        {
                            emailSent
                              ? <Button sx={{ display: 'flex', margin: 'auto', marginTop: 3, px: 2, marginBottom: 1, width: 'fit', borderRadius: '20px', bgcolor: ' #E5DED8' }} disabled>
                                    <Box component="img" src="/email/tickIcon.svg" alt="tick" sx={{ mr: 1 }} />
                                    <Typography fontFamily="MundialDemiBold" fontSize={16} sx={{ color: '#2e2a26' }}>Email sent</Typography>
                                </Button>
                              : (
                                  loading
                                    ? <Spinner />
                                    : <Button type="submit" sx={{ display: 'flex', margin: 'auto', marginTop: 3, marginBottom: 1, width: '50%', borderRadius: '20px', bgcolor: '#2e2a26', '&:hover': { bgcolor: '#2e2a26' } }}>
                                    <Typography fontFamily="MundialDemiBold" fontSize={16}>Send email</Typography>
                                </Button>
                                )
                        }
                    </Form>
                )
            }
        </Formik>

    </Box>
  )
}

export default EmailInputForm
