import React, { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { Box, Typography, Slide, Dialog, IconButton, Divider, Grid, Container } from '@mui/material'
import { useTheme } from '@mui/styles'
import characteristics from './characteristics'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const Characteristics = ({ open, setOpen, charac }) => {
  const theme = useTheme()
  const [infoHeight, setInfoHeight] = useState()
  const handleClose = () => {
    setOpen(false)
  }

  const handlePopUpView = () => {
    // console.log("height props", window.innerHeight)
    if (window.innerHeight < 650) {
      setInfoHeight('100%')
    } else if (window.innerHeight > 650) {
      setInfoHeight('70%')
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handlePopUpView)
  }, [])

  return (
    <Box sx={{
      height: '100%',
      display: 'flex',
      flexDirection: 'column'
    }}>

       <Dialog
        fullScreen={theme.popUpsWidth}
        sx={{
          display: 'flex',
          width: '100vw',
          // maxWidth: '450px',
          m: 'auto',

          '.MuiBackdrop-root': {
            width: '100vw',
            // maxWidth: '450px',
            m: 'auto'
          },
          '.MuiDialog-container': {
            display: 'flex',
            alignItems: 'flex-end'
          },
          '.MuiPaper-root': {
            width: '100vw',
            // maxWidth: '450px',
            bgcolor: 'transparent'
          }
          // '.MuiDialog-paper': { ml:-5 },
        }}
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
      <Container disableGutters sx={{
        '&.MuiContainer-root': {
          width: '100vw', maxWidth: '450px', minHeight: 'min-content', bgcolor: 'transparent'
        }
      }}>
      <Box sx={{
        width: 1,
        height: 1,
        mt: '250px',
        bgcolor: 'primary.main',
        borderTopLeftRadius: '25px',
        borderTopRightRadius: '25px'
      }}>

          <Box sx={{ width: 1, mb: 2 }} >
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
                sx={{ mr: 2, mt: 2 }}
                >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>

            <Box sx={{ mx: 4, mb: 1 }}>
              <Typography fontFamily="KaiseiDecolBold" fontSize={32} sx={{ mt: -2 }} >{characteristics[charac].title}</Typography>
              <Typography fontFamily="MundialRegular" fontWeight={400} fontSize={18} sx={{ color: '#575757' }}>{characteristics[charac].subtitle}</Typography>
            </Box>

          <Divider />

            <Box sx={{ mx: 4, mb: 4, position: 'relative' }}>
              <Grid container sx={{ my: 2 }}>
                <Grid item xs={12} sx={{ mb: 4 }} >
                  <Grid container >
                    <Grid item xs={2}> <Box sx={{ width: '25px', height: '25px', bgcolor: '#2E2A26', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '100%', ml: 1, color: 'white', fontWeight: 700, fontSize: 'small' }} >1</Box> </Grid>
                    <Grid item xs={10}>
                      <Typography fontFamily="MundialDemiBold" fontSize={16} sx={{ color: '#575757' }}>{characteristics[charac].points[0]}</Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Box sx={[
                  { position: 'absolute', left: 20 },
                  charac === 'body' ? { top: 53 } : { top: 43 }
                ]}>
                  <Box sx={{ width: 2, height: 4, my: 0.5, bgcolor: '#ea4928', borderRadius: '20px' }}/>
                  <Box sx={{ width: 2, height: 4, my: 0.5, bgcolor: '#ea4928', borderRadius: '20px' }}/>
                  <Box sx={{ width: 2, height: 4, my: 0.5, bgcolor: '#ea4928', borderRadius: '20px' }}/>
                </Box>

                <Grid item xs={12} >
                  <Grid container >
                    <Grid item xs={2}> <Box sx={{ width: '25px', height: '25px', bgcolor: '#2E2A26', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '100%', ml: 1, color: 'white', fontWeight: 700, fontSize: 'small' }} >5</Box> </Grid>
                    <Grid item xs={10}>
                      <Typography fontFamily="MundialDemiBold" fontSize={16} sx={{ color: '#575757' }}>{characteristics[charac].points[1]}</Typography>
                    </Grid>
                  </Grid>

                </Grid>
              </Grid>
              {
                characteristics[charac].description.map((desc, i) =>
                <Typography fontFamily="MundialLight" fontSize={18} key={i} sx={{ py: 1, color: '#575757' }}>{desc}</Typography>
                )
              }
            </Box>

            </Box>
          </Container>

        </Dialog>
    </Box>
  )
}

export default Characteristics
