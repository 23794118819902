import { useState } from 'react'
import useApi from 'api/apiRequests'
import { useGameMode } from 'providers/GameMode'
import { useNavigate } from 'react-router-dom'

export const getRoundToPlay = (gameData, roundNumber) => {
  const roundMatch = gameData?.wines?.find(({ round }) => round === roundNumber)
  return roundMatch
}

export const checkEveryoneReadyToStart = (round) => {
  const areReady = round.players.every(player => player.isReady === true)
  if (areReady) return true
  return false
}
export const checkRoundFinished = (round) => round?.finished

export const useCheckIsPlayer = () => {
  const navigate = useNavigate()
  const { gameData, getStoragePlayerData, removeGameDataState } = useGameMode()
  const checkIsPlayerOrHost = async (isJoinModal) => {
    const playerData = await getStoragePlayerData()

    // console.log('player y game data', playerData, gameData)
    if (playerData && gameData) {
      const isActive = isPlayerActive(gameData, playerData.nickname)
      if (!isActive) removeGameDataState(navigate)
    }
    if (!playerData && !isJoinModal) removeGameDataState()
    return !playerData
  }
  return { checkIsPlayerOrHost }
}
const isPlayerActive = (gameData, player) => {
  const isHost = gameData.host.host === player
  const playerActive = gameData.players.some(pl => pl.nickName === player)
  return isHost || playerActive
}

export const useHandleRounds = () => {
  const navigate = useNavigate()
  const { startRound, removePlayerFromGame } = useApi()
  const { getMultiplayerData, deleteGameRequest, removeGameDataState, player, gameData } = useGameMode()

  const [reEntrance, setReentrance] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingModalBtn, setLoadingModalBtn] = useState(false)

  const handleStartRound = async () => {
    try {
      setLoading(true)
      const gameData = await getMultiplayerData()
      const nextRound = getRoundToPlay(gameData, gameData.round)
      const everyoneReady = checkEveryoneReadyToStart(gameData)

      let players = gameData.players.map(player => player.nickName)
      players = [...players, gameData.host.host]
      if (everyoneReady && !(nextRound.started) && !reEntrance) {
        setReentrance(true)
        const round = (await startRound(gameData.id, nextRound.id, players))?.data
        setLoading(false)
        return round
      }
      return null
    } catch (e) {
      throw new Error(e)
    }
  }
  const handleNextRound = () => {
    handleStartRound().then(round => {
      if (!round) navigate('/game-room')
      else navigate(`/multiplayer/${round.id}/scoring`)
    })
  }

  const handleDeleteGame = async () => {
    try {
      const gameData = await getMultiplayerData()
      setLoadingModalBtn(true)
      const boolean = await deleteGameRequest(gameData.id)
      setLoadingModalBtn(false)
      if (boolean) navigate('/game-mode')
    } catch (e) {
      throw new Error(e)
    }
  }
  const handleRemovePlayer = async () => {
    const gameData = await getMultiplayerData()
    setLoadingModalBtn(true)
    const boolResponse = (await removePlayerFromGame(gameData.gameId, player.nickname))?.data
    setLoadingModalBtn(false)
    if (!boolResponse) alert("There's been an error. Please try again")
    else removeGameDataState(navigate)
  }
  const handleFinish = async () => {
    setLoading(true)
    navigate(`${gameData?.winery.id}/investor-deck`)
    setLoading(false)
  }

  return { handleNextRound, handleStartRound, handleDeleteGame, handleRemovePlayer, handleFinish, loading, loadingModalBtn }
}
