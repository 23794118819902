import useWinesApi from 'firebaseKit/wines'
import React, { useEffect, createContext, useContext, useState } from 'react'
import { useWineryContext } from './WineryProvider'

const PointsContext = createContext(null)

const PointsProvider = ({ children }) => {
  const [wineToScore, setWineToScore] = useState(null)
  const [wineToRate, setWineToRate] = useState(null)

  const { wineries } = useWineryContext()

  const getWineScore = (wineName) => {
    const wineStorageObject = JSON.parse(localStorage.getItem('scoring'))
    if (wineStorageObject && wineName in wineStorageObject) {
      return wineStorageObject[wineName].score
    }
    return null
  }

  const setWineScore = (wineName, score) => {
    // console.log(wineName, score)
    const previousDataObject = JSON.parse(localStorage.getItem('scoring'))
    const previousWineData = previousDataObject && wineName in previousDataObject ? previousDataObject[wineName] : null
    localStorage.setItem('scoring', JSON.stringify({
      ...previousDataObject,
      [wineName]: {
        ...previousWineData,
        score
      }
    }))
  }

  const getWinePoints = (wineName, extraPoints) => {
    const wineStorageObject = JSON.parse(localStorage.getItem('scoring'))
    if (wineStorageObject && wineName in wineStorageObject) {
      if (extraPoints) {
        // console.log("CONTEXT---", typeof wineStorageObject[wineName].points, typeof wineStorageObject[wineName].extraPoints)
        const extraPointsValue = 'extraPoints' in wineStorageObject[wineName] ? wineStorageObject[wineName].extraPoints : 0
        return Number(wineStorageObject[wineName].points) + extraPointsValue
      } else {
        return Number(wineStorageObject[wineName].points)
      }
    }
    return null
  }

  const setWinePoints = (wineName, points, extraPoints) => {
    // console.log(wineName, points)
    const previousDataObject = JSON.parse(localStorage.getItem('scoring'))
    const previousWineData = previousDataObject && wineName in previousDataObject ? previousDataObject[wineName] : null
    if (extraPoints || extraPoints === 0) {
      localStorage.setItem('scoring', JSON.stringify({
        ...previousDataObject,
        [wineName]: {
          ...previousWineData,
          extraPoints
        }
      }))
    } else {
      localStorage.setItem('scoring', JSON.stringify({
        ...previousDataObject,
        [wineName]: {
          ...previousWineData,
          points
        }
      }))
    }
  }

  const getWineRating = (wineName) => {
    const wineStorageObject = JSON.parse(localStorage.getItem('rating'))
    if (wineStorageObject && wineName in wineStorageObject) {
      setWineToRate(wineStorageObject[wineName])
      return wineStorageObject[wineName]
    }
    return null
  }
  const setWineRating = (wineName, rate) => {
    // console.log(wineName, rate)
    const previousDataObject = JSON.parse(localStorage.getItem('rating'))
    localStorage.setItem('rating', JSON.stringify({
      ...previousDataObject,
      [wineName]: rate
    }))
  }

  const getWineNotes = (wineName) => {
    const wineStorageObject = JSON.parse(localStorage.getItem('tasting_notes'))
    if (wineStorageObject && wineName in wineStorageObject) {
      return wineStorageObject[wineName]
    }
    return null
  }
  const setWineNotes = (wineName, notes) => {
    const previousDataObject = JSON.parse(localStorage.getItem('tasting_notes'))
    localStorage.setItem('tasting_notes', JSON.stringify({
      ...previousDataObject,
      [wineName]: notes
    }))
  }

  const getWineToScoreAndRate = (wines) => {
    return wines.find(wine => {
      const notScored = getWineScore(wine.name)
      let rated = getWineRating(wine.name)
      rated = rated != null

      if (!(notScored || rated)) return wine
      return null
    })
  }

  const RemoveWineryPoints = (wineArray) => {
    const scoringObject = JSON.parse(localStorage.getItem('scoring'))
    const ratingObject = JSON.parse(localStorage.getItem('rating'))
    const scoringPreference = JSON.parse(localStorage.getItem('scoringPreference'))
    const tastingNotes = JSON.parse(localStorage.getItem('tasting_notes'))

    for (const wine of wineArray) {
      if (wine?.wineName in scoringObject) {
        delete scoringObject[wine.wineName]
      }
      if (wine?.wineName in ratingObject) {
        delete ratingObject[wine.wineName]
      }
      if (scoringPreference && wine?.winery in scoringPreference) {
        delete scoringPreference[wine.winery]
      }
      if (tastingNotes && wine?.wineName in tastingNotes) {
        delete tastingNotes[wine.wineName]
      }
    }

    localStorage.setItem('scoring', JSON.stringify({
      ...scoringObject
    }))
    localStorage.setItem('rating', JSON.stringify({
      ...ratingObject
    }))
    localStorage.setItem('scoringPreference', JSON.stringify({
      ...scoringPreference
    }))
    localStorage.setItem('tasting_notes', JSON.stringify({
      ...tastingNotes
    }))
    const winery = wineries.find(W => W.name === wineArray[0].winery)
    delete winery.fullyScored
  }

  const { getWines } = useWinesApi()

  const fullyRatedWineries = async () => {
    const totalWines = await getWines()
    const fullyRatedWineries = getWineToScoreAndRate(totalWines)
    // console.log('fullyRatedWineries', fullyRatedWineries)
    if (!fullyRatedWineries) {
      try {
        localStorage.removeItem('scoring')
        localStorage.removeItem('rating')
        localStorage.removeItem('scoringPreference')
      } catch (e) {
        localStorage.clear()
      }
    }
  }
  useEffect(() => {
    fullyRatedWineries()
  }, [])

  const value = {
    getWineScore,
    setWineScore,
    wineToScore,
    setWineToScore,

    getWinePoints,
    setWinePoints,

    getWineRating,
    setWineRating,

    getWineNotes,
    setWineNotes,

    wineToRate,
    setWineToRate,

    RemoveWineryPoints,
    fullyRatedWineries
  }

  return (
    <PointsContext.Provider value={value}>{ children }</PointsContext.Provider>
  )
}

export default PointsProvider

export const usePointsContext = () => {
  return useContext(PointsContext)
}
