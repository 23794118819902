import React, { useEffect, useState } from 'react'
import { Box, Button, Grid, Typography, Rating, useMediaQuery } from '@mui/material'
import { styled } from '@mui/material/styles'
import { usePointsContext } from 'providers/PointsProvider'
import { usePointSetin } from './hooks'
import { useLocation } from 'react-router-dom'
import { useTheme } from '@mui/styles'
import TastingNotes from 'modules/scoring/TastingNotes'

const PriceButton = styled(Button)(({ theme }) => ({
  width: '80%',
  border: '2px solid gray',
  borderRadius: '20px',
  padding: theme.spacing(1),
  margin: theme.spacing(1),
  color: 'black'
}))

const GuessPriceButton = ({ wine, wineExtraPoints, setWineExtraPoints }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const extraPointStorage = JSON.parse(localStorage.getItem('scoring'))
  const { pathname } = useLocation()
  const [isMultiplayer] = useState(pathname.includes('game-room'))
  const [disabled, setDisabled] = useState(extraPointStorage && extraPointStorage[wine.name] && 'extraPoints' in extraPointStorage[wine.name] && !isMultiplayer)

  const { getWineNotes, setWinePoints, getWineRating } = usePointsContext()
  // console.log("wine guess price btn", 'extraPoints' in JSON.parse(localStorage.getItem("scoring"))[wine.name])
  const isRated = getWineRating(wine.name)

  const [guess, setGuess] = useState({ changed: false, guessed: false })
  //   const ifGuess = (extraPoints) => setWinePoints(wine.name, '', extraPoints)

  const [pricesArray, setPricesArray] = useState([])
  const [correctPrice, setCorrectPrice] = useState(null)
  const [notesOpen, setNotesOpen] = useState(false)
  const [wineForNotes, setWineForNotes] = useState(null)

  const { ifGuess } = usePointSetin(wine, wineExtraPoints, setWineExtraPoints)
  if (guess.changed) {
    if (guess.guessed) ifGuess(5)
    else ifGuess(0)
    setGuess({ ...guess, changed: false })
  }

  useEffect(() => {
    let arr = []
    for (const price in wine.price) {
      arr = [...arr, { price: Number(wine.price[price]) }]
    }
    arr.push({ correctPrice: Number(wine.price.price) })

    const shuffle = (arr) => {
      for (let i = arr.length - 1; i > 0; i -= 1) {
        const idx = Math.floor(Math.random() * (i + 1))
        const temp = arr[i]
        arr[i] = arr[idx]
        arr[idx] = temp
      }
    }
    shuffle(arr)
    setPricesArray(arr)
    setCorrectPrice(arr.find(el => 'correctPrice' in el).correctPrice)
  }, [])

  return (
    <Box sx={{ width: 1, my: 2 }}>
        <Grid container sx={{ }}>
            <Grid item xs={12} sx={{ position: 'relative', bgcolor: wine.backgroundColor, borderTopLeftRadius: '20px', borderTopRightRadius: '20px', border: '2px solid gray' }}>
                <Box component="img" src={wine.image} alt="wine" sx={{ position: 'absolute', width: 48, left: 30, bottom: -35 }} />
                <Grid container sx={{ display: 'flex', alignItems: 'flex-end', minHeight: 100 }} >
                    <Grid item xs={3}>
                    </Grid>
                    <Grid item xs={9} sx={{ position: 'relative' }}>
                        <Grid container >
                            <Grid item xs={isMobile ? 3 : 2.5} sx={{ display: 'flex' }}>
                                <Typography component="p" variant="subtitle" fontFamily="DMSansRegular" fontSize={12} sx={{ ml: 1, color: 'white', my: 'auto' }}>
                                    Wine { wine.index }
                                </Typography>
                            </Grid>
                            <Grid item xs={isMobile ? 9 : 8.5} sx={{ }}>
                                {
                                    isRated != null &&
                                    <Box sx={{ display: 'flex', alignContent: 'center' }}>
                                        <Rating sx={{ transform: 'scale(0.7)', ml: -2, pb: 1 }}
                                        name="rating"
                                        readOnly
                                        value={isRated}
                                        precision={0.5}
                                        size="large"
                                        />
                                    </Box>
                                }
                            </Grid>
                            <Grid item xs={12}>
                                <Typography fontFamily="KaiseiDecolBold" fontSize={18} sx={{ color: 'white', p: 1, pt: 0 }}>{wine.name}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography fontFamily="MundialRegular" fontSize={16} sx={{ color: 'white', pb: 1, px: 1 }}>{wine.grape}</Typography>
                            </Grid>
                          {getWineNotes(wine.name) &&
                            <Grid item xs={12}>
                              <Button
                                sx={{
                                  height: 30,
                                  px: 1.5,
                                  py: 0,
                                  my: 'auto',
                                  marginBottom: '1em',
                                  border: '1px solid white',
                                  borderRadius: '50px'
                                }}
                                onClick={() => {
                                  setWineForNotes(wine)
                                  setNotesOpen(true)
                                }}
                              >
                                    <Typography fontFamily="DMSansMedium" fontSize={16} sx={{ color: 'white' }}> Notes </Typography>
                                </Button>
                            </Grid>
                          }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sx={{ borderLeft: '2px solid gray', borderRight: '2px solid gray' }}>
                <Grid container>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={7}>
                        <Typography fontFamily="DMSansMedium" fontSize={18} sx={{ p: 1 }}>Guess the price</Typography>
                    </Grid>
                    <Grid item xs={2} sx={{ position: 'relative' }} >
                        {
                            guess.guessed &&
                            <Box sx={{ position: 'absolute', zIndex: 10, bottom: 0, right: 20 }}>
                                <Grid container sx={{ zIndex: 20, position: 'absolute', bottom: 15 }}>
                                    <Grid item xs={4}>
                                        <Grid item xs={12} sx={{ height: '50%', display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', pr: 0.4 }}>
                                            <Typography fontFamily="MundialBold" fontSize={12} sx={{ color: 'white', pt: 4 }}>+</Typography>
                                        </Grid>
                                        <Grid item xs={12} sx={{ height: '50%' }}>

                                        </Grid>
                                    </Grid>
                                    <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <Typography fontFamily="MundialBold" fontSize={22} sx={{ color: 'white', mt: -0.5 }}>5</Typography>
                                        <Typography fontFamily="MundialBold" fontSize={12} sx={{ color: 'white', mt: -1 }}>Pts</Typography>
                                    </Grid>
                                </Grid>
                                <Box component="img" src="/emptyStar.svg" alt="" sx={{ width: 70 }} />
                            </Box>
                        }
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sx={{ borderBottomLeftRadius: '20px', borderBottomRightRadius: '20px', border: '2px solid gray' }}>
                <Grid container >
                    {
                        pricesArray.map((price, i) => {
                          if ('correctPrice' in price) {
                            return null
                          }
                          return (
                                <Grid item xs={3} key={i}>
                                <PriceButton onClick={(e) => {
                                  if (Number(price.price) === correctPrice) {
                                    setGuess({ changed: true, guessed: true })
                                    document.getElementById(`${correctPrice}-${wine.id}`).style.backgroundColor = '#ce8900'
                                    document.getElementById(`${correctPrice}-${wine.id}`).style.color = 'white'
                                    document.getElementById(`${correctPrice}-${wine.id}`).style.border = '2px solid #ce8900'
                                  } else {
                                    setGuess({ changed: true, guessed: false })
                                    document.getElementById(`${correctPrice}-${wine.id}`).style.backgroundColor = '#015962'
                                    document.getElementById(`${correctPrice}-${wine.id}`).style.color = 'white'
                                    document.getElementById(`${correctPrice}-${wine.id}`).style.border = '2px solid #015962'

                                    document.getElementById(`${price.price}-${wine.id}-${i}`).style.border = '2px solid #ea4928'
                                    document.getElementById(`${price.price}-${wine.id}-${i}`).style.color = '#ea4928'
                                  }
                                  setDisabled(true)
                                }}
                                id={Number(price.price) === correctPrice ? `${correctPrice}-${wine.id}` : `${price.price}-${wine.id}-${i}`}
                                disabled={disabled} >
                                <Typography fontFamily="MundialBold" fontSize={16} >
                                    £{price.price.toFixed()}
                                </Typography>
                                </PriceButton>
                            </Grid>
                          )
                        })
                    }
                </Grid>
            </Grid>
        </Grid>
      {
        notesOpen &&
          <TastingNotes
            open={notesOpen}
            setOpen={setNotesOpen}
            wine={wineForNotes}
            readOnly={true}
            closeButtonLabel="Return to bonus round"
          />
      }
    </Box>
  )
}

export default GuessPriceButton
