import { Typography } from '@mui/material'
import React, { useState } from 'react'

export const URL_GAME = process.env.REACT_APP_URL
const SCORING = 'scoring'
const JUST_RATING = 'just_rating'
const ratePhrases = [
  'Yuk!',
  'Not too keen',
  'It’s OK, I’d drink a glass or two',
  'Seriously good',
  'This wine is incredible!'
]
const matchPercentagePhrases = [
  'Turns out you’re a bit of an expert! You should definitely be choosing the wine next time, that’s for sure.',
  'Good result – you can really taste! A bit more practice and you’ll smash it.',
  'You didn’t win this time, but a bit more practice and soon you’ll be tasting like a pro!'
]
const priceRange = (wines) => {
  const prices = wines.map(wine => Number(wine.price.price))
  return prices && prices.reduce((acc, val) => {
    acc[0] = (acc[0] === undefined || val < acc[0]) ? val.toFixed() : acc[0]
    acc[1] = (acc[1] === undefined || val > acc[1]) ? val.toFixed() : acc[1]
    return acc
  }, [])
}

const getRatePhrase = (value) => {
  switch (value) {
    case 0:
      return ratePhrases[0]
    case 0.5:
      return ratePhrases[0]
    case 1:
      return ratePhrases[0]
    case 1.5:
      return ratePhrases[0]
    case 2:
      return ratePhrases[1]
    case 2.5:
      return ratePhrases[1]
    case 3:
      return ratePhrases[2]
    case 3.5:
      return ratePhrases[2]
    case 4:
      return ratePhrases[3]
    case 4.5:
      return ratePhrases[3]
    case 5:
      return ratePhrases[4]
    default:
  }
}
const useRatingStars = () => {
  const [rate, setRate] = useState(null)
  const [ratePhrase, setRatePhrase] = useState('')

  const handleChange = (name, value) => {
    setRatePhrase('')
    if (!value || value == null) {
      setRate(0)
    } else {
      setRate(value)
      const phrase = getRatePhrase(value)
      setRatePhrase(phrase)
    }
  }
  return { rate, ratePhrase, handleChange }
}
const matchPercentage = (points) => {
  const fixedNum = (points / 30) * 100
  return fixedNum.toFixed()
}

function getDifference (a, b) {
  return Math.abs(a - b)
}
const compareWithExpert = (userScore, expertScore) => {
  let pointsAccum = 0
  const difference = getDifference(userScore, expertScore)
  const returnPoints = (difference) => {
    switch (difference) {
      case 0:
        pointsAccum += 5
        break
      case 0.5:
        pointsAccum += 4
        break
      case 1:
        pointsAccum += 2.5
        break
      case 1.5:
        pointsAccum += 2
        break
      default:
        pointsAccum += 0
        break
    }
  }
  returnPoints(difference)
  return pointsAccum
}

const CharsLeft = (value, maxValue) => {
  const innerText = value === 0 ? `(Max. ${maxValue} characters)` : `${maxValue - value} characters left`
  return <Typography fontSize={11} sx={[
    { textAlign: 'end', pr: 1 },
    value >= maxValue && { color: 'red' }
  ]}>{innerText}</Typography>
}

export { SCORING, JUST_RATING, priceRange, useRatingStars, getRatePhrase, matchPercentage, matchPercentagePhrases, compareWithExpert, CharsLeft }
