import React, { useRef, useState } from 'react'
import { useTheme } from '@mui/styles'
import { Box, Dialog, Slide, Container, IconButton, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import Button from 'core/buttons/Button'
import { useGameMode } from 'providers/GameMode'
import ShareComponent from './ShareComponent'
import { URL_GAME } from 'utils'
import { isMobile, MobileOnlyView } from 'react-device-detect'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const ShareCodeModal = ({ handleClose, gameId }) => {
  const theme = useTheme()
  const [shareMedia, setShareMedia] = useState(false)
  const clipBoardRef = useRef()
  const { gameData } = useGameMode()

  const copyToClipboard = () => {
    const code = clipBoardRef.current.innerText
    navigator.clipboard.writeText(code)
  }

  const handleShare = () => setShareMedia(true)

  const page = `${URL_GAME}game-room/`
  const shareMessage = `Hi, you've been invited to play a game of Wine Smash. To join the game tap the link below: ${URL_GAME}game-room/ Or enter the code ${gameData?.gameId} in the 'Join friends game'.`

  const handleMobile = () => {
    if (navigator.canShare) {
      if (navigator.share) {
        navigator.share({
          title: 'wine smash game',
          text: shareMessage,
          url: page
        })
      }
    }
  }

  return (
    <Box sx={{ height: '100%', position: 'relative', display: 'flex', flexDirection: 'column' }}>
      <Dialog
        fullScreen={theme.popUpsWidth}
        sx={{
          display: 'flex',
          width: '100vw',
          m: 'auto',

          '.MuiBackdrop-root': {
            width: '100vw',
            m: 'auto'
          },
          '.MuiDialog-container': {
            display: 'flex',
            alignItems: 'flex-end'
          },
          '.MuiPaper-root': {
            width: '100vw',
            bgcolor: 'transparent'
          }
        }}
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <Container disableGutters
          sx={{
            '&.MuiContainer-root': {
              width: '100vw',
              maxWidth: '450px',
              minHeight: 500,
              height: 1,
              bgcolor: 'transparent'
            },
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end'
          }}
        >
        <Box sx={{ height: 1, bgcolor: '#FFFDF7', position: 'relative', display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ width: 1, mb: 0 }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close" sx={{ mr: 2, mt: 4, mb: 2 }}>
                    <CloseIcon fontSize="medium" />
                    </IconButton>
                </Box>
                </Box>

                <Box sx={{ display: 'grid', pb: 2, mx: 3 }}>
                    <Typography fontFamily="KaiseiDecolReg" fontWeight={700} fontSize={26}
                    textAlign="left" >
                    Game Invite Code
                    </Typography>
                    <Typography fontFamily="MundialLight" fontWeight={300} fontSize={18} pt={2}
                    textAlign="left" color="#575757">
                        Tell your friends to enter this code when they open Wine Smash
                    </Typography>
                </Box>

                <HostCode copy={copyToClipboard} ref={clipBoardRef} code={gameData?.gameId} />

                <Box sx={{ display: 'flex', cursor: 'text' }} onClick={copyToClipboard}>
                    <Typography fontFamily="MundialLight" fontWeight={300} fontSize={18} pt={2} m="auto"
                        textAlign="center" color="#575757">
                        Tap to copy to clipboard
                    </Typography>
                </Box>

              {/* <MobileOnlyView> */}
                <Box sx={{ display: 'grid', p: 3, position: 'absolute', bottom: 3, width: 1 }}>
                    <Button onClick={isMobile ? handleMobile : handleShare} sx={{ m: 'auto' }}>
                        <Typography fontFamily="DMSansRegular" fontWeight={700} fontSize={18}>
                        Share code
                        </Typography>
                    </Button>
                </Box>
              {/* </MobileOnlyView> */}

            { shareMedia ? <ShareComponent message={shareMessage} handleClose={() => setShareMedia(false)} /> : null }
            </Box>
        </Container>
      </Dialog>
    </Box>
  )
}

const HostCode = React.forwardRef(function HostCode (props, ref) {
  return (
    <Box onClick={props.copy} sx={{ display: 'flex', width: 1, height: 120, bgcolor: '#FFFFFF', borderBottom: '1px solid #F0D7C1', borderTop: '1px solid #F0D7C1', mt: 2, cursor: 'text' }}>
        <Typography ref={ref} fontFamily="KaiseiDecolReg" fontWeight={700} fontSize={42}
        textAlign="center" color="#2E2A26" sx={{ letterSpacing: 20, m: 'auto' }}>
        { props.code }
        </Typography>
    </Box>
  )
})

export default ShareCodeModal
