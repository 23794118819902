import React from 'react'
import { Box, Grid, Typography } from '@mui/material'

const WineDetails = ({ wine, isRated, userScore }) => {
  return (
    <Grid container sx={{ px: 4 }}>
      <Grid container>
        {wine.producer &&
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={6}>
                <Typography fontFamily="MundialDemiBold" fontSize={16}>
                  Producer
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography fontFamily="MundialLight" fontSize={16}>
                  {wine.producer}
                </Typography>
              </Grid>
            </Grid>
          </Grid>}
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6}>
              <Typography fontFamily="MundialDemiBold" fontSize={16}>
                Country
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography fontFamily="MundialLight" fontSize={16}>
                {wine.country}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6}>
              <Typography fontFamily="MundialDemiBold" fontSize={16}>
                Region
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography fontFamily="MundialLight" fontSize={16}>
                {wine.region}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {wine.subregion
          ? (
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={6}>
                <Typography fontFamily="MundialDemiBold" fontSize={16}>
                  Subregion
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography fontFamily="MundialLight" fontSize={16}>
                  {wine.subregion}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
            )
          : null}
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6}>
              <Typography fontFamily="MundialDemiBold" fontSize={16}>
                Grape blend:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography fontFamily="MundialLight" fontSize={16}>
                {wine.grape}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6}>
              <Typography fontFamily="MundialDemiBold" fontSize={16}>
                Vintage
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography fontFamily="MundialLight" fontSize={16}>
                {wine.vintage}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {isRated || userScore
        ? (
        <Box sx={{ mt: 4 }}>
          <Typography fontFamily="MundialDemiBold" fontSize={16} pb={1}>
            Expert notes
          </Typography>
          <Grid item xs={12}>
            <Typography fontFamily="MundialLight" fontSize={16}>
              {wine.notes}
            </Typography>
          </Grid>
        </Box>
          )
        : null}
    </Grid>
  )
}

export default WineDetails
