import React, { useEffect, useState } from 'react'
import { renderToString } from 'react-dom/server'
import { useTheme, styled } from '@mui/styles'
import { Box, Typography, Grid, Button as MButton } from '@mui/material'
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt'
import Button from 'core/buttons/Button'
import { useLocation, useNavigate, useParams, Link } from 'react-router-dom'
import { useWineContext } from 'providers/WinesProvider'
import useWineriesApi from 'firebaseKit/wineries'
import useWinesApi from 'firebaseKit/wines'
import { usePointsContext } from 'providers/PointsProvider'
/* eslint import/no-webpack-loader-syntax: off */
import YourMarkdown from '!html-loader!core/email-templates/EmailTemplate.html'
import EmailInput from './EmailInput'
import Spinner from 'core/Spinner'
import { useGameMode } from 'providers/GameMode'
import useMailApi from 'firebaseKit/mail'
import EmailTemplate from './EmailTemplate'

const MarkdownComponent = YourMarkdown

const MuiButton = styled(MButton)(() => ({
  position: 'absolute',
  width: '50px',
  bottom: -1,
  right: -1,
  background: '#2E2A26',
  borderRadius: 0,
  borderTopLeftRadius: '15px',
  borderBottomRightRadius: '15px',
  '&:hover': {
    background: '#2E2A26',
    opacity: 0.95
  }
}))

const InvestorDeck = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { guessed, setGuessed, getStoragePlayerData, player, setGameData, setPlayer } = useGameMode()
  const [emailSent, setEmailSent] = useState(false)
  const [isMultiplayer] = useState(pathname.includes('game-room'))
  const winery = guessed?.wineryName
  const points = Math.round(isMultiplayer ? player?.totalPoints ?? guessed?.points : guessed?.points)
  const { id } = useParams()
  const { wines, setWineList } = useWineContext()
  const { getWinery } = useWineriesApi()
  const { getWinesByWynery } = useWinesApi()
  const { fullyRatedWineries, getWineNotes, getWineRating } = usePointsContext()
  const [wineryData, setWineryData] = useState(null)

  const [loading, setLoading] = useState(false)

  const { sendMail } = useMailApi()

  useEffect(() => {
    getStoragePlayerData().then(storageData => {
      if (isMultiplayer) {
        if (!storageData) navigate('/game-mode')
        if (!guessed?.wineryName && storageData && !('totalPoints' in storageData)) {
          navigate(pathname.replace('investor-deck', 'guess-price'))
        }
      } else { if (!guessed?.wineryName) navigate(pathname.replace('investor-deck', 'guess-price')) }
    })
    getWinesByWynery(id).then((wineList) => {
      setWineList(wineList)
    })
    window.scrollTo({ top: 0 })
  }, [])

  useEffect(() => {
    getWinery(id).then((data) => setWineryData(data))
  }, [id])

  const pointsLength = points.toString().length
  const pointsWithDecimals = points && points.toString().includes('.')

  const winesToAskFor =
    wines &&
    wines.map((wine) => {
      const wineInfo = {}
      wineInfo.name = wine.name
      wineInfo.winery = wine.winery
      wineInfo.country = wine.country
      wineInfo.grape = wine.grape
      wineInfo.color = wine.color
      wineInfo.price = wine.price.price
      wineInfo.year = wine.year
      return wineInfo
    })

  // Send rating stars for email template
  const getStarsImage = (rate) => {
    switch (rate) {
      case 1:
        return 'https://firebasestorage.googleapis.com/v0/b/wine-smash-dev.appspot.com/o/one_star.png?alt=media&token=f8ead3d1-9603-4d63-816a-365213cb09bf'
      case 1.5:
        return 'https://firebasestorage.googleapis.com/v0/b/wine-smash-dev.appspot.com/o/one_half_star.png?alt=media&token=03877ef3-9ecd-4683-a80c-54afe05021bf'
      case 2:
        return 'https://firebasestorage.googleapis.com/v0/b/wine-smash-dev.appspot.com/o/two_star.png?alt=media&token=f3f4a4a2-e830-43b3-9d8f-a671effbeff7'
      case 2.5:
        return 'https://firebasestorage.googleapis.com/v0/b/wine-smash-dev.appspot.com/o/two_half_star.png?alt=media&token=bc7fb4ef-750b-4824-bfe6-43b6baf0db30'
      case 3:
        return 'https://firebasestorage.googleapis.com/v0/b/wine-smash-dev.appspot.com/o/three_star.png?alt=media&token=2139dd28-c9c8-45cb-845f-592ab311b1dd'
      case 3.5:
        return 'https://firebasestorage.googleapis.com/v0/b/wine-smash-dev.appspot.com/o/three_half_star.png?alt=media&token=05c56d16-06ea-4c59-831c-35829db6c462'
      case 4:
        return 'https://firebasestorage.googleapis.com/v0/b/wine-smash-dev.appspot.com/o/four_star.png?alt=media&token=87c6ea59-7b15-448d-b6f0-428b449c629f'
      case 4.5:
        return 'https://firebasestorage.googleapis.com/v0/b/wine-smash-dev.appspot.com/o/four_half_star.png?alt=media&token=fb8ddbd7-3675-47a1-b716-7e8c69ccc07a'
      case 5:
        return 'https://firebasestorage.googleapis.com/v0/b/wine-smash-dev.appspot.com/o/five_star.png?alt=media&token=1e49ad9a-348f-4b2f-a3e9-f24f7958020a'
      default:
        return ''
    }
  }

  const emailTemplateProps = {
    app_url: process.env.REACT_APP_URL,
    items: wines && wines.length >= 5
      ? wines.map((wine) => ({
        producer: wine.producer || wine.winery,
        name: wine.name,
        grape: `${wine.grape} - ${wine.year}`,
        image: wine.image,
        color: wine.backgroundColor,
        rating: getStarsImage(getWineRating(wine.name)),
        tasting_notes: getWineNotes(wine.name),
        url: wine.url
      }))
      : [],
    score: points,
    to_email: null
  }

  const sendEmail = (values) => {
    emailTemplateProps.to_email = values.email

    setLoading(true)

    const htmlDiv = renderToString(<EmailTemplate props={emailTemplateProps} />)
    const contentHtml = `<!DOCTYPE html>\n<html>\n<head>\n</head>\n<body style="font-family: Roboto, Helvetica, Arial, sans-serif">${htmlDiv}</body></html>`

    // Generate e-mail text content
    const subject = `I just scored ${emailTemplateProps.score} points at Wine Smash!`
    let contentText = `${subject}\n\nToday's wines:\n\n`
    contentText += emailTemplateProps.items.map((item) => {
      let itemLine = ' - ' + (
        [
          item.producer,
          item.name,
          item.grape
        ]
          .filter((v) => !!v && v.length > 0)
          .join(' ')
      )
      if (item.url) {
        itemLine += `\n  - ${item.url}`
      }
      if (item.tasting_notes) {
        itemLine += `\n  - ${item.tasting_notes}`
      }
      return itemLine
    })
      .join('\n')

    sendMail(
      values.email, // TO
      subject,
      contentText,
      contentHtml,
      [], // CC
      ['winelist@winesmash.com'], // BCC
      { marketing: values['receive-marketing'] }
    )
      .then(
        (result) => {
          console.log(result.text)
          setLoading(false)
          setEmailSent(true)
        },
        (error) => {
          console.log(error.text)
          setLoading(false)
        }
      )
  }

  const winesToBuy =
    winesToAskFor &&
    `%0D%0A 
        Winery: ${winesToAskFor[0].winery}
        %0D%0A %0D%0A %0D%0A 
        Name: ${winesToAskFor[0].name} %0D%0A Producer: ${winesToAskFor[0].winery} %0D%0A Country: ${winesToAskFor[0].country} %0D%0A Grape: ${winesToAskFor[0].grape} %0D%0A Color: ${winesToAskFor[0].color} %0D%0A Price: ${winesToAskFor[0].price} %0D%0A Year: ${winesToAskFor[0].year}
        %0D%0A %0D%0A
        Name: ${winesToAskFor[1].name} %0D%0A Producer: ${winesToAskFor[1].winery} %0D%0A Country: ${winesToAskFor[1].country} %0D%0A Grape: ${winesToAskFor[1].grape} %0D%0A Color: ${winesToAskFor[1].color} %0D%0A Price: ${winesToAskFor[1].price} %0D%0A Year: ${winesToAskFor[1].year}
        %0D%0A %0D%0A
        Name: ${winesToAskFor[2].name} %0D%0A Producer: ${winesToAskFor[2].winery} %0D%0A Country: ${winesToAskFor[2].country} %0D%0A Grape: ${winesToAskFor[2].grape} %0D%0A Color: ${winesToAskFor[2].color} %0D%0A Price: ${winesToAskFor[2].price} %0D%0A Year: ${winesToAskFor[2].year}
        %0D%0A %0D%0A
        Name: ${winesToAskFor[3].name} %0D%0A Producer: ${winesToAskFor[3].winery} %0D%0A Country: ${winesToAskFor[3].country} %0D%0A Grape: ${winesToAskFor[3].grape} %0D%0A Color: ${winesToAskFor[3].color} %0D%0A Price: ${winesToAskFor[3].price} %0D%0A Year: ${winesToAskFor[3].year}
        %0D%0A %0D%0A
        Name: ${winesToAskFor[4].name} %0D%0A Producer: ${winesToAskFor[4].winery} %0D%0A Country: ${winesToAskFor[4].country} %0D%0A Grape: ${winesToAskFor[4].grape} %0D%0A Color: ${winesToAskFor[4].color} %0D%0A Price: ${winesToAskFor[4].price} %0D%0A Year: ${winesToAskFor[4].year}
         `

  const endCurrentPlayerGame = () => {
    localStorage.removeItem('multiplayer')
    localStorage.removeItem('rating')
    localStorage.removeItem('scoring')
    localStorage.removeItem('tasting_notes')
    setGameData(null)
    setPlayer({ isHost: null, nickname: null, isReady: null, color: null })
    navigate('/game-mode')
  }
  const playAgain = () => {
    setGuessed({ ...guessed, guessed: false })
    if (isMultiplayer) {
      endCurrentPlayerGame()
    } else {
      fullyRatedWineries()
      navigate('/home')
    }
  }
  return (
    <Box style={theme.background} sx={{ position: 'relative', display: 'flex', flexDirection: 'column', m: 'auto', overflow: 'hidden' }}>
      <Box component="img" src="/background.svg" alt=""
      sx={{ position: 'absolute', top: -100, width: '100%', height: '100%', transform: 'scale(1.25)' }} />

      <Box sx={{ zIndex: 10, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography fontFamily="KaiseiDecolBold" fontSize={32} sx={{ mt: 8, mb: 4, textAlign: 'center' }}>
          Thank you for playing Wine Smash!
        </Typography>
        <Typography fontFamily="MundialRegular" fontSize={16}>
          Your final score
        </Typography>
      </Box>

      <Box sx={{ zIndex: 10, px: 4, background: 'linear-gradient(to bottom, rgba(255,253,247,0), rgb(250,243,237))' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <Box sx={{ position: 'relative', mt: 4 }}>
            <Typography
              fontFamily="KaiseiDecolBold"
              fontSize={pointsLength >= 4 && pointsWithDecimals ? 50 : pointsLength === 3 ? 54 : 66}
              sx={{
                color: 'white',
                position: 'absolute',
                [theme.breakpoints.up('sm')]: {
                  left:
                    pointsLength === 5 && pointsWithDecimals
                      ? 0
                      : pointsLength === 4 && pointsWithDecimals
                        ? 5
                        : pointsLength === 3 && pointsWithDecimals
                          ? 18
                          : pointsLength === 2 && pointsWithDecimals
                            ? 27
                            : pointsLength === 1 && pointsWithDecimals
                              ? 27
                              : pointsLength === 3
                                ? 10
                                : pointsLength === 2
                                  ? 20
                                  : 40,
                  top:
                    pointsLength === 5 && pointsWithDecimals
                      ? 20
                      : pointsLength === 4 && pointsWithDecimals
                        ? 20
                        : pointsLength === 3 && pointsWithDecimals
                          ? 15
                          : pointsLength === 2 && pointsWithDecimals
                            ? 27
                            : pointsLength === 1 && pointsWithDecimals
                              ? 27
                              : pointsLength === 3
                                ? 15
                                : pointsLength === 2
                                  ? 4
                                  : 4
                },
                left:
                  pointsLength === 5 && pointsWithDecimals
                    ? 0
                    : pointsLength === 4 && pointsWithDecimals
                      ? 5
                      : pointsLength === 3 && pointsWithDecimals
                        ? 18
                        : pointsLength === 2 && pointsWithDecimals
                          ? 27
                          : pointsLength === 1 && pointsWithDecimals
                            ? 27
                            : pointsLength === 3
                              ? 10
                              : pointsLength === 2
                                ? 20
                                : 40,
                top:
                  pointsLength === 5 && pointsWithDecimals
                    ? 20
                    : pointsLength === 4 && pointsWithDecimals
                      ? 20
                      : pointsLength === 3 && pointsWithDecimals
                        ? 15
                        : pointsLength === 2 && pointsWithDecimals
                          ? 27
                          : pointsLength === 1 && pointsWithDecimals
                            ? 27
                            : pointsLength === 3
                              ? 15
                              : pointsLength === 2
                                ? 4
                                : 4
              }}
            >
              {points}
            </Typography>

            <Typography fontFamily="MundialBold" fontSize={18} sx={{ color: 'white', position: 'absolute', bottom: 30, left: 32 }}>
              Points
            </Typography>

            <Box component="img" src="/emptyStar.svg" alt="star" sx={{ width: 120 }} />
          </Box>

          <Box sx={{ width: 1, mt: 2, mb: 4 }}>
            <Box sx={{ my: 2, border: '2px solid #2E2A26', borderRadius: '20px', minHeight: 110, position: 'relative' }}>
              <Grid container>
                <Grid item xs={3} sx={{ position: 'relative' }}>
                  <Box sx={{ position: 'absolute', left: 27, top: 30 }}>
                    {wines && (
                      <>
                        <Box component="img" src={wines[0].image} alt="bootles" sx={{ width: 40, position: 'absolute', zIndex: 20 }} />
                        <Box component="img" src={wines[1].image} alt="bootles" sx={{ width: 35, position: 'absolute', left: 18, top: 7, zIndex: 10 }} />
                        <Box component="img" src={wines[2].image} alt="bootles" sx={{ width: 35, position: 'absolute', left: -15, top: 7, zIndex: 10 }} />
                      </>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={9}>
                  <Grid container sx={{ [theme.breakpoints.up('sm')]: { p: 0 }, pl: 2 }}>
                    <Grid item xs={12}>
                      <Typography fontFamily="KaiseiDecolBold" fontSize={18} sx={{ my: 1 }}>
                        Enjoyed these wines?
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography fontFamily="DMSansRegular" fontSize={16} sx={{ mb: 4, pr: 0.5 }}>
                        <p>If you would like to buy any of the wines tasted in this game, please click this link to order.</p>
                      </Typography>
                      </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <a href={wineryData?.link || 'https://winesmash.com/shop'} target="_blank" rel="noreferrer">
                <MuiButton type="submit">
                  <Typography fontFamily="DMSansMedium" fontSize={16} sx={{ color: 'white' }}>
                    Buy
                  </Typography>
                </MuiButton>
              </a>
            </Box>
            <EmailInput sendEmail={sendEmail} loading={loading} emailSent={emailSent} />
          </Box>
        </Box>

        <Box sx={{ mb: 2 }}>
          <Button sx={{ mb: 2 }} onClick={playAgain}>
            <Typography fontFamily="DMSansBold" fontSize={18}>
             { isMultiplayer ? 'Start New Game' : 'Play again' }
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default InvestorDeck
