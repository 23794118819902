import React from 'react'
import { Box } from '@mui/material'
import { useTheme } from '@mui/styles'

const HeaderImgBox = (props) => {
  const theme = useTheme()
  const { children, backgroundImg } = props
  return (
    <Box sx={{ position: 'relative', height: 270 }}>
        <Box sx={{ position: 'absolute', height: 270, width: 1, zIndex: 0, overflow: 'hidden', ...props.brightness }} >
            <Box component="img" src={backgroundImg} alt={backgroundImg} sx={{ display: 'flex', m: 'auto', height: 1, width: 1, objectFit: 'cover', zIndex: 0 }} />
            <Box sx={{ position: 'absolute', height: 70, width: 1, bottom: 0, bgcolor: 'transparent', background: 'linear-gradient(to top, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0))' }}></Box>
        </Box>
        <Box sx={{
          [theme.breakpoints.up('sm')]: { px: 4 },
          px: 2,
          position: 'absolute',
          height: 270,
          width: 1,
          pt: 6,
          zIndex: 1000
        }}>
        { children }
        </Box>
    </Box>
  )
}

export default HeaderImgBox
