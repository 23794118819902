import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { usePointsContext } from 'providers/PointsProvider'
import { useWineryContext } from 'providers/WineryProvider'
import { getNextWineryToScore } from '../Hooks'
import { SCORING } from 'utils'

import { Box, Typography } from '@mui/material'
import Button from 'core/buttons/Button'

const ButtonSection = ({ wine, userScore, isRated, scoredPlusExtraPoints, wineryFullyScored, handleClose }) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { wineToScore } = usePointsContext()
  const { getWineryPreference, wineries } = useWineryContext()
  const wineryPreference = getWineryPreference(wine.winery)

  const userPreference = wineryPreference?.scoringPreference === SCORING ? 'Score' : 'Rate'

  return (
    <Box>
      {isRated != null
        ? (
            scoredPlusExtraPoints
              ? (
          <Box sx={{ m: 4, pb: 2 }}>
            <Button
              onClick={() => {
                navigate(`/wine-room/${getNextWineryToScore(wineries)}`, { replace: true })
                handleClose()
                window.scrollTo({ top: 0, behavior: 'smooth' })
              }}
            >
              <Typography fontFamily="DMSansBold" fontSize={18}>
                Next winery
              </Typography>
            </Button>
          </Box>
                )
              : wineryFullyScored
                ? (
          <Box sx={{ m: 4, pb: 2 }}>
            <Button
              onClick={() => {
                navigate(`${pathname}/guess-price`)
              }}
            >
              <Typography fontFamily="DMSansBold" fontSize={18}>
                Bonus Round - Guess the Price
              </Typography>
            </Button>
          </Box>
                  )
                : userScore || isRated
                  ? (
          <Box sx={{ m: 4, pb: 2 }}>
            <Button
              onClick={() => {
                navigate(`/rate/${wineToScore.id}/${wineryPreference?.scoringPreference}`)
              }}
            >
              <Typography fontFamily="DMSansBold" fontSize={18}>
                {userPreference} next wine
              </Typography>
            </Button>
          </Box>
                    )
                  : null
          )
        : null
      }
    </Box>
  )
}

export default ButtonSection
