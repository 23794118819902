import React, { useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Box, Grid, Typography, IconButton, Divider } from '@mui/material'
import { useGameMode } from 'providers/GameMode'

const Compare = ({ expertColor, players, compareWExpert, setCompareWExpert, playerCompared, setPlayerCompared }) => {
  const arrowDown = 'rotate(360deg)'
  const arrowUp = 'rotate(180deg)'
  const [arrowSide, setArrowSide] = useState(arrowDown)
  const [playerDetails, setPlayerDetails] = useState(false)

  const handleOpen = () => {
    setArrowSide(arrowUp)
    setPlayerDetails(true)
  }
  const handleClose = () => {
    setArrowSide(arrowDown)
    setPlayerDetails(false)
  }

  const handleSelectPlayer = (player) => {
    setCompareWExpert(false)
    setPlayerCompared(player)
  }
  const { player } = useGameMode()
  const otherPlayers = players?.filter(pl => pl.nickName !== player.nickname)
  return (
    <Grid container sx={{ position: 'relative' }}>
        <Grid item xs={6} sx={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>
            <Box sx={{ py: 1.5, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Box sx={{ width: 10, height: 10, bgcolor: '#ea4928', borderRadius: '100%' }} />
                <Typography fontFamily="MundialDemiBold" fontSize={18} sx={{ color: '#ea4928', ml: 1 }}>You</Typography>
            </Box>
        </Grid>
        <Grid item xs={6} >
            <Box onClick={compareWExpert || playerCompared ? handleOpen : null}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', m: 'auto', height: 1 }}>
                <Box sx={{ width: 10, height: 10, bgcolor: expertColor, borderRadius: '100%' }} />
                <Typography fontFamily="MundialDemiBold" fontSize={18} sx={{ color: expertColor, ml: 1 }}>
                  { playerCompared ? playerCompared.nickName : 'Expert' }
                </Typography>
                {
                  (compareWExpert || playerCompared) && otherPlayers?.length
                    ? <IconButton sx={{ transform: arrowSide, transition: 'all 300ms ease-out' }}>
                          <ExpandMoreIcon />
                      </IconButton>
                    : null
                }
            </Box>
        </Grid>
        {
        playerDetails && otherPlayers?.length
          ? <Box sx={{ bgcolor: '#E1E4EA', width: 1, display: 'grid' }}>
            <Divider mt={-5} />
            <Box sx={{ width: 1, bgcolor: 'white', display: 'flex', justifyContent: 'flex-end' }}>
                <Typography onClick={handleClose}
                fontFamily="MundialDemiBold" fontSize={14} sx={{ cursor: 'pointer', color: '#009FFF', mr: 2 }}>Done</Typography>
            </Box>
            {
              otherPlayers
                ? otherPlayers.map(player => <Typography onClick={() => handleSelectPlayer(player)}
                  key={player.nickName} fontFamily="MundialDemiBold" fontSize={18} sx={{ color: expertColor, width: 1, m: 'auto', textAlign: 'center', '&:hover': { borderTop: '1px solid rgba(0, 0, 0, 0.1)', borderBottom: '1px solid rgba(0, 0, 0, 0.1)' }, cursor: 'pointer' }}>{player.nickName}</Typography>)
                : null
            }
            {
              !compareWExpert
                ? <Typography onClick={() => { setCompareWExpert(true); setPlayerCompared(null) }}
                  key={player.nickName} fontFamily="MundialDemiBold" fontSize={18} sx={{ color: expertColor, width: 1, m: 'auto', textAlign: 'center', '&:hover': { borderTop: '1px solid rgba(0, 0, 0, 0.1)', borderBottom: '1px solid rgba(0, 0, 0, 0.1)' }, cursor: 'pointer' }}>Expert</Typography>
                : null
            }
            </Box>
          : null
        }
    </Grid>
  )
}

export default Compare
