import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMultiplayerWineHook } from 'modules/wine-room/Hooks'
import { useHandleRounds } from 'modules/game-room/hooks'

import { Box, Typography, Grid, useMediaQuery } from '@mui/material'
import Button from 'core/buttons/Button'
import FinalLeaderBoard from 'modules/multiplayer/leaderboard/final-leaderboard'
import { useGameMode } from 'providers/GameMode'
import GuessPriceAsModal from 'modules/guess-price/GuessPriceAsModal'
import WaitingGuessPriceScreen from 'modules/multiplayer/scoring/components/WaitingGuessPriceScreen'
import Spinner from 'core/Spinner'
import { useTheme } from '@mui/styles'

const ButtonSection = ({ isRated, userScore, showLeaderboard, showBoard }) => {
  const navigate = useNavigate()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { guessed, player, gameData } = useGameMode()
  const { handleNextRound } = useHandleRounds()

  // Multiplayer flow = wine details > round leaderboard > guess price > final leaderBoard > than you for playing
  const { isGameFinished } = useMultiplayerWineHook()
  // Guess price modal
  const [guessPriceOpened, setGuessPriceOpened] = useState(false)
  const [finalBoard, setFinalBoard] = useState(guessed.guessed)
  const [waitingForPlayers, setWaitingForPlayers] = useState(false)

  const handleContinue = () => {
    if (isGameFinished() && showLeaderboard) {
      if (player?.totalPoints) setWaitingForPlayers(true) // To wait screen
      else setGuessPriceOpened(true)
    } else {
      if (!showLeaderboard) showBoard(true)
      else { player?.isHost ? handleNextRound() : navigate('/game-room') }
    }
  }
  const toFinalBoard = () => setFinalBoard(true)
  useEffect(() => {
    if (gameData?.toInvestorDeck) { setWaitingForPlayers(false); toFinalBoard() }
  }, [gameData])

  return (
    <Box>
      {isRated != null || userScore
        ? <Box sx={{ p: 4, pb: 2 }}>
            <Button onClick={handleContinue} >
              {
                gameData?.round !== 6
                  ? <Grid container>
                  <Grid item xs={isMobile ? 9 : 10}>
                    <Typography fontFamily="DMSansBold" fontSize={18} ml={6}>
                      Score next wine
                    </Typography>

                  </Grid>
                  <Grid item xs={0.5} />
                  <Grid item xs={isMobile ? 2.5 : 1.5} sx={{ display: 'flex', overflow: 'hidden', ml: isMobile ? 0 : -2 }}>
                      {
                        showLeaderboard
                          ? <Box component="img" src="/buttonLongArrow.svg" alt="long-arrow" sx={{ ml: -7, my: 'auto' }} />
                          : null
                      }
                  </Grid>
                </Grid>
                  : <Typography fontFamily="DMSansBold" fontSize={18}>
                  Bonus Round - Guess the Price
                </Typography>

              }
            </Button>
          </Box>
        : null
      }
      {
        guessPriceOpened
          ? <GuessPriceAsModal open={guessPriceOpened} setOpen={setGuessPriceOpened} handleContinue={() => setWaitingForPlayers(true)} />
          : null
      }
      {
          waitingForPlayers &&
        <WaitingGuessPriceScreen open={waitingForPlayers} setOpen={setWaitingForPlayers} />
      }
      {
        finalBoard
          ? <FinalLeaderBoard open={finalBoard} setOpen={setFinalBoard} />
          : null
      }
    </Box>
  )
}

export default ButtonSection
