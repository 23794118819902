import { db } from '../firebase-config'
import { collection, getDoc, doc, setDoc } from 'firebase/firestore/lite'

export default function useMailApi () {
  const mailCollection = collection(db, 'mail')

  const sendMail = async (
    to,
    subject,
    messageText,
    messageHtml,
    cc = [],
    bcc = [],
    extraData = null
  ) => {
    const ref = doc(mailCollection)
    await setDoc(
      ref,
      {
        to: Array.isArray(to) ? to : [to],
        bcc: Array.isArray(bcc) ? bcc : [bcc],
        cc: Array.isArray(cc) ? cc : [cc],
        message: {
          html: messageHtml,
          subject,
          text: messageText
        },
        extraData
      }
    )
    const document = await getDoc(ref)

    console.log('Mail document', document.data())
    return document.data()
  }

  return { sendMail }
}
