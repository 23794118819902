import React from 'react'
import { useTheme } from '@mui/styles'
import { Box, Typography } from '@mui/material'
import Spinner from 'core/Spinner'
import GuessPriceButton from 'core/guess-price-btn'
import Button from 'core/buttons/Button'
import { useWineriesData } from './hooks'

const GuessPrice = () => {
  const theme = useTheme()

  const { handleFinish, loading, wineList, wineExtraPoints, setWineExtraPoints } = useWineriesData()

  return (
    <Box style={theme.background} sx={{ height: '100%', display: 'flex', flexDirection: 'column', m: 'auto' }}>

        <Box sx={{ m: 4, mt: 8 }}>
            <Typography fontFamily="KaiseiDecolBold" fontSize={26}>Win 5 more points</Typography>
            <Typography fontFamily="MundialLight" fontSize={18} sx={{ color: '#575757' }}>Try and guess the price of your wines and get extra points on your final score!</Typography>
        </Box>

        <Box sx={{ mx: 4, my: 2 }}>
            {
                loading
                  ? <Box sx={{ mt: 20 }}><Spinner /> </Box>
                  : <>
                    {wineList && wineList.map(wine => <GuessPriceButton wine={wine} key={wine.index} wineExtraPoints={wineExtraPoints} setWineExtraPoints={setWineExtraPoints} />)}

                    <Box sx={{ mt: 6, mb: 2, position: 'relative' }}>
                        <Button onClick={handleFinish} >
                            <Typography fontFamily="DMSansBold" fontSize={18}>
                            Finish
                            </Typography>
                            <Box component="img" src="/buttonLongArrow.svg" alt="long-arrow" sx={{ position: 'absolute', right: 20 }} />
                        </Button>
                    </Box>
                </>
            }
        </Box>

    </Box>
  )
}

export default GuessPrice
