import React, { useState, useEffect } from 'react'
import { Box, Typography, IconButton } from '@mui/material'
import Slider, { SliderThumb } from '@mui/material/Slider'
import { styled } from '@mui/system'
import Characteristics from 'core/wine-characteristics'

const AirbnbSlider = styled(Slider)(({ theme, colorprop, fontDecimals }) => {
  // console.log("decimales", fontDecimals)
  return ({
    color: colorprop,
    height: 1,
    padding: '13px 0',
    '& .MuiSlider-thumb': {
      height: 27,
      width: 27,
      backgroundColor: '#2e2a26',
      border: '1px solid currentColor',
      '&:hover': {
        boxShadow: '0 0 0 8px rgba(58, 133, 137, 0.16)'
      },
      '& .airbnb-bar': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        color: 'white',
        fontSize: '100%'
      }
    },
    '& .MuiSlider-track': {
      height: 3
    },
    '& .MuiSlider-rail': {
      color: theme.palette.mode === 'dark' ? '#bfbfbf' : '#d8d8d8',
      opacity: theme.palette.mode === 'dark' ? undefined : 1,
      height: 3
    }
  })
})

function AirbnbThumbComponent (props) {
  const { children, ...other } = props
  return (
      <SliderThumb {...other}>
        {children}
        <span className="airbnb-bar">{children.props.value}</span>
      </SliderThumb>
  )
}

const Scoring = ({ wine, scoring, setScoring }) => {
  const [wineCharacteristics, setWineCharacteristics] = useState(false)
  const [charac, setCharac] = useState('')
  const [fontDecimals, setFontDecimals] = useState(false)

  useEffect(() => {
    const obj = {
      body: '',
      ripeness: '',
      tannins: '',
      aromatics: '',
      acidity: '',
      oak: '',
      alcohol: ''
    }
    if (wine.color === 'Red') {
      for (const score in wine.score) {
        if (score !== 'aromatics') {
          obj[score] = 3
        }
      }
      delete obj.aromatics
      setScoring(obj)
    } else {
      for (const score in wine.score) {
        if (score !== 'tannins') {
          obj[score] = 3
        }
      }
      delete obj.tannins
      setScoring(obj)
    }
  }, [])

  return (
    <Box sx={{ m: 4 }}>
      {
        wineCharacteristics && <Characteristics open={wineCharacteristics} setOpen={setWineCharacteristics} charac={charac} />
      }

      {
        Object.entries(scoring).map((score, i) => {
          const title = score[0] === 'aromatics'
            ? 'Sweetness'
            : score[0].charAt(0).toUpperCase() + score[0].slice(1)
          return <Box key={i}>
              <Typography fontFamily="MundialDemiBold" fontSize={18} sx={{ mb: -1, color: '#2e2a26' }}>{title}</Typography>

              <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <AirbnbSlider
                  components={{ Thumb: AirbnbThumbComponent }}
                  defaultValue={score[1]}
                  aria-label="Default"
                  valueLabelDisplay="off"
                  step={0.5}
                  min={1}
                  max={5}
                  name={score[0]}
                  onChange={({ target }) => {
                    setScoring({ ...scoring, [target.name]: target.value })
                    setFontDecimals(target.value.toString().includes('.'))
                  }}
                  colorprop={wine.backgroundColor}
                  fontDecimals={fontDecimals}
              />
              <IconButton onClick={() => {
                setCharac(score[0])
                setWineCharacteristics(true)
              }}
              sx={{ marginLeft: 2 }}> <Box component="img" src="/questionSymbol.png" alt="" sx={{ width: 27 }} /> </IconButton>
              </Box>
              </Box>
        })
      }
    </Box>
  )
}

export default Scoring
