import React from 'react'
import { useTheme } from '@mui/styles'
import { Box, Typography, Dialog, Slide, Container } from '@mui/material'
import Spinner from 'core/Spinner'
import GuessPriceButton from 'core/guess-price-btn'
import Button from 'core/buttons/Button'
import { useWineriesData } from './hooks'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})
const GuessPriceAsModal = ({ open, setOpen, handleContinue }) => {
  const theme = useTheme()
  const { handleFinish, loading, loadingBtn, wineList, wineExtraPoints, setWineExtraPoints } = useWineriesData(handleContinue)

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', m: 'auto' }}>
     <Dialog
        fullScreen={theme.popUpsWidth}
        sx={{
          display: 'flex',
          width: '100vw',
          // maxWidth: '450px',
          m: 'auto',

          '.MuiBackdrop-root': {
            width: '100vw',
            // maxWidth: '450px',
            m: 'auto'
          },
          '.MuiDialog-container': {
            display: 'flex',
            alignItems: 'flex-end'
          },
          '.MuiPaper-root': {
            width: '100vw',
            // maxWidth: '450px',
            bgcolor: 'transparent'
          }
          // '.MuiDialog-paper': { ml:-5 },
        }}
        open={open}
        onClose={() => setOpen(false)}
        TransitionComponent={Transition}
      >
      <Container disableGutters sx={{ '&.MuiContainer-root': { width: '100vw', maxWidth: '450px', minHeight: 1, bgcolor: 'primary.main' } }}>
        <Box sx={{ bgcolor: 'primary.main' }}>

            <Box sx={{ m: 4, mt: 8 }}>
                <Typography fontFamily="KaiseiDecolBold" fontSize={26}>Win 5 more points</Typography>
                <Typography fontFamily="MundialLight" fontSize={18} sx={{ color: '#575757' }}>Try and guess the price of your wines and get extra points on your final score!</Typography>
            </Box>

            <Box sx={{ mx: 4, my: 2 }}>
                {
                    loading
                      ? <Box sx={{ mt: 20 }}><Spinner /> </Box>
                      : <>
                        {wineList && wineList.map(wine => <GuessPriceButton wine={wine} key={wine.index} wineExtraPoints={wineExtraPoints} setWineExtraPoints={setWineExtraPoints} />)}

                        <Box sx={{ mt: 6, pb: 2, position: 'relative' }}>
                            <Button disabled={loadingBtn} onClick={handleFinish} >
                            {
                                loadingBtn
                                  ? <Spinner />
                                  : <>
                                        <Typography fontFamily="DMSansBold" fontSize={18}>
                                        Finish
                                        </Typography>
                                        <Box component="img" src="/buttonLongArrow.svg" alt="long-arrow" sx={{ position: 'absolute', right: 20 }} />
                                    </>
                            }
                            </Button>
                        </Box>
                    </>
                }
            </Box>

            </Box>
        </Container>
        </Dialog>
    </Box>
  )
}

export default GuessPriceAsModal
