import React, { useEffect, useState } from 'react'
import { useTheme } from '@mui/styles'
import { Box, Button as MuiButton, Dialog, Slide, Container, styled, TextField, Typography } from '@mui/material'
import { usePointsContext } from 'providers/PointsProvider'
import { CharsLeft } from 'utils'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const Input = styled(TextField)((_) => ({
  width: '100%',
  borderRadius: 0,
  marginTop: 4
}))

const TastingNotes = ({
  open,
  setOpen,
  wine,
  readOnly = false,
  onUpdate = null,
  closeButtonLabel = 'Close'
}) => {
  const MAX_LEN = 1000

  const theme = useTheme()

  const [tastingNotes, setTastingNotes] = useState('')

  const handleClose = () => {
    setOpen(!open)
  }

  const {
    getWineNotes,
    setWineNotes
  } = usePointsContext()

  useEffect(() => {
    setTastingNotes(getWineNotes(wine.name) || '')
  }, [wine])

  const handleChange = (e) => {
    const notes = e.target.value.slice(0, MAX_LEN)
    setTastingNotes(notes)
    if (wine?.name) {
      setWineNotes(wine.name, notes)
      if (onUpdate) {
        onUpdate(notes)
      }
    }
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', m: 'auto' }}>
     <Dialog
        fullScreen={theme.popUpsWidth}
        sx={{
          display: 'flex',
          width: '100vw',
          m: 'auto',
          '.MuiBackdrop-root': {
            width: '100vw',
            m: 'auto'
          },
          '.MuiDialog-container': {
            display: 'flex',
            alignItems: 'flex-end'
          },
          '.MuiPaper-root': {
            width: '100vw',
            bgcolor: 'transparent'
          }
        }}
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
      <Container disableGutters sx={{ '&.MuiContainer-root': { width: '100vw', maxWidth: '450px', bgcolor: 'primary.main' } }}>
        <Box style={theme.background} sx={{ height: '100%', display: 'flex', flexDirection: 'column', m: 'auto', p: '1em' }}>
          <Box sx={{ width: 1, mt: 4, mb: 2 }} >
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <MuiButton
                sx={{
                  border: '1px solid black',
                  borderRadius: '50px',
                  height: 30,
                  m: 'auto',
                  px: 1.5,
                  py: 0,
                  width: 1
                }}
                onClick={handleClose}
              >
                <Typography fontFamily="DMSansMedium" fontSize={16} sx={{ color: 'black' }}>
                  {closeButtonLabel}
                </Typography>
              </MuiButton>
            </Box>
          </Box>
          <p>Your wine tasting notes:</p>
          {readOnly
            ? <>
                {tastingNotes
                  .split('\n')
                  .filter((line) => line.length > 0)
                  .map((line, idx) => <p key={idx}>{line}</p>)}
              </>
            : <>
                <Input
                  value={tastingNotes}
                  onChange={handleChange}
                  multiline={true}
                />
                {CharsLeft(tastingNotes.length, MAX_LEN)}
              </>}
        </Box>
      </Container>
    </Dialog>
    </Box>
  )
}

export default TastingNotes
