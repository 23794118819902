import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box } from '@mui/material'
import WineryButton from 'core/WineryButton'
import Spinner from 'core/Spinner'

import { useWineryContext } from 'providers/WineryProvider'

const WineryTab = ({ multiplayer, wineRoomSelected, setWineRoomSelected }) => {
  const navigate = useNavigate()
  const { wineries } = useWineryContext()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    wineries && setLoading(false)
    return () => setLoading(true)
  }, [wineries])

  const handleClick = (winery) => {
    if (multiplayer) {
      setWineRoomSelected('wineRoomSelected', { name: winery.name, id: winery.id })
    } else {
      navigate(`/wine-room/${winery.id}`)
    }
  }

  return (
    <Box sx={{ mt: !multiplayer && 6, mb: 6 }}>
        {
          loading
            ? <Box sx={{ mt: 20 }}><Spinner /> </Box>
            : wineries && wineries.map((winery) => {
              return <WineryButton
              key={winery.index} winery={winery}
              multiplayer={multiplayer}
              handleClick={() => handleClick(winery)}
              isWineryChosen={wineRoomSelected?.id === winery.id}
            />
            }
            )
        }
    </Box>
  )
}

export default WineryTab
