import { createTheme } from '@mui/material/styles'
import './index.css'
import KaiseiReg from './fonts/KaiseiDecol-Regular.ttf'
import KaiseiMed from './fonts/KaiseiDecol-Medium.ttf'
import KaiseiBold from './fonts/KaiseiDecol-Bold.ttf'
// Mundial
import MundialBlack from './fonts/MundialBlack.otf'
import MundialBlackItalic from './fonts/MundialBlackItalic.otf'
import MundialBold from './fonts/MundialBold.otf'
import MundialBoldItalic from './fonts/MundialBoldItalic.otf'
import MundialDemiBold from './fonts/MundialDemibold.otf'
import MundialDemiBoldItalic from './fonts/MundialDemiboldItalic.otf'
import MundialHair from './fonts/MundialHair.otf'
import MundialHairItalic from './fonts/MundialHairItalic.otf'
import MundialItalic from './fonts/MundialItalic.otf'
import MundialLight from './fonts/MundialLight.otf'
import MundialLightItalic from './fonts/MundialLightItalic.otf'
import MundialRegular from './fonts/MundialRegular.otf'
import MundialThin from './fonts/MundialThin.otf'
import MundialThinItalic from './fonts/MundialThinItalic.otf'
// DMSans
import DMSans_Bold from './fonts/DMSans-Bold.ttf'
import DMSans_Medium from './fonts/DMSans-Medium.ttf'
import DMSans_Regular from './fonts/DMSans-Regular.ttf'

const DMSansBold = {
  fontFamily: 'DMSansBold',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  // fontWeight: 400,
  src: `
    local('DMSans'),  
    local('DMSans-Bold'),
    url(${DMSans_Bold}) format('ttf')
  `
}

const DMSansMedium = {
  fontFamily: 'DMSansMedium',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('DMSans'),  
    local('DMSans-Medium'),
    url(${DMSans_Medium}) format('ttf')
  `
}

const DMSansRegular = {
  fontFamily: 'DMSansRegular',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('DMSans'),
    local('DMSans-Regular'),
    url(${DMSans_Regular}) format('ttf')
  `
}

const kaiseiRegular = {
  fontFamily: 'KaiseiDecolReg',
  fontStyle: 'normal',
  fontWeight: 400,
  src: `local(''),
    url(${KaiseiReg}) format('ttf')`
}
const kaiseiMed = {
  fontFamily: 'KaiseiDecolMed',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('KaiseiDecol'),
    local('KaiseiDecol-Medium'),
    url(${KaiseiMed}) format('ttf')
  `
}
const kaiseiBold = {
  fontFamily: 'KaiseiDecolBold',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `
    local('KaiseiDecol'),
    local('KaiseiDecol-Bold'),
    url(${KaiseiBold}) format('ttf')
  `
}
// MUNDIAL
const mundialBlack = {
  fontFamily: 'MundialBlack',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Mundial'),
    local('MundialBlack'),
    url(${MundialBlack}) format('otf')
  `
}
const mundialBlackItalic = {
  fontFamily: 'MundialBlackItalic',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Mundial'),
    local('MundialBlackItalic'),
    url(${MundialBlackItalic}) format('otf')
  `
}
const mundialBold = {
  fontFamily: 'MundialBold',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Mundial'),
    local('MundialBold'),
    url(${MundialBold}) format('otf')
  `
}
const mundialBoldItalic = {
  fontFamily: 'MundialBoldItalic',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Mundial'),
    local('MundialBoldItalic'),
    url(${MundialBoldItalic}) format('otf')
  `
}
const mundialDemiBold = {
  fontFamily: 'MundialDemiBold',
  fontStyle: 'normal',
  // fontDisplay: 'swap',
  fontWeight: 700,
  src: `
    local(''),
    url(${MundialDemiBold}) format('otf')
  `
}
const mundialDemiBoldItalic = {
  fontFamily: 'MundialDemiBoldItalic',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Mundial'),
    local('MundialDemiBoldItalic'),
    url(${MundialDemiBoldItalic}) format('otf')
  `
}
const mundialHair = {
  fontFamily: 'MundialHair',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Mundial'),
    local('MundialHair'),
    url(${MundialHair}) format('otf')
  `
}
const mundialHairItalic = {
  fontFamily: 'MundialHairItalic',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Mundial'),
    local('MundialHairItalic'),
    url(${MundialHairItalic}) format('otf')
  `
}
const mundialItalic = {
  fontFamily: 'MundialItalic',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Mundial'),
    local('MundialItalic'),
    url(${MundialItalic}) format('otf')
  `
}
const mundialLight = {
  fontFamily: 'MundialLight',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Mundial'),
    local('MundialLight'),
    url(${MundialLight}) format('otf')
  `
}
const mundialLightItalic = {
  fontFamily: 'MundialLightItalic',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Mundial'),
    local('MundialLightItalic'),
    url(${MundialLightItalic}) format('otf')
  `
}
const mundialRegular = {
  fontFamily: 'MundialRegular',
  // fontStyle: 'normal',
  // fontDisplay: 'swap',
  // fontWeight: 400,
  src: `
    url(${MundialRegular}) format('otf')
  `
}
const mundialThin = {
  fontFamily: 'MundialThin',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Mundial'),
    local('MundialThin'),
    url(${MundialThin}) format('otf')
  `
}
const mundialThinItalic = {
  fontFamily: 'MundialThinItalic',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Mundial'),
    local('MundialThinItalic'),
    url(${MundialThinItalic}) format('otf')
  `
}

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 330,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      mobile: 420
    }
  },
  palette: {
    primary: {
      main: '#fffdf7'
    },
    secondary: {
      main: '#880937'
    },
    error: {
      main: '#d32f2f',
      light: '#878787'
    },
    background: {
      main: '#b09e9e'
    }
  },

  transitions: {
    duration: {
      long: 4000
    }
  },

  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [kaiseiRegular, kaiseiMed, kaiseiBold, mundialBlack, mundialBlackItalic, mundialBold, mundialBoldItalic, mundialDemiBold, mundialDemiBoldItalic, mundialHair, mundialHairItalic, mundialItalic, mundialLight, mundialLightItalic, mundialRegular, mundialThin, mundialThinItalic, DMSansBold, DMSansMedium, DMSansRegular],
        '.subtitle': {
          color: 'red',
          fontWeight: 'bold'
        }
      }
    }
  },
  background: {
    backgroundColor: '#fffdf7',
    width: '100vw',
    maxWidth: '450px',
    minHeight: '100vh'
  },
  popUpsWidth: {
    width: '100vw',
    maxWidth: '450px'
  },
  paragraph: {
    fontFamily: 'MundialLight',
    color: '#575757'
  },
  title: {
    fontFamily: 'KaiseiDecolBold',
    fontSize: 30
  },
  buttonTitle: {
    fontFamily: 'KaiseiDecolBold'
  }

})
export default theme
