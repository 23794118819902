import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/styles'
import { Box, TextField, Typography } from '@mui/material'

import useApi from 'api/apiRequests'
import { useGameMode } from 'providers/GameMode'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'

import WelcomeHeader from 'core/WelcomeHeader'
import Button from 'core/buttons/Button'
import WineryTable from 'modules/home/WineryTable'
import Spinner from 'core/Spinner'

const CodeSchema = Yup.object().shape({
  nickname: Yup.string().defined()
    .test('len', 'Min 1 character', val => val?.toString().length >= 1)
    .test('len', 'Max 10 characters', val => val?.toString().length <= 10),
  wineRoomSelected: Yup.object().shape({
    name: Yup.string().required('Need to pick a winery'),
    id: Yup.string().required('Need to pick a winery')
  }).required('Need to pick a winery')
})
const wait = async (num) => await new Promise((resolve) => setTimeout(() => resolve(), num * 1000))

const MultiplayerHome = () => {
  const [loading, setLoading] = useState(false)
  const [loadingBtn, setLoadingBtn] = useState(true)
  const theme = useTheme()
  const navigate = useNavigate()
  const { createGame } = useApi()
  const { setStoragePlayerData, getMultiplayerData, removeGameDataState } = useGameMode()
  const [hostGame, setHostGame] = useState(null)

  useEffect(() => {
    if (hostGame) navigate('/game-room')
    if (!hostGame) {
      getMultiplayerData().then(data => {
        if (data) setHostGame(data.host.host)
      }).finally(() => setLoadingBtn(false))
    }
  }, [hostGame])

  const handleCreateGame = async (wineRoomSelected, nickname) => {
    try {
      setLoading(true)
      const game = await createGame({ winery: wineRoomSelected, host: nickname })
      // removeGameDataState()
      setStoragePlayerData({ isHost: true }, nickname, game.id, game.gameId)
      await wait(2)
      setLoading(false)
      navigate('/game-room')
    } catch (e) {
      throw new Error(e)
    }
  }

  return (
    <Box style={theme.background} sx={{ height: '100%', display: 'flex', flexDirection: 'column', m: 'auto' }} >
    <Box sx={{ [theme.breakpoints.up('sm')]: { mx: 2 }, mx: 4, mt: 2, mb: 4 }}>
      <WelcomeHeader />
    </Box>
    {
      loadingBtn
        ? <Spinner />
        : <Formik initialValues={{ wineRoomSelected: {}, nickname: '' }}
              validationSchema={CodeSchema}
              onSubmit={(values) => { handleCreateGame(values.wineRoomSelected, values.nickname.trim()) }}
          >
          {
              ({ errors, touched, values, setFieldValue }) => {
                return (
                  <Form>
        <Box sx={{ }}>
          <Box sx={{ [theme.breakpoints.up('sm')]: { mx: 2 }, mx: 4, mt: 4, mb: 2 }}>
            <Typography fontFamily="MundialRegular" fontWeight={300} fontSize={16}>Create nickname</Typography>
            {
                errors?.nickname
                  ? <Box sx={{ mt: 2 }}>
                      <Box sx={{ display: 'flex', width: 1, alignItems: 'center', justifyContent: 'flex-start' }}>
                        <Box component="img" src="/email/errorIcon.svg" alt="error-icon" sx={{ mr: 1 }} />
                        <Typography fontFamily="MundialLight" fontWeight={300} fontSize={18}
                          textAlign="left" color="#EA4928">{errors.nickname}</Typography>
                      </Box>
                    </Box>
                  : null
            }
          </Box>
          {
            hostGame
              ? <Box sx={{ width: 1, display: 'flex', justifyContent: 'flex-end' }}><Button sx={{ p: 1, width: 0.4, mr: 2 }} onClick={() => navigate('/game-room')}>
              <Typography fontFamily="DMSansRegular" fontWeight={700} fontSize={18}>
                {hostGame}&apos;s game
              </Typography></Button></Box>
              : null
          }
          <TextField name="nickname"
            onChange={(e) => {
              const value = e.target.value
              if (value.trim() || values.nickname.length) {
                const nickName = value.charAt(0).toUpperCase() + value.slice(1)
                setFieldValue(e.target.name, nickName)
              }
            }} value={values.nickname}
            id="outlined-basic"
            autoFocus
            autoComplete="off"
            placeholder="Type here..."
            sx={{
              width: 1,
              '& .MuiOutlinedInput-root': {
                height: 68,
                bgcolor: '#FFFFFF',
                input: {
                  padding: 1.5,
                  pl: 6,
                  fontSize: 20,
                  fontWeight: 700,
                  fontFamily: 'KaiseiDecolReg',
                  color: '#000000'
                },
                fieldset: {
                  borderTop: '1px solid #F0D7C1',
                  borderBottom: '1px solid #F0D7C1',
                  borderLeft: 'none',
                  borderRight: 'none',
                  padding: 0
                },
                '&:hover fieldset': {
                  borderBottom: '1px solid #F0D7C1',
                  borderTop: '1px solid #F0D7C1',
                  borderLeft: 'none',
                  borderRight: 'none'
                },
                '&.Mui-focused fieldset': {
                  borderTop: '1px solid #F0D7C1',
                  borderBottom: '1px solid #F0D7C1',
                  borderLeft: 'none',
                  borderRight: 'none'
                }
              }
            }}/>

          <Box sx={{ [theme.breakpoints.up('sm')]: { mx: 2 }, mx: 4, mt: 5 }}>
            <Typography fontFamily="MundialRegular" fontWeight={300} fontSize={16}>Choose winery</Typography>
            {
              touched.wineRoomSelected && errors?.wineRoomSelected
                ? <Box sx={{ mt: 2 }}>
                    <Box sx={{ display: 'flex', width: 1, alignItems: 'center', justifyContent: 'flex-start' }}>
                      <Box component="img" src="/email/errorIcon.svg" alt="error-icon" sx={{ mr: 1 }} />
                      <Typography fontFamily="MundialLight" fontWeight={300} fontSize={18}
                        textAlign="left" color="#EA4928">{errors.wineRoomSelected.name}</Typography>
                    </Box>
                  </Box>
                : null
            }
          </Box>
        </Box>

        <Box sx={{ [theme.breakpoints.up('sm')]: { mx: 2 }, mx: 4, mb: 4 }}>
          <WineryTable multiplayer wineRoomSelected={values.wineRoomSelected} setWineRoomSelected={setFieldValue} />
          {
            loading
              ? <Spinner />
              : <Button type="submit" >
                  <Typography fontFamily="DMSansRegular" fontWeight={700} fontSize={18}>Create game</Typography>
                </Button>
          }
        </Box>
          </Form>)
              }
      }
      </Formik>
    }
  </Box>
  )
}

export default MultiplayerHome
