import { useEffect, useState } from 'react'

const LoadingDots = () => {
  const [threeDots, setThreeDots] = useState(null)

  const getThreeDots = (param) => {
    if (!param) setThreeDots('.')
    if (param === '.') setThreeDots('..')
    if (param === '..') setThreeDots('...')
    if (param === '...') setThreeDots('.')
  }
  useEffect(() => {
    setTimeout(() => {
      setThreeDots(prev => {
        getThreeDots(prev)
      })
    }, 1000)
  }, [threeDots])

  return threeDots
}

export default LoadingDots
