import React from 'react'
import { useTheme } from '@mui/styles'
import { Box } from '@mui/material'

import WineryTable from './WineryTable'
import WelcomeHeader from 'core/WelcomeHeader'

const Home = () => {
  const theme = useTheme()

  return (
    <Box style={theme.background} sx={{ height: '100%', display: 'flex', flexDirection: 'column', m: 'auto' }} >
      <Box sx={{ [theme.breakpoints.up('sm')]: { mx: 2 }, mx: 4, mt: 2, mb: 4 }}>
        <WelcomeHeader />
        <WineryTable />
      </Box>
    </Box>
  )
}

export default Home
