import React, { useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Box, Grid, Typography, IconButton, Button as MuiButton } from '@mui/material'
import { useTheme } from '@mui/styles'
import ArrowIcon from '@mui/icons-material/ArrowBackIos'
import Button from 'core/buttons/Button'
import Spinner from 'core/Spinner'
import WineButton from 'core/WineButton/singleplayer'
import HeaderImgBox from 'core/HeaderImgBox'

import AboutUs from 'core/about-us'
import StartPlaying from './start-playing'
import OnBoarding from 'modules/how-to-play'

import { useWineryContext } from 'providers/WineryProvider'
import { useWineContext } from 'providers/WinesProvider'
// import { usePointsContext } from 'providers/PointsProvider'

import { SCORING, priceRange } from 'utils'
import { useWinesAndWineriesHook, getNextWineryToScore, useSinglePlayerWineHook } from './Hooks'

const WineRoom = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { id } = useParams()
  const { pathname } = useLocation()

  const { loadingWines, setLoadingWines, wines, winery, nextWineryToScore, setNextWineryToScore } = useWinesAndWineriesHook(id)
  const { wineToScore, getWinePoints, RemoveWineryPoints, isRated, wineryPoints, isFullyScored, scoredPlusExtraPoints, restart, setRestart } = useSinglePlayerWineHook(winery, setNextWineryToScore)
  const { wineries, getWineryPreference, setWineryPreferences } = useWineryContext()
  const { isWineScored } = useWineContext()

  const wineryPreference = getWineryPreference(winery?.name)
  const userPreference = wineryPreference?.scoringPreference === SCORING ? 'Score' : 'Rate'
  // pop ups
  const [openAbout, setOpenAbout] = useState(false)
  const [startPlaying, setStartPlaying] = useState(false)
  const [openOnBoarding, setOpenOnBoarding] = useState(false)
  const closeOnBoarding = () => setOpenOnBoarding(false)

  const [priceMin, priceMax] = wines ? priceRange(wines) : [18, 35]
  const largeNum = wineryPoints.toString().length >= 2
  const XlNum = wineryPoints.toString().length >= 3

  return (
        <Box style={theme.background} sx={{ height: '100%', display: 'flex', flexDirection: 'column', m: 'auto' }}>
            {
                !winery || !wines
                  ? <Spinner />
                  : <>
              <HeaderImgBox backgroundImg='/wineriesBackground.jpg' brightness={{ filter: 'brightness(35%)' }}>
                <Grid container>
                  <Grid item xs={4}>
                      <IconButton onClick={() => navigate('/home')}> <ArrowIcon sx={{ height: 20, color: 'white' }} /> </IconButton>
                  </Grid>
                  <Grid item xs={3} sx={{ display: 'flex' }}>
                      <Typography fontFamily="DMSansRegular" fontSize={16} sx={{ [theme.breakpoints.up('sm')]: { ml: 3 }, m: 'auto', color: 'white' }}>Game {winery.index + 1}</Typography>
                  </Grid>
                  <Grid item xs={5} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <MuiButton onClick={() => setOpenAbout(!openAbout)}
                      sx={{ height: 30, px: 1, py: 0, my: 'auto', border: '1px solid white', borderRadius: '50px' }}>
                          <Typography fontFamily="DMSansMedium" color="white" fontSize={14} sx={{ m: 0, p: 0 }} >About Game</Typography>
                      </MuiButton>
                  </Grid>
                </Grid>
                <Grid container sx={{ my: 4 }}>
                    <Grid item xs={4} sx={{ display: 'flex', position: 'relative' }}>
                    <Box item xs={4} sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      position: 'relative',
                      width: 90,
                      [theme.breakpoints.up('sm')]: { top: 0, left: 0 }
                    }}>
                        <Box sx={{
                          position: 'absolute',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}>
                            <Typography fontFamily="KaiseiDecolBold" color="white" sx={{
                              [theme.breakpoints.up('sm')]: {
                                fontSize: XlNum ? 28 : largeNum ? 38 : 44,
                                mt: wineryPoints.toString().length <= 2 ? 0.5 : XlNum ? 2 : 1.5
                              },
                              fontSize: XlNum ? 28 : largeNum ? 38 : 44,
                              m: 'auto',
                              mt: wineryPoints.toString().length <= 2 ? 0.5 : XlNum ? 2 : 1,
                              mb: largeNum ? -1 : -2
                            }} >{wineryPoints}</Typography>
                            <Typography fontFamily="MundialBold" color="white" fontSize={18} sx={{ m: 'auto' }} >Points</Typography>
                        </Box>
                        <Box component="img" src="/emptyStar.svg" alt="points" sx={{ width: 1, m: 'auto', mt: 0 }} />
                    </Box>
                    </Grid>
                    <Grid item xs={8} sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography fontFamily="KaiseiDecolBold" fontSize={24} sx={{ color: 'white' }}>{ winery.name }</Typography>
                        <Box sx={{ display: 'flex', mt: 0.5, ml: -0.3 }}>
                        {
                            wines && wines.map((w, i) => {
                              const isScored = getWinePoints(w.name)
                              return <Box key={i} component="img" src={`${!isScored ? '/glassIcon.svg' : '/filledGlass.svg'}`} alt="glass" sx={{ p: 0.5, pl: i === 0 && 0, ml: i === 0 && 0, mr: i === 0 && 0.5, mx: i !== 0 && 0.5, height: 37, width: 24 }} />
                            })
                        }
                        </Box>
                    </Grid>
                </Grid>
              </HeaderImgBox>

                {
                    loadingWines
                      ? <Spinner />
                      : <Box sx={{ [theme.breakpoints.up('sm')]: { mx: 2 }, mx: 4 }}>
                        <Box sx={{ my: 4 }}>
                            <Typography component="h1" variant="h2" fontSize={20} sx={{ fontFamily: 'MundialRegular', textAlign: 'center' }}>
                                Today&apos;s wines range from <br /> <Typography fontFamily="KaiseiDecolBold" fontSize={20}> £{priceMin} to £{priceMax} </Typography>
                            </Typography>
                        </Box>

                        { wines
                          ? wines.map((wine, i) => {
                            const showScoredWineDetails = wine.name === isWineScored?.name
                            return <WineButton key={i} wine={wine} showDetails={showScoredWineDetails ?? null} isFullyScored={isFullyScored} scoredPlusExtraPoints={scoredPlusExtraPoints} />
                          })
                          : null }

                        <Box sx={{ mt: 6, mb: 4 }}>
                        {
                            nextWineryToScore === undefined
                              ? <Button onClick={() => { navigate(`${pathname}/guess-price`) }}>
                                <Typography fontFamily="DMSansBold" fontSize={18}>Finish</Typography>
                            </Button>
                              : (
                                  scoredPlusExtraPoints
                                    ? <Button onClick={() => {
                                      navigate(`/wine-room/${getNextWineryToScore(wineries)}`, { replace: true })
                                      window.scrollTo({ top: 0, behavior: 'smooth' })
                                    }} >
                                    <Typography fontFamily="DMSansBold" fontSize={18}>Next winery</Typography>
                                </Button>
                                    : isFullyScored
                                      ? <Button onClick={() => { navigate(`${pathname}/guess-price`) }} >
                                    <Typography fontFamily="DMSansBold" fontSize={18}>Bonus Round - Guess the Price</Typography>
                                </Button>
                                      : wineryPoints
                                        ? <Button onClick={() => {
                                          navigate(`/rate/${wineToScore.id}/scoring`)
                                        }} >
                                    <Typography fontFamily="DMSansBold" fontSize={18}>Score next wine</Typography>
                                </Button>
                                        : isRated
                                          ? <Button onClick={() => {
                                            navigate(`/rate/${wineToScore.id}/${wineryPreference?.scoringPreference}`)
                                          }} >
                                    <Typography fontFamily="DMSansBold" fontSize={18}>{userPreference} next wine</Typography>
                                </Button>
                                          : <Button onClick={() => {
                                            window.scrollTo({ top: 0, behavior: 'smooth' })
                                            setStartPlaying(!startPlaying)
                                          }}>
                                    <Typography fontFamily="DMSansBold" fontSize={18}>Start</Typography>
                                </Button>
                                )
                        }
                        {
                            wineryPoints || isRated
                              ? <Button sx={{ mt: 2 }} onClick={() => {
                                setLoadingWines(true)
                                try {
                                  const winesData = wines.map(W => ({ wineName: W.name, winery: W.winery }))
                                  RemoveWineryPoints(winesData)

                                  setTimeout(() => {
                                    setRestart(!restart)
                                    setLoadingWines(false)
                                  }, 1000)
                                } catch (e) {
                                  console.log('error:', e)
                                  setLoadingWines(false)
                                  alert('Error. Please try again')
                                  localStorage.clear()
                                }
                              }}>
                                <Typography fontFamily="DMSansBold" fontSize={18}>Reset game points</Typography>
                            </Button>
                              : null
                        }
                        </Box>
                    </Box>
                }
            </>
            }

            {
                winery && openAbout &&
                <AboutUs themeOp={theme} open={openAbout} setOpen={setOpenAbout} name={winery.name} location={winery.location} description={winery.description} />
            }
            {
                startPlaying &&
                <StartPlaying open={startPlaying} setOpen={setStartPlaying} openBoarding={openOnBoarding} setOpenOnBoarding={setOpenOnBoarding} />
            }

            {
                openOnBoarding && wineToScore &&
                <OnBoarding open={openOnBoarding} handleClose={closeOnBoarding} wineToScore={wineToScore} handleClick={() => {
                  setWineryPreferences(wineToScore.winery, SCORING)
                  navigate(`/rate/${wineToScore.id}/scoring`)
                }} />
            }

        </Box>
  )
}

export default WineRoom
