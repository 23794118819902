import React, { useState } from 'react'
import { useTheme } from '@mui/styles'
import { Box, Dialog, Slide, Container, TextField, Typography } from '@mui/material'

import Button from 'core/buttons/Button'
import Header from 'modules/game-room/Header'

import { useGameMode } from 'providers/GameMode'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import Spinner from 'core/Spinner'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const CodeSchema = Yup.object().shape({
  nickName: Yup.string().defined()
    .test('len', 'Min 1 character', val => val?.toString().length >= 1)
    .test('len', 'Max 10 characters', val => val?.toString().length <= 10)
})
const JoinGameModal = ({ handleClose, goBack }) => {
  const [loading, setLoading] = useState(false)
  const theme = useTheme()
  // const navigate = useNavigate()
  const { gameData, joinGameRequest } = useGameMode()

  const handleJoinRoom = async (nickName, setErrors) => {
    setLoading(true)
    const result = await joinGameRequest(gameData.gameId, nickName)
    if ('error' in result) setErrors({ nickName: result.error })
    else {
      if ('game' in result) handleClose()
    }
    setLoading(false)
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 20 }}>
      <Dialog
        fullScreen={theme.popUpsWidth}
        sx={{
          display: 'flex',
          width: '100vw',
          m: 'auto',

          '.MuiBackdrop-root': {
            width: '100vw',
            m: 'auto'
          },
          '.MuiDialog-container': {
            display: 'flex',
            alignItems: 'flex-end'
          },
          '.MuiPaper-root': {
            width: '100vw',
            bgcolor: 'transparent'
          }
        }}
        open={open}
        // onClose={handleClose}
        TransitionComponent={Transition}
      >
        <Container disableGutters
          sx={{
            '&.MuiContainer-root': {
              width: '100vw',
              maxWidth: '450px',
              [theme.breakpoints.up('sm')]: { minHeight: 600, height: 1 },
              minHeight: 550,
              height: 1,
              bgcolor: 'transparent'
            },
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end'
          }}
        >
        <Box sx={{ minHeight: 1, bgcolor: '#FFFDF7', position: 'relative' }}>
            <Header game={gameData} handleClick={goBack} />

            <Formik initialValues={{ nickName: '' }}
                validationSchema={CodeSchema}
                onSubmit={(values, { setErrors }) => {
                  const nickName = values.nickName.trim()
                  handleJoinRoom(nickName.charAt(0).toUpperCase() + nickName.slice(1), setErrors)
                }}
            >
                {
                    ({ errors, values, handleChange }) => {
                      return (
                    <Form>
                    <Box sx={{ [theme.breakpoints.up('sm')]: { mt: 5 }, mt: 5 }}>
                        <Box sx={{ [theme.breakpoints.up('sm')]: { mx: 2 }, mx: 4, mt: 4, mb: 2 }}>
                            <Typography fontFamily="MundialRegular" fontWeight={300} fontSize={16}>Create nickname</Typography>
                        </Box>

                        <TextField name="nickName" onChange={(e) => {
                          const value = e.target.value.trim()
                          if (value || values.nickName.length === 1) handleChange(e)
                        }} value={values.nickName && values.nickName.charAt(0).toUpperCase() + values.nickName.slice(1)}
                            id="outlined-basic"
                            autoFocus
                            placeholder="Type here..."
                            sx={{
                              width: 1,
                              '& .MuiOutlinedInput-root': {
                                height: 68,
                                bgcolor: '#FFFFFF',
                                borderRadius: 0,
                                m: 0,
                                p: 0,
                                input: {
                                  m: 0,
                                  pl: 6,
                                  fontSize: 20,
                                  fontWeight: 700,
                                  fontFamily: 'KaiseiDecolReg',
                                  color: '#000000'
                                },
                                fieldset: {
                                  borderTop: '1px solid #F0D7C1',
                                  borderBottom: '1px solid #F0D7C1',
                                  borderLeft: 'none',
                                  borderRight: 'none',
                                  padding: 0
                                },
                                '&:hover fieldset': {
                                  borderBottom: '1px solid #F0D7C1',
                                  borderTop: '1px solid #F0D7C1',
                                  borderLeft: 'none',
                                  borderRight: 'none'
                                },
                                '&.Mui-focused fieldset': {
                                  borderTop: '1px solid #F0D7C1',
                                  borderBottom: '1px solid #F0D7C1',
                                  borderLeft: 'none',
                                  borderRight: 'none'
                                }
                              }
                            }}/>
                    </Box>

                        <Box sx={{ display: 'grid', p: 3, position: 'absolute', bottom: 3, width: 1 }}>
                          {
                            errors?.nickName
                              ? <Typography fontFamily="MundialLight" fontWeight={300} fontSize={18} pt={2}
                              textAlign="center" color="red">{errors.nickName}</Typography>
                              : null
                          }
                            <Button disabled={errors?.nickName || loading} type="submit" sx={{ m: 'auto' }}>
                              {
                                loading
                                  ? <Spinner />
                                  : <Typography fontFamily="DMSansRegular" fontWeight={700} fontSize={18}>
                                Join game
                                </Typography>

                              }
                            </Button>
                        </Box>
                    </Form>
                      )
                    }
                }

            </Formik>
        </Box>
        </Container>
      </Dialog>
    </Box>
  )
}

export default JoinGameModal
