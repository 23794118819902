import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Grid, Typography, IconButton, Button as MuiButton } from '@mui/material'
import { useTheme } from '@mui/styles'
import ArrowIcon from '@mui/icons-material/ArrowBackIos'
import Spinner from 'core/Spinner'
import WineButton from 'core/WineButton/multiplayer'
import HeaderImgBox from 'core/HeaderImgBox'
import AboutUs from 'core/about-us'
import { useGameMode } from 'providers/GameMode'

import { priceRange } from 'utils'
import { useWinesAndWineriesHook, useMultiplayerWineHook } from 'modules/wine-room/Hooks'
import { useWineContext } from 'providers/WinesProvider'
import { UnSubscribeMethod } from 'modules/game-room/hooks/FirebaseRealTimeHook'

const MultiWineRoom = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { id } = useParams()

  const { guessed, gameData } = useGameMode()
  const { loadingWines, wines, winery } = useWinesAndWineriesHook(id)
  const { wineryPoints, isFullyScored } = useMultiplayerWineHook(wines)
  const { isWineScored } = useWineContext()
  // pop ups
  const [openAbout, setOpenAbout] = useState(false)

  const [priceMin, priceMax] = wines ? priceRange(wines) : [18, 35]
  const largeNum = wineryPoints.toString().length >= 2
  const XlNum = wineryPoints.toString().length >= 3

  return (
    <Box style={theme.background} sx={{ height: '100%', display: 'flex', flexDirection: 'column', m: 'auto' }}>
      { gameData ? <UnSubscribeMethod gameData={gameData} /> : null }
            {
                !winery || !wines
                  ? <Spinner />
                  : <>
              <HeaderImgBox backgroundImg='/wineriesBackground.jpg' brightness={{ filter: 'brightness(35%)' }}>
                <Grid container>
                  <Grid item xs={4}>
                      <IconButton onClick={() => navigate('/game-room')}> <ArrowIcon sx={{ height: 20, color: 'white' }} /> </IconButton>
                  </Grid>
                  <Grid item xs={3} sx={{ display: 'flex' }}>
                      <Typography fontFamily="DMSansRegular" fontSize={16} sx={{ [theme.breakpoints.up('sm')]: { ml: 3 }, m: 'auto', color: 'white' }}>Game {winery.index + 1}</Typography>
                  </Grid>
                  <Grid item xs={5} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <MuiButton onClick={() => setOpenAbout(!openAbout)}
                      sx={{ height: 30, px: 1, py: 0, my: 'auto', border: '1px solid white', borderRadius: '50px' }}>
                          <Typography fontFamily="DMSansMedium" color="white" fontSize={14} sx={{ m: 0, p: 0 }} >About Game</Typography>
                      </MuiButton>
                  </Grid>
                </Grid>
                <Grid container sx={{ my: 4 }}>
                    <Grid item xs={4} sx={{ display: 'flex', position: 'relative' }}>
                    <Box item xs={4} sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      position: 'relative',
                      width: 90,
                      [theme.breakpoints.up('sm')]: { top: 0, left: 0 }
                    }}>
                        <Box sx={{
                          position: 'absolute',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}>
                            <Typography fontFamily="KaiseiDecolBold" color="white" sx={{
                              [theme.breakpoints.up('sm')]: {
                                fontSize: XlNum ? 28 : largeNum ? 38 : 44,
                                mt: wineryPoints.toString().length <= 2 ? 1 : XlNum ? 2 : 1.5
                              },
                              fontSize: XlNum ? 28 : largeNum ? 38 : 44,
                              m: 'auto',
                              mt: wineryPoints.toString().length <= 2 ? 1 : XlNum ? 2 : 1,
                              mb: largeNum ? -1 : -2
                            }} >{wineryPoints}</Typography>
                            <Typography fontFamily="MundialBold" color="white" fontSize={18} sx={{ m: 'auto' }} >Points</Typography>
                        </Box>
                        <Box component="img" src="/emptyStar.svg" alt="points" sx={{ width: 1, m: 'auto', mt: 0 }} />
                    </Box>
                    </Grid>
                    <Grid item xs={8} sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography fontFamily="KaiseiDecolBold" fontSize={24} sx={{ color: 'white' }}>{ winery.name }</Typography>
                        <Box sx={{ display: 'flex', mt: 0.5, ml: -0.3 }}>
                        {
                            wines && wines.map((w, i) => {
                              const isScored = isFullyScored(w.id)
                              return <Box key={i} component="img" src={`${!isScored ? '/glassIcon.svg' : '/filledGlass.svg'}`} alt="glass" sx={{ p: 0.5, pl: i === 0 && 0, ml: i === 0 && 0, mr: i === 0 && 0.5, mx: i !== 0 && 0.5, height: 37, width: 24 }} />
                            })
                        }
                        </Box>
                    </Grid>
                </Grid>
              </HeaderImgBox>

                {
                    loadingWines
                      ? <Spinner />
                      : <Box sx={{ [theme.breakpoints.up('sm')]: { mx: 2 }, mx: 4 }}>
                        <Box sx={{ my: 4 }}>
                            <Typography component="h1" variant="h2" fontSize={20} sx={{ fontFamily: 'MundialRegular', textAlign: 'center' }}>
                                Today&apos;s wines range from <br /> <Typography fontFamily="KaiseiDecolBold" fontSize={20}> £{priceMin} to £{priceMax} </Typography>
                            </Typography>
                        </Box>

                        { wines
                          ? wines.map((wine) => {
                            const showScoredWineDetails = wine.name === isWineScored?.name || (guessed.guessed && wine.index === gameData?.wines.length)
                            return <WineButton key={wine.index} wine={wine} showDetails={showScoredWineDetails ?? null} isFullyScored={isFullyScored(wine.id)} />
                          })
                          : null }

                    </Box>
                }
            </>
            }

            {
                winery && openAbout &&
                <AboutUs themeOp={theme} open={openAbout} setOpen={setOpenAbout} name={winery.name} location={winery.location} description={winery.description} />
            }

        </Box>
  )
}

export default MultiWineRoom
