import React from 'react'
import { Box, Typography } from '@mui/material'
import { compareWithExpert } from 'utils'
import { useTheme } from '@mui/styles'

const RadarCustomLabels = ({ top, left, topMobile, leftMobile, label, expertPoints, userPoints }) => {
  const points = compareWithExpert(userPoints, expertPoints)
  const theme = useTheme()

  return (
    <Box sx={{
      zIndex: 10,
      bgcolor: 'white',
      position: 'absolute',
      display: 'grid',
      borderRadius: '10px',
      border: '1px solid gray',
      [theme.breakpoints.up('mobile')]: { top, left },
      top: topMobile,
      left: leftMobile,
      minWidth: '80px'
    }}>
        <Box sx={{ display: 'flex', borderBottom: '1px solid gray', width: 1, p: 0.5 }}>
            <Typography fontFamily="MundialLight" fontSize={18} sx={{ textAlign: 'center', m: 'auto' }}>{ `${label[0].toUpperCase()}${label.slice(1)}` }</Typography>
        </Box>
        <Box sx={{ display: 'flex', width: 1, px: 0.5 }}>
            <Box sx={{ display: 'flex', m: 'auto', justifyContent: 'center', alignItems: 'center' }}>
                <Typography fontFamily="MundialBold" fontSize={24} pr={0.3} >{ points } </Typography>
                <Typography fontFamily="MundialBold" fontSize={16} sx={{ textAlign: 'top' }} >points</Typography>
            </Box>
        </Box>
    </Box>
  )
}

export default RadarCustomLabels
