import React, { useEffect } from 'react'
import { Box, Container, Typography, useTheme } from '@mui/material'
import { PieChart, Pie, ResponsiveContainer, Cell } from 'recharts'
import LoadingDots from './LoadingDots'
import { useGameMode } from 'providers/GameMode'

// const playersReadyFunc = (players, singleProportion, isStart) => {
//   return (players.filter(player => player[isStart ? 'isReady' : 'finished'] === true)).map(player => ({ player, value: singleProportion }))
// }

const WaitingForPlayers = ({ players, content, isStart }) => {
  // const theme = useTheme()
  const { player, gameData, startGameRequest } = useGameMode()

  // const fullMark = 450
  // const singleProportion = fullMark / (players.length)
  // const playersReady = playersReadyFunc(players, singleProportion, isStart)
  // const proportionReady = (playersReady.length / (players.length)) * fullMark

  useEffect(() => {
    if (gameData?.round === 1) {
      const currentPlayer = gameData.players.find(pl => pl.nickName === player.nickname)
      // console.log(' is player data currentPlayer', currentPlayer)
      if (currentPlayer && !currentPlayer.isReady) startGameRequest(gameData.gameId, player.nickname)
    }
  }, [])

  return (
    <Container disableGutters sx={{ height: 1, bgcolor: '#FFFDF700' }}>
        <Box sx={{ height: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Box component="img" src="/multiplayer/waiting.svg" sx={{ mb: 5, mt: 2 }} />
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Typography sx={{ textAlign: 'center' }} fontFamily="KaiseiDecolBold" fontSize={30}>Waiting </Typography>
            <Box sx={{ width: 30, fontSize: 30 }}>{LoadingDots()}</Box>
            </Box>
            <Typography sx={{ mx: 8 }} fontFamily="MundialLight" fontSize={18} fontWeight={300} color="#575757" textAlign="center">
                {content}
            </Typography>
        </Box>
    </Container>
  )
}

export default WaitingForPlayers
