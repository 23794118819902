import React, { Suspense } from 'react'
import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom'
import { Box } from '@mui/material'

import Splash from 'modules/splash'

import WineryProvider from 'providers/WineryProvider'
import WinesProvider from 'providers/WinesProvider'
import PointsProvider from 'providers/PointsProvider'
import GameModeProvider from 'providers/GameMode'

import RoutesConfig from './RoutesConfig.js'
import { useGameMode } from 'providers/GameMode.jsx'
import GameMode from 'modules/game-mode/index.jsx'

const RouteComponent = (props) => {
  const { multiplayer } = useGameMode()
  const { component: Component, ...rest } = props.route
  return <Component {...rest} multiplayer={multiplayer.value} />
}

const OnBoarding = React.lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import('modules/onboarding_one')), 5000)
  })
})

function App () {
  const toRouteComponents = (route) => <Route key={route.name} path={route.path} element={<RouteComponent route={route} />} />

  return (
    <Box sx={{ width: '100%', bgcolor: '#b09e9e' }}>

      <BrowserRouter>
        <Routes>
          <Route path="/" element={
            <Suspense fallback={<Splash />}>
              <OnBoarding />
            </Suspense>
          } />

{/* {
      component: (props) => <GameMode {...props} />,
      name: 'Game Mode',
      path: '/game-mode'
    }, */}
          <Route path="/game-mode" element={<GameMode /> } />

          <Route element={
            <GameModeProvider>
              <WineryProvider>
                <WinesProvider>
                  <PointsProvider>
                    <Outlet />
                  </PointsProvider>
                  </WinesProvider>
              </WineryProvider>
            </GameModeProvider>
          }>

            { RoutesConfig().map(toRouteComponents) }
            </Route>
        </Routes>
      </BrowserRouter>
    </Box>
  )
}

export default App
