import React from 'react'

export default function EmailTemplate ({ props }) {
  return (
    <div style={ { backgroundColor: '#fff4de', textAlign: 'center' } }>
      <div style={{ maxWidth: '500px', display: 'inline-block' }}>
        {props.score &&
          <h1>I just scored {props.score} points at Wine Smash!</h1>}
        <h2>Today&apos;s Wines:</h2>
        {(props.items || []).map((item, idx) =>
          <div key={idx} style={ { padding: '1em' } }>
            {item.url && <a href={item.url} target="_blank" rel="noreferrer" style={ {
              backgroundColor: item.color,
              display: 'inline-block',
              padding: '1em',
              textAlign: 'center',
              width: '12%'
            } }>
              <img style={ {
                maxWidth: '100%'
              } } src={item.image} />
            </a>
            }
            {!item.url && <div style={ {
              backgroundColor: item.color,
              display: 'inline-block',
              padding: '1em',
              textAlign: 'center',
              width: '12%'
            } }>
              <img style={ {
                maxWidth: '100%'
              } } src={item.image} />
            </div>
            }
            <div style={ {
              display: 'inline-block',
              textAlign: 'left',
              verticalAlign: 'top',
              width: '80%'
            } }>
              {item.rating &&
                <img style={ {
                  maxHeight: '64px',
                  maxWidth: '160px',
                  paddingLeft: '0.7em'
                } } src={item.rating} />
              }
              <ul style={ { listStyleType: 'none', paddingLeft: '1em', marginTop: '0.5em' } }>
                <li style={ { marginBottom: '0.5em' } }><strong>Producer: </strong><br/>{item.producer}</li>
                <li style={ { marginBottom: '0.5em' } }><strong>Name: </strong><br/>{item.name}</li>
                <li><strong>Grape blend: </strong><br/>{item.grape}</li>
              </ul>
            </div>
            {item.tasting_notes &&
              <div style={ {
                display: 'inline-block',
                textAlign: 'left',
                verticalAlign: 'top',
                width: '80%'
              } }>
                <h3>Tasting notes:</h3>
                {item.tasting_notes
                  .split('\n')
                  .filter((line) => line.length > 0)
                  .map((line, idx) => <p key={idx}>{line}</p>)}
              </div>}
          </div>
        )}
      </div>
    </div>
  )
}
