import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box } from '@mui/material'
import { useTheme } from '@mui/styles'

import Header from './Header'
import PlayersSection from './Players'
import BottomButtons from './delete-start'
import GameInvite from './game-invite'
import JoinCodeModal from './game-invite/JoinCode'
import WaitingForPlayers from 'core/waiting-players/WaitingForPlayers'
import Spinner from 'core/Spinner'

import { useGameMode } from 'providers/GameMode'
import { checkEveryoneReadyToStart, checkRoundFinished, useCheckIsPlayer } from './hooks'
import { UnSubscribeMethod } from './hooks/FirebaseRealTimeHook'

const GameRoom = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const [joinModal, setJoinModal] = useState(false)
  const { player, gameData, currentRound, getGameRequest, getMultiplayerData, removeGameDataState } = useGameMode()
  const { checkIsPlayerOrHost } = useCheckIsPlayer()

  useEffect(() => {
    checkIsPlayerOrHost(joinModal).then(res => { setJoinModal(res) })
    if (!gameData) {
      getMultiplayerData().then(data => {
        if (player.nickname && !data) navigate('/game-mode')
        if (!data) removeGameDataState()
      })
    }
    if (gameData && currentRound?.id && checkEveryoneReadyToStart(gameData.round > 1 ? currentRound : gameData) &&
    !checkRoundFinished(currentRound) && player.nickname && !player.isHost) navigate(`/multiplayer/${currentRound.id}/scoring`)
    if (gameData?.round === 6 && !player.isHost) navigate(`/game-room/${gameData?.winery.id}`)
  }, [currentRound, gameData])

  return (
    <Box style={theme.background} sx={{ position: 'relative', height: 1, display: 'flex', flexDirection: 'column', m: 'auto' }}>
      { gameData ? <UnSubscribeMethod gameData={gameData} /> : null }
      {
        !gameData
          ? <Spinner />
          : <>
            <Header player={player} game={gameData} handleClick={() => navigate('/multiplayer')} />
            {
              player.isReady && gameData.players && !player.isHost
                ? <WaitingForPlayers players={gameData.players} content={gameData.round > 1 ? 'Waiting for other players to be ready' : 'We are waiting for the host to start the game'} isStart />
                : <>
                    <GameInvite gameData={gameData}/>

                    <PlayersSection gameData={gameData} currentPlayer={player} />

                    <BottomButtons />
                  </>
            }
          </>
      }
      {
        joinModal
          ? <JoinCodeModal getGameRequest={getGameRequest} handleClose={() => setJoinModal(false)} />
          : null
      }
    </Box>
  )
}

export default GameRoom
