import { usePointsContext } from 'providers/PointsProvider'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'

export const usePointSetin = (wine, wineExtraPoints, setWineExtraPoints) => {
  const { setWinePoints } = usePointsContext()
  const { pathname } = useLocation()
  const [isMultiplayer] = useState(pathname.includes('game-room'))

  const ifGuess = (extraPoints) => {
    if (isMultiplayer) {
      setWineExtraPoints({ ...wineExtraPoints, [wine.name]: extraPoints })
    } else {
      setWinePoints(wine.name, '', extraPoints)
    }
  }

  return { ifGuess }
}
