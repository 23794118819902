import React, { useState } from 'react'
import { Box, Divider, Grid, Typography } from '@mui/material'
import { useTheme } from '@mui/styles'
import Button from 'core/buttons/Button'
import { Chart, RadialLinearScale, PointElement, LineElement, CategoryScale, LinearScale, BarElement } from 'chart.js'
import { matchPercentage } from 'modules/scoring/MatchResults'
import AboutExpert from 'modules/wine-room/wine-details/AboutExpert'
import RadarChart from 'modules/wine-room/wine-details/RadarChart'
import { compareWithExpert } from 'utils'
import Compare from './Compare'
import { usePlayerRoundPoints } from 'modules/multiplayer/leaderboard/hooks'

Chart.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  CategoryScale,
  LinearScale,
  BarElement
)

const pointsWidth = (point) => {
  switch (point) {
    case 0:
      return 0
    case 0.5:
      return 0.1
    case 1:
      return 0.2
    case 1.5:
      return 0.3
    case 2:
      return 0.4
    case 2.5:
      return 0.5
    case 3:
      return 0.6
    case 3.5:
      return 0.7
    case 4:
      return 0.8
    case 4.5:
      return 0.9
    case 5:
      return 1
    default:
      return 0
  }
}

const YouVsExpert = ({ wine, winery, userScore, userPoints, gameId }) => {
  const theme = useTheme()
  const expertColor = '#01545c'
  const [openAboutExpert, setOpenAboutExpert] = useState(false)
  // Multiplayer
  const { roundData } = usePlayerRoundPoints(gameId, wine.id)
  const [compareWExpert, setCompareWExpert] = useState(true)
  const [playerCompared, setPlayerCompared] = useState(null)

  let comparisonObj = [
    { keyName: 'body', playerValue: !compareWExpert && playerCompared.score.body, expertValue: wine.score.body, userValue: userScore.body },
    { keyName: 'ripeness', playerValue: !compareWExpert && playerCompared.score.ripeness, expertValue: wine.score.ripeness, userValue: userScore.ripeness },
    { keyName: 'oak', playerValue: !compareWExpert && playerCompared.score.oak, expertValue: wine.score.oak, userValue: userScore.oak },
    { keyName: 'tannins', playerValue: !compareWExpert && playerCompared.score.tannins, expertValue: wine.score.tannins, userValue: userScore.tannins },
    { keyName: 'alcohol', playerValue: !compareWExpert && playerCompared.score.alcohol, expertValue: wine.score.alcohol, userValue: userScore.alcohol },
    { keyName: 'aromatics', playerValue: !compareWExpert && playerCompared.score.aromatics, expertValue: wine.score.aromatics, userValue: userScore.aromatics },
    { keyName: 'acidity', playerValue: !compareWExpert && playerCompared.score.acidity, expertValue: wine.score.acidity, userValue: userScore.acidity }
  ]

  for (const WScore in wine.score) {
    if (wine.color === 'Red') {
      if (WScore === 'tannins') {
        comparisonObj = comparisonObj.filter(obj => obj.keyName !== 'aromatics')
      }
    } else if (wine.color !== 'Red') {
      if (WScore === 'aromatics') {
        comparisonObj = comparisonObj.filter(obj => obj.keyName !== 'tannins')
      }
    }
  }
  return (
        <Box sx={{ }}>
        <Grid container sx={{ px: 4, mb: 2 }}>
            <Grid item xs={6} sx={{ display: 'grid' }}>
                <Typography fontFamily="KaiseiDecolBold" fontSize={18} sx={{ m: 'auto' }}>You vs. { compareWExpert ? 'the expert' : 'friend'}</Typography>
            </Grid>
            <Grid item xs={6}>
                <Button onClick={() => setOpenAboutExpert(!openAboutExpert)}
                 sx={{ display: 'flex', p: 0, py: 0.2, width: '80%', mx: 'auto', [theme.breakpoints.down('sm')]: { width: '90%' } }}>
                    <Typography fontFamily="DMSansMedium" fontSize={16}>About the expert</Typography>
                </Button>
            </Grid>
        </Grid>

        { openAboutExpert && <AboutExpert open={openAboutExpert} setOpen={setOpenAboutExpert} expertName={winery?.expertName} expertDetails={winery?.expertDetails} /> }

        <Divider sx={{ mt: 2 }} />

        <Grid container sx={{ }}>
            <Grid item xs={6} sx={{ py: 1, borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography fontFamily="KaiseiDecolBold" fontSize={27}>{ matchPercentage(userPoints) }</Typography>
                    <Typography fontFamily="MundialLight" fontSize={16} sx={{ textAlign: 'center', alignSelf: 'end', pb: 0.3 }}>% Match</Typography>
                </Box>
            </Grid>
            <Grid item xs={6}>
                <Box sx={{ py: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography fontFamily="KaiseiDecolBold" fontSize={27}>{userPoints}</Typography>
                    <Typography fontFamily="MundialLight" fontSize={16} sx={{ alignSelf: 'end', pb: 0.3, ml: 1 }}>Points</Typography>
                </Box>
            </Grid>
        </Grid>

        <Divider sx={{ }} />

        <RadarChart comparisonObj={comparisonObj} expertColor={expertColor} />

        <Divider sx={{ }} />

        <Compare expertColor={expertColor}
          players={roundData?.players}
          compareWExpert={compareWExpert}
          playerCompared={playerCompared}
          setCompareWExpert={setCompareWExpert}
          setPlayerCompared={setPlayerCompared}
        />

        <Divider sx={{ }} />

        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', my: 2 }}>
            <Typography fontFamily="KaiseiDecolBold" fontSize={18}>Score breakdown</Typography>
        </Box>

        <Divider sx={{ mb: 2 }} />

        <Grid container sx={{ px: 4 }}>
            {
            comparisonObj.map(({ keyName }, i) => {
              const filteredObj = comparisonObj.filter(obj => obj.keyName === keyName)[0]
              const currentComparison = compareWExpert ? filteredObj?.expertValue : filteredObj?.playerValue
              const keyForDisplay = keyName === 'aromatics' ? 'sweetness' : keyName
              return (
                <Grid container key={i}>

                    <Grid item xs={compareWExpert ? 6 : 8} sx={{ height: 1, pt: i === 0 && 2, pr: 2 }}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography fontFamily="MundialLight" fontSize={16}>{keyForDisplay}</Typography>
                                <Box sx={{ bgcolor: '#ea4928', height: '5px', width: pointsWidth(filteredObj?.userValue), mb: 0.3, borderRadius: '10px' }} />
                                <Box sx={{ bgcolor: expertColor, height: '5px', width: pointsWidth(currentComparison), mb: 1, borderRadius: '10px' }}/>
                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid item xs={2} sx={{ display: 'grid' }}>
                        {
                            i === 0 &&
                            <Typography fontFamily="MundialLight" fontSize={16} sx={{ m: 'auto' }} >You</Typography>
                        }
                        <Typography fontFamily="MundialDemiBold" fontSize={16} sx={{ color: '#ea4928', m: 'auto', pt: 2 }} >{ filteredObj?.userValue }</Typography>
                    </Grid>
                    <Grid item xs={2} sx={{ display: 'grid' }}>
                        {
                            i === 0 &&
                            <Typography fontFamily="MundialLight" fontSize={16} sx={{ m: 'auto' }} >
                              { compareWExpert ? 'Expert' : 'Friend' }
                            </Typography>
                        }
                        <Typography fontFamily="MundialDemiBold" fontSize={16} sx={{ color: expertColor, m: 'auto', pt: 2 }} >
                          { currentComparison }
                        </Typography>

                    </Grid>
                    {
                      compareWExpert
                        ? <Grid item xs={2} sx={{ display: 'grid' }}>
                          {
                              i === 0 &&
                              <Typography fontFamily="MundialLight" fontSize={16} sx={{ m: 'auto' }} >Score</Typography>
                          }
                          <Typography fontFamily="MundialDemiBold" fontSize={16} sx={{ m: 'auto', pt: 2 }} >{ compareWithExpert(filteredObj?.userValue, filteredObj?.expertValue) }</Typography>
                      </Grid>
                        : null
                    }

                </Grid>
              )
            })
        }
        <Box sx={{ bgcolor: '#f0d7c1', height: '0.2px', width: 1, my: 1.5 }} />

        <Grid item xs={6}></Grid>
        <Grid item xs={2} sx={{ minHeight: 30 }}></Grid>
        {
          compareWExpert
            ? <>
            <Grid item xs={2} sx={{ display: 'grid' }}>
                <Typography fontFamily="MundialLight" fontSize={14} sx={{ m: 'auto', ml: 3, color: '#1f1049' }}>Total</Typography>
            </Grid>
            <Grid item xs={2} sx={{ display: 'grid' }}>
                <Typography fontFamily="MundialBold" fontSize={18} sx={{ m: 'auto' }}>{userPoints}</Typography>
            </Grid>
          </>
            : null
        }
        </Grid>

        <Divider sx={{ my: 2 }} />

    </Box>
  )
}

export default YouVsExpert
