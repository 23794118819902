import React from 'react'
import { Typography, Box, Grid } from '@mui/material'

const PlayerTab = ({ nickName, points, position, roundWins, finalBoard }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', px: 3, bgcolor: '#FAF3ED', height: 70, borderBottom: '1px solid #F0D7C1' }} onClick={() => {}}>
      <Grid container>
        <Grid item xs={4}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography fontFamily="Mundial" fontWeight={700} fontSize={18} sx={{ mr: 1 }} >{ position }</Typography>
              <Box sx={{ display: 'flex', minHeight: 30, minWidth: 30, borderRadius: '50%', bgcolor: '#CE8900' }}>
                  <Typography fontFamily="KaiseiDecolBold" fontWeight={700} fontSize={12.5} sx={{ color: 'white', m: 'auto', mt: 0.5 }} >
                    { nickName.charAt(0) }
                  </Typography>
              </Box>
            <Typography fontFamily="MundialBold" fontSize={18} sx={{ ml: 1 }} >
                { nickName }
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={2.5}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Box component="img" src={`${position === 1 ? '/multiplayer/crown.svg' : position === 2 ? '/multiplayer/silverCrown.svg' : position === 3 ? '/multiplayer/bronzeCrown.svg' : ''}`} />
          </Box>
        </Grid>
        <Grid item xs={finalBoard ? 3 : 5.5}>
          <Box sx={{ width: 1, display: 'flex', justifyContent: 'flex-end' }}>
            <Typography fontFamily="Mundial" fontWeight={700} fontSize={18} >{ points }</Typography>
          </Box>
        </Grid>
        {
          finalBoard
            ? <Grid item xs={2.5}>
                <Box sx={{ width: 1, display: 'flex', justifyContent: 'flex-end' }}>
                  <Typography fontFamily="Mundial" fontWeight={700} fontSize={18} color="#999999" >{ roundWins }</Typography>
                </Box>
              </Grid>
            : null
        }
      </Grid>
    </Box>
  )
}

export default PlayerTab
