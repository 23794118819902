import React, { useState } from 'react'
import { Radar, RadarChart, PolarGrid, ResponsiveContainer } from 'recharts'
import RadarCustomLabels from './RadarCustomLabels'
import { Box, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/styles'

export default function Example ({ comparisonObj, expertColor }) {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'))
  comparisonObj = comparisonObj.map(obj => ({ subject: obj.keyName, A: obj.userValue, C: obj.expertValue, B: obj.playerValue || obj.expertValue, fullMark: 150 }))

  const [customWidth, setCustomWidth] = useState(window.innerWidth > 420 ? 400 : 350)
  const innerW = () => {
    setCustomWidth(window.innerWidth > 420 ? 400 : 350)
    return window.innerWidth
  }
  window.addEventListener('resize', innerW)

  const getLabels = () => {
    return comparisonObj?.map(({ subject, A, B, C }, counter) => {
      let top, topMobile, left, leftMobile
      switch (counter) {
        case 0:
          top = -10
          left = 60
          topMobile = -5
          leftMobile = 55
          break
        case 1:
          top = -10
          left = 245
          topMobile = -5
          leftMobile = 215
          break
        case 2:
          top = 162.5
          left = 358
          topMobile = 138
          leftMobile = 295
          break
        case 3:
          top = 337
          left = 245
          topMobile = 280
          leftMobile = 215
          break
        case 4:
          top = 337
          left = 60
          topMobile = 280
          leftMobile = 55
          break
        case 5:
          top = 162.5
          left = -50
          topMobile = 138
          leftMobile = -35
          break
        default:
          break
      }
      return <RadarCustomLabels key={counter} top={top} left={left} topMobile={topMobile} leftMobile={leftMobile} label={subject === 'aromatics' ? 'sweetness' : subject} expertPoints={C} userPoints={A} counter={counter} />
    })
  }

  return (
      <Box sx={{ display: 'flex', overflow: 'hidden' }}>
        <Box sx={{ position: 'relative', transform: 'scale(0.8)', width: customWidth, m: 'auto' }}>
          { getLabels() }
          <ResponsiveContainer width='100%' height={customWidth}>
            <RadarChart cx="50%" cy="50%" outerRadius={isMobile ? '70%' : '80%'} data={comparisonObj} style={{ transform: 'rotate(-30deg)' }}>
              <PolarGrid polarRadius={[0]} />
              {/* <PolarAngleAxis dataKey="subject" tick={<CustomTick />} /> */}
              <Radar name="User" dataKey="A" stroke='#ea4928' strokeWidth={2} fill='transparent' fillOpacity={0.6} />
              <Radar name="Expert" dataKey="B" stroke={expertColor} strokeWidth={2} fill="transparent" fillOpacity={0.6} />
            </RadarChart>
          </ResponsiveContainer>
        </Box>
      </Box>
  )
}
