import React, { useEffect, useState } from 'react'
import useWinesApi from 'firebaseKit/wines'
import { useWineryContext } from 'providers/WineryProvider'
import { usePointsContext } from 'providers/PointsProvider'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import useApi from 'api/apiRequests'
import { useGameMode } from 'providers/GameMode'
import { useCheckIsPlayer } from 'modules/game-room/hooks'

export const useWineriesData = (handleContinue) => {
  const { id } = useParams()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  // Multi
  const { addPlayerExtraPoints } = useApi()
  const { gameData, player, getMultiplayerData, removeGameDataState, setGuessed } = useGameMode()
  const [isMultiplayer] = useState(pathname.includes('game-room'))
  const { checkIsPlayerOrHost } = useCheckIsPlayer()

  const { getWinesByWynery } = useWinesApi()
  const { wineries } = useWineryContext()
  const { getWinePoints } = usePointsContext()

  const [loading, setLoading] = useState(true)
  const [loadingBtn, setLoadingBtn] = useState(false)
  const [winery, setWinery] = useState(null)
  const [wineList, setWineList] = useState(null)

  const [wineExtraPoints, setWineExtraPoints] = useState(null)

  useEffect(() => {
    if (isMultiplayer) checkIsPlayerOrHost() // Check if player is active
    if (isMultiplayer && !gameData) getMultiplayerData().then(data => { if (!data) removeGameDataState(navigate) })
    if (wineries) {
      const winery = wineries.filter((Winery) => Winery.id === id)
      setWinery(winery[0])
      getWinesByWynery(winery[0].id).then((wineList) => {
        setLoading(false)
        setWineList(wineList.sort((a, b) => a.index < b.index ? -1 : 1))
      })
    }
  }, [wineries, winery])

  const calculatePlusExtraPoints = () => {
    const extraPoints = true
    const totalArr = wineList && wineList.map(wine => getWinePoints(wine.name, extraPoints))
    const total = totalArr.reduce((acum, cur) => acum + cur)
    return total
  }

  const handleFinish = async () => {
    try {
      setLoadingBtn(true)
      if (isMultiplayer) {
        let extraPoints = 0
        if (wineExtraPoints) {
          for (const wines in wineExtraPoints) {
            extraPoints += wineExtraPoints[wines]
          }
        }
        const response = (await addPlayerExtraPoints(gameData.id, player.nickname, extraPoints))?.data
        const storageData = JSON.parse(localStorage.getItem('multiplayer'))
        localStorage.setItem('multiplayer', JSON.stringify({ ...storageData, totalPoints: response?.totalPoints || 0 }))
        setGuessed({ guessed: true, wineryName: gameData.winery.name, points: response?.totalPoints || 0 })
        if (handleContinue) {
          handleContinue()
        } else {
          navigate(`/game-room/${gameData.winery.id}`)
        }
      } else {
        const totalPoints = calculatePlusExtraPoints()
        setGuessed({ guessed: true, wineryName: winery.name, points: totalPoints })
        navigate(pathname.replace('guess-price', 'investor-deck'))
      }
      setLoadingBtn(false)
    } catch (error) {
      throw new Error(error)
    }
  }

  return { handleFinish, loading, loadingBtn, wineList, wineExtraPoints, setWineExtraPoints }
}
