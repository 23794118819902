import React, { useState } from 'react'
import { useTheme } from '@mui/styles'
import { Box, Dialog, Slide, Container, IconButton, Typography, Input } from '@mui/material'
import ArrowIcon from '@mui/icons-material/ArrowBackIos'

import Button from 'core/buttons/Button'
import JoinGameModal from './join-game'

import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import Spinner from 'core/Spinner'
import { useNavigate } from 'react-router-dom'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const CodeSchema = Yup.object().shape({
  playerCode: Yup.number()
    .test('len', 'Must be exactly 6 digits', val => val.toString().length === 6)
    // .max(6, 'Must be exactly 6 digits')
    .required('Enter six digit code')
})

const JoinCodeModal = ({ handleClose, getGameRequest }) => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const theme = useTheme()

  const handleConfirmCode = async (code, setErrors) => {
    setLoading(true)
    try {
      const game = await getGameRequest(code)
      if (game) setJoinGameModal(true)
      if (!game) setErrors({ playerCode: 'The code you entered is incorrect' })
    } catch (e) {
      setErrors({ playerCode: e.message })
      setLoading(false)
      throw new Error(e)
    }
    setLoading(false)
  }

  // Pop ups
  const [joinGameModal, setJoinGameModal] = useState(false)

  const handleClick = () => navigate('/game-mode')

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 20 }}>
      <Dialog
        fullScreen={theme.popUpsWidth}
        sx={{
          display: 'flex',
          width: '100vw',
          m: 'auto',

          '.MuiBackdrop-root': {
            width: '100vw',
            m: 'auto'
          },
          '.MuiDialog-container': {
            display: 'flex',
            alignItems: 'flex-end'
          },
          '.MuiPaper-root': {
            width: '100vw',
            bgcolor: 'transparent'
          }
        }}
        open={open}
        // onClose={handleClose}
        TransitionComponent={Transition}
      >
        <Container disableGutters
          sx={{
            '&.MuiContainer-root': {
              width: '100vw',
              maxWidth: '450px',
              minHeight: 450,
              height: 1,
              bgcolor: 'transparent'
            },
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end'
          }}
        >
        <Box sx={{ height: 1, bgcolor: '#FFFDF7', position: 'relative' }}>
            <Formik initialValues={{ playerCode: '' }}
                validationSchema={CodeSchema}
                onSubmit={(values, { setErrors, resetForm }) => {
                  handleConfirmCode(values.playerCode, setErrors)
                }}
            >
            {
                ({ errors, touched, values, handleChange }) => {
                  return (
                    <Form>
                <Box sx={{ width: 1, mb: 0, minHeight: '88px' }}>
                    <IconButton onClick={handleClick} sx={{ m: 2, display: 'flex' }}>
                      {' '}
                      <ArrowIcon sx={{ height: 20, color: 'black' }} />{' '}
                    </IconButton>
                </Box>

                <Box sx={{ mb: 2, mx: 3, minHeight: 110 }}>
                    <Typography fontFamily="KaiseiDecolReg" fontWeight={700} fontSize={26}
                    textAlign="left" >
                      Invite code
                    </Typography>

                  {
                     touched.playerCode && errors?.playerCode
                       ? <Box sx={{ mt: 2 }}>
                          <Box sx={{ display: 'flex', width: 1, alignItems: 'center', justifyContent: 'flex-start' }}>
                            <Box component="img" src="/email/errorIcon.svg" alt="error-icon" sx={{ mr: 1 }} />
                            <Typography fontFamily="MundialLight" fontWeight={300} fontSize={18}
                              textAlign="left" color="#EA4928">{errors.playerCode}</Typography>
                          </Box>
                          <Typography fontFamily="MundialLight" fontWeight={300} fontSize={18}
                          textAlign="left" color="#575757">Please try again</Typography>
                        </Box>
                       : <Typography fontFamily="MundialLight" fontWeight={300} fontSize={18} pt={2}
                       textAlign="left" color="#575757">
                         Add invite code to join this game
                       </Typography>
                  }
                </Box>
                <Box sx={{ display: 'flex', width: 1, height: 120, bgcolor: '#FFFFFF', borderBottom: `1px solid ${errors.playerCode ? '#EA4928' : '#F0D7C1'}`, borderTop: `1px solid ${errors.playerCode ? '#EA4928' : '#F0D7C1'}`, mt: 2, cursor: 'text', [theme.breakpoints.up('sm')]: { px: 10 }, px: 5 }}>
                    <Input autoFocus sx={{
                      input: {
                        fontFamily: 'KaiseiDecolReg',
                        fontWeight: 700,
                        [theme.breakpoints.down('xs')]: { fontSize: 28 },
                        fontSize: 42,
                        textAlign: 'center',
                        color: '#2E2A26',
                        letterSpacing: 20,
                        m: 'auto'
                      },
                      width: 1
                    }}
                    autoComplete="off"
                    name="playerCode"
                    placeholder="------"
                    type="number"
                        value={values.playerCode} onChange={handleChange} disableUnderline />
                </Box>

                <Box sx={{ display: 'grid', p: 3, position: 'absolute', bottom: 3, width: 1 }}>
                  <Button disabled={'playerCode' in errors || loading} type="submit" sx={{ m: 'auto' }}>
                    {
                      loading
                        ? <Spinner />
                        : <Typography fontFamily="DMSansRegular" fontWeight={700} fontSize={18}>
                        Confirm code
                      </Typography>
                    }
                  </Button>
                </Box>

            </Form>
                  )
                }
            }
        </Formik>

        {
            joinGameModal
              ? <JoinGameModal handleClose={handleClose} goBack={() => setJoinGameModal(false)} />
              : null
        }
            </Box>
        </Container>
      </Dialog>
    </Box>
  )
}

export default JoinCodeModal
