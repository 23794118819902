import React, { useState } from 'react'
import { useTheme } from '@mui/styles'
import { Box, Dialog, Slide, Container, IconButton, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import Button from 'core/buttons/Button'
import Spinner from 'core/Spinner'

import { useGameMode } from 'providers/GameMode'
import { useHandleRounds } from '../hooks'
import { useNavigate } from 'react-router-dom'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const StartModal = ({ handleClose }) => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const theme = useTheme()
  const { player, gameData, startGameRequest } = useGameMode()
  const { handleStartRound } = useHandleRounds()

  const handleStartGame = async () => {
    setLoading(true)
    try {
      const response = await startGameRequest(gameData.gameId, player.nickname)
      if (!response?.error && player.isHost) {
        handleStartRound().then(round => {
          // console.log('round---', round)
          if (round) navigate(`/multiplayer/${round.id}/scoring`)
          if (!round) alert('There are players not ready')
          setLoading(false)
        })
      } else if (response?.error) { alert(response.error); setLoading(false) } else handleClose()
    } catch (e) {
      handleClose()
    }
  }
  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', ml: 20 }}>
      <Dialog
        fullScreen={theme.popUpsWidth}
        sx={{
          display: 'flex',
          width: '100vw',
          // maxWidth: '450px',
          m: 'auto',
          boxShadow: 'none',
          '.MuiBackdrop-root': {
            width: '100vw',
            // maxWidth: '450px',
            m: 'auto'
          },
          '.MuiDialog-container': {
            display: 'flex',
            alignItems: 'flex-end'
          },
          '.MuiPaper-root': {
            width: '100vw',
            // maxWidth: '450px',
            bgcolor: 'transparent'
          }
        }}
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <Container disableGutters
          sx={{
            '&.MuiContainer-root': {
              width: '100vw',
              maxWidth: '450px',
              height: 1,
              minHeight: 550,
              bgcolor: 'transparent'
            },
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end'
          }}
        >
          <Box sx={{
            bgcolor: 'primary.main',
            borderTopLeftRadius: '25px',
            borderTopRightRadius: '25px'
          }}>
            <Box sx={{ width: 1, mb: 0 }}>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close" sx={{ mr: 2, mt: 2 }}>
                  <CloseIcon fontSize="medium" />
                </IconButton>
              </Box>
            </Box>

            <Box sx={{
              display: 'grid',
              pb: 4,
              borderBottom: '1px solid #E0E0E0'
            }}>
              {
                player.isHost
                  ? <>
                  <Typography fontFamily="KaiseiDecolReg" fontWeight={700} fontSize={32}
                  sx={{
                    [theme.breakpoints.up('sm')]: { mx: 12 },
                    mx: 10
                  }} textAlign="center" >
                    Are you sure you&apos;re ready to start?
                  </Typography>
                  <Typography fontFamily="MundialRegular" fontWeight={300} fontSize={18}
                        sx={{
                          [theme.breakpoints.up('sm')]: { mx: 7 },
                          mx: 3,
                          pt: 1
                        }} textAlign="center" >
                        Make sure every player is in the lobby before starting the game
                  </Typography>
                </>
                  : <Typography fontFamily="KaiseiDecolReg" fontWeight={700} fontSize={32}
                sx={{
                  [theme.breakpoints.up('sm')]: { mx: 12 },
                  mx: 10
                }} textAlign="center" >
                  Are you sure you&apos;re ready to play?
                </Typography>
              }

            </Box>
            <Box sx={{
              display: 'grid',
              my: 4,
              [theme.breakpoints.up('sm')]: { mx: 4 },
              mx: 2,
              gap: 2
            }}>
                <Button onClick={handleStartGame} disabled={loading}>
                  {
                    loading
                      ? <Spinner />
                      : <Typography fontFamily="DMSansRegular" fontWeight={700} fontSize={18}>
                        Yes, { player?.isHost ? 'I am ready' : "let's go" }
                    </Typography>
                  }
                </Button>

                <Button onClick={handleClose}>
                    <Typography fontFamily="DMSansRegular" fontWeight={700} fontSize={18}>
                    No, { player?.isHost ? 'I need more time' : 'Take me back' }
                    </Typography>
                </Button>
            </Box>
          </Box>
        </Container>
      </Dialog>
    </Box>
  )
}

export default StartModal
