import React from 'react'
import { Box, Grid, Typography } from '@mui/material'

const Compare = ({ expertColor }) => {
  return (
    <Grid container sx={{ position: 'relative' }}>
        <Grid item xs={6} sx={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>
            <Box sx={{ py: 1.5, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Box sx={{ width: 10, height: 10, bgcolor: '#ea4928', borderRadius: '100%' }} />
                <Typography fontFamily="MundialDemiBold" fontSize={18} sx={{ color: '#ea4928', ml: 1 }}>You</Typography>
            </Box>
        </Grid>
        <Grid item xs={6} >
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', m: 'auto', height: 1 }}>
                <Box sx={{ width: 10, height: 10, bgcolor: expertColor, borderRadius: '100%' }} />
                <Typography fontFamily="MundialDemiBold" fontSize={18} sx={{ color: expertColor, ml: 1 }}>
                  Expert
                </Typography>
            </Box>
        </Grid>
    </Grid>
  )
}

export default Compare
