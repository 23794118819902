import { useEffect, useState, useCallback } from 'react'
import useApi from 'api/apiRequests'
import { matchPercentagePhrases } from 'utils'
import { useGameMode } from 'providers/GameMode'

export const usePlayerRoundPoints = (gameId, roundId) => {
  const { getRound } = useApi()
  const [roundData, setRoundData] = useState(null)

  useEffect(() => { getRound(gameId, roundId).then(({ data }) => setRoundData(data)) }, [])

  return { gameRound: roundData?.gameRound, roundData: roundData?.round }
}

export const usePlayerGamePoints = (gameId) => {
  const { getPlayersPoints, getRoundWins } = useApi()
  const { player } = useGameMode()
  const [playerPoints, setPlayerPoints] = useState(null)
  const [roundWins, setRoundWins] = useState([])
  const checkWinner = () => playerPoints && playerPoints.players && playerPoints.players[0]

  const getCurrentPlayerPhrase = () => {
    try {
      const isWinner = player?.nickname === checkWinner()?.name
      const msg1 = isWinner ? `Congratulations ${player?.nickname}! You smashed it!` : `Thanks for playing ${player?.nickname}! You did great!`
      const currentPlayer = playerPoints?.players.find(pl => pl.name === player?.nickname)
      const fixedNum = (currentPlayer?.points / (30 * 5)) * 100
      const msg2 = fixedNum > 75 ? matchPercentagePhrases[0] : fixedNum > 51 ? matchPercentagePhrases[1] : matchPercentagePhrases[2]
      return { msg1, msg2 }
    } catch (e) {
      return { msg1: '', msg2: '' }
    }
  }

  useEffect(() => {
    getPlayersPoints(gameId, player.isHost).then(({ data }) => setPlayerPoints(data))
    getRoundWins(gameId).then(({ data }) => setRoundWins(data))
  }, [])

  return { playerPoints, checkWinner, roundWins, getCurrentPlayerPhrase }
}
