import React from 'react'
import { Box, IconButton } from '@mui/material'
import { useTheme } from '@mui/styles'
import { useLocation, useNavigate } from 'react-router-dom'
import ArrowIcon from '@mui/icons-material/ArrowBackIos'

const WelcomeHeader = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  return (
    <Box sx={{ [theme.breakpoints.up('sm')]: { minHeight: '35vh' }, minHeight: '30vh' }}>
      <Box sx={{ display: 'grid', position: 'relative', width: 1 }}>
        <IconButton sx={{ position: 'absolute', top: 50, display: !pathname.includes('home') && 'none' }} onClick={() => { navigate('/game-mode') }}><ArrowIcon /></IconButton>
        <Box component="img" src="/wineSmash.svg" alt="wine-smash" sx={{ p: 2, width: 150, display: 'flex', mx: 'auto', mb: '2rem' }} />
      </Box>
      <Box component="img" src="/wineSmashTitle.svg" alt="wine-smash" sx={{ width: 1, display: 'flex' }} />
    </Box>
  )
}

export default WelcomeHeader
