import React, { useState } from 'react'
import { useTheme } from '@mui/styles'
import { Box, Dialog, Slide, Container, IconButton, Typography } from '@mui/material'

import Button from 'core/buttons/Button'
import { URL_GAME } from 'utils'
import {
  EmailShareButton,
  WhatsappShareButton,
  TelegramShareButton,
  TelegramIcon,
  WhatsappIcon,
  FacebookShareButton,
  EmailIcon,
  FacebookIcon
} from 'react-share'

const ShareComponent = ({ handleClose, message }) => {
  const theme = useTheme()

  const page = `${URL_GAME}game-room/`

  return (
      <Dialog
        fullScreen={theme.popUpsWidth}
        sx={{
          display: 'flex',
          width: '100vw',
          height: 220,
          bgcolor: 'transparent',
          position: 'sticky',
          bottom: 0,

          '.MuiBackdrop-root': { display: 'none' },
          '.MuiDialog-container': {
            display: 'flex',
            alignItems: 'flex-end'
          },
          '.MuiPaper-root': {
            width: '100vw',
            boxShadow: 'none',
            bgcolor: 'transparent'
          }
        }}
        open={open}
        onClose={handleClose}
      >
        <Container disableGutters
          sx={{
            '&.MuiContainer-root': {
              width: '100vw',
              maxWidth: '450px'
            },
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            overflow: 'hidden'
          }}
        >
            <Box sx={{ height: 220, bgcolor: '#FFFDF7', px: 2 }}>
                <Box sx={{ bgcolor: '#EFEDEC', borderRadius: 2, display: 'flex', flexDirection: 'column' }}>
                    <Box sx={{ display: 'flex', overflowX: 'scroll' }}>
                        <Box sx={{ display: 'grid' }}>
                            <EmailShareButton
                                subject='Join me in the Wine Smash game'
                                body={message}
                                separator=""
                                url=""
                                style={{ margin: 10, marginBottom: 0 }}
                            >
                                <EmailIcon borderRadius={15} size={80} style={{ color: '#18BFFA' }} />
                            </EmailShareButton>
                            <Typography sx={{ m: 'auto', fontSize: 12, fontWeight: 600, mb: 2 }}>Mail</Typography>
                        </Box>
                        <Box sx={{ display: 'grid' }}>
                            <FacebookShareButton style={{ margin: 10, marginBottom: 0 }} quote={message} url={page} hashtag="wineSmash" >
                                <FacebookIcon borderRadius={15} size={80} />
                            </FacebookShareButton>
                            <Typography sx={{ m: 'auto', fontSize: 12, fontWeight: 600, mb: 2 }}>Facebook</Typography>
                        </Box>
                        <Box sx={{ display: 'grid' }}>
                            <WhatsappShareButton style={{ margin: 10, marginBottom: 0 }} url={message} separator="">
                                <WhatsappIcon borderRadius={15} size={80} />
                            </WhatsappShareButton>
                            <Typography sx={{ m: 'auto', fontSize: 12, fontWeight: 600, mb: 2 }}>WhatsApp</Typography>
                        </Box>
                        <Box sx={{ display: 'grid' }}>
                            <TelegramShareButton style={{ margin: 10, marginBottom: 0 }} url={message}>
                                <TelegramIcon borderRadius={15} size={80} />
                            </TelegramShareButton>
                            <Typography sx={{ m: 'auto', fontSize: 12, fontWeight: 600, mb: 2 }}>Telegram</Typography>
                        </Box>
                    </Box>
                    <Button onClick={handleClose} sx={{ display: 'flex', py: 2, borderRadius: 2, border: 'none' }}>
                        <Typography fontWeight={700} fontSize={18} color="#1183FE" m="auto">Cancel</Typography>
                    </Button>
                </Box>
            </Box>
        </Container>
      </Dialog>
  )
}
export default ShareComponent
