import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import { Box, Grid, Typography, IconButton, Rating } from '@mui/material'
import DetailsInfo from 'modules/multiplayer/wine-room/wine-details'
import { useTheme } from '@mui/styles'
import { useMultiPointsHook } from '../Hooks'

const StyledGrid = styled(Grid)(({ theme }) => ({
  width: '100%',
  border: '2px solid gray',
  borderRadius: '15px',
  padding: theme.spacing(1.5),
  color: 'black'
}))
const PaintedBox = styled(Box)((props) => ({
  width: '50px',
  position: 'absolute',
  background: props.wineColor,
  left: -1,
  top: 15,
  bottom: props.points ? 45 : 15,
  borderTopRightRadius: '15px',
  borderBottomRightRadius: '15px'
}))
const ImageBox = styled(Box)((props) => ({
  position: 'absolute',
  width: 35,
  bottom: props.points ? 35 : 5,
  left: 10
}))

const WineButton = ({ wine, showDetails, isFullyScored, scoredPlusExtraPoints }) => {
  const theme = useTheme()
  const { points, userScore, isRated, loading } = useMultiPointsHook(wine)

  const matchPercentage = () => {
    const fixedNum = (points / 30) * 100
    return fixedNum.toFixed()
  }
  // console.log('wine ---', loading)
  // Details info pop up
  const [open, setOpen] = useState(!!showDetails)

  return (
    <Box sx={{ my: 2.2 }}>
      {
        !loading
          ? <StyledGrid container sx={{ position: 'relative', p: 0, pb: '37px', minHeight: 120, maxHeight: 160 }}>
        <Grid item xs={2} sx={{ display: 'flex', m: 'auto' }}>
          <PaintedBox points={points ? 1 : undefined} wineColor={wine.backgroundColor} />
        </Grid>

        <ImageBox component="img" src={wine.image} alt={wine.name} points={points || undefined} />

        <Grid item xs={10} sx={{ [theme.breakpoints.down('sm')]: { pl: 1 } }}>
          <Grid container sx={{ pt: 2, mb: points ? 2 : -0.5 }}>
            <Grid item xs={3} sx={{ display: 'flex' }}>
              <Typography component="p" variant="subtitle" fontFamily="DMSansRegular" fontSize={12} sx={{ color: '#957d70', my: 'auto' }}>
                Wine {wine.index}
              </Typography>
            </Grid>
            <Grid item xs={9} sx={{ my: 'auto', position: 'relative' }}>
              {isRated != null && (
                <Box sx={{ position: 'absolute', display: 'flex', alignContent: 'center', bottom: -13 }}>
                  <Rating sx={{ transform: 'scale(0.7)', ml: -3 }} name="rating" readOnly value={isRated} precision={0.5} size="large" />
                </Box>
              )}
            </Grid>

            <Grid item xs={12}>
              <Typography component="h1" fontFamily="KaiseiDecolBold" fontSize={18} sx={{ color: 'rgb(46,42,38)' }}>
                {wine.name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="subtitle" fontFamily="DMSansRegular" fontSize={14} sx={{ zIndex: 10, color: 'rgb(46,42,38)' }}>
                  {wine.grape} &#183; {wine.year}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          {!points && (
            <Box sx={{ position: 'absolute', bottom: -2, right: -2, width: 100 }}>
              <IconButton sx={{ width: '100%', bgcolor: '#2E2A26', borderRadius: 0, borderBottomRightRadius: '15px', borderTopLeftRadius: '15px', ':hover': { bgcolor: '#2E2A26' } }} onClick={() => setOpen(!open)}>
                <Typography component="p" variant="p" fontFamily="DMSansMedium" color="white" fontSize={16} sx={{ ':hover': { opacity: 0.7 } }}>
                  Details
                </Typography>
              </IconButton>
            </Box>
          )}
        </Grid>
        {points
          ? (
            <Grid container sx={{ position: 'absolute', bottom: 0, borderTop: '1px solid gray', height: 35 }}>
            <Grid item xs={4} sx={{ display: 'flex', py: 'auto', [theme.breakpoints.up('sm')]: { pl: 2 }, pl: 0.5, borderRight: '1px solid gray' }}>
              <Typography fontFamily="KaiseiDecolBold" sx={{ [theme.breakpoints.up('sm')]: { fontSize: 20 }, fontSize: 16, m: 'auto', mr: 0 }}>
                {matchPercentage()}%{' '}
              </Typography>
              <Typography fontFamily={'MundialLight'} fontSize={16} sx={{ [theme.breakpoints.up('sm')]: { ml: 1, mt: 0.7, fontSize: 16 }, fontSize: 14, m: 'auto', ml: 0.5, mt: 1 }}>
                Match
              </Typography>
            </Grid>
            <Grid item xs={5} sx={{ display: 'flex', py: 'auto', [theme.breakpoints.up('sm')]: { ml: 3 }, ml: 2, mb: 0 }}>
              <Typography fontFamily="KaiseiDecolBold" sx={{ [theme.breakpoints.up('sm')]: { fontSize: 20 }, fontSize: 16, my: 'auto' }}>
                {points}
              </Typography>
              <Typography fontFamily={'MundialLight'} sx={{ [theme.breakpoints.up('sm')]: { fontSize: 16, ml: 1, mt: 0.7 }, fontSize: 14, ml: 0.5, my: 'auto', mt: 1 }}>
                Points
              </Typography>
            </Grid>
            <Box sx={{ position: 'absolute', bottom: -2, right: -2, width: 100, height: 37 }}>
              <IconButton sx={{ width: '100%', bgcolor: '#2E2A26', borderRadius: 0, borderBottomRightRadius: '15px', borderTopLeftRadius: '15px', ':hover': { bgcolor: '#2E2A26' } }} onClick={() => setOpen(!open)}>
                <Typography component="p" variant="p" fontFamily="DMSansMedium" color="white" fontSize={16} sx={{ ':hover': { opacity: 0.7 } }}>
                  Details
                </Typography>
              </IconButton>
            </Box>
          </Grid>
            )
          : null }
        {wine && open && <DetailsInfo open={open} setOpen={setOpen} wine={wine} userScore={userScore} isRated={isRated} points={points} wineryFullyScored={isFullyScored} scoredPlusExtraPoints={scoredPlusExtraPoints} />}
      </StyledGrid>
          : null
        }
    </Box>
  )
}

export default WineButton
