import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'

import Button from 'core/buttons/Button'
import ShareCodeModal from './ShareCode'
import { useNavigate } from 'react-router-dom'

const GameInvite = ({ gameData }) => {
  const navigate = useNavigate()
  const [gameInvite, setGameInvite] = useState(false)
  const handleClose = () => setGameInvite(false)

  return (
    <Box sx={{ borderBottom: '1px solid #F0D7C1' }}>
      <Box sx={{ display: 'flex', gap: 3 }}>
        <Button onClick={() => navigate(`/game-room/${gameData?.winery.id}`)}
        sx={{ my: 2, ml: 2, py: 1.5 }}><Typography fontFamily="DMSansRegular" fontWeight={700} fontSize={18}>View wines</Typography></Button>
        <Button onClick={() => setGameInvite(true)}
        sx={{ my: 2, mr: 2, py: 1.5 }}><Typography fontFamily="DMSansRegular" fontWeight={700} fontSize={18}>Invite friends</Typography></Button>
      </Box>

      {
        gameInvite
          ? <ShareCodeModal handleClose={handleClose} />
          : null
      }
    </Box>
  )
}

export default GameInvite
