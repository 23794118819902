import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Box, Grid, Typography, IconButton } from '@mui/material'

import Button from 'core/buttons/Button'
import DeleteModal from './DeleteModal'
import StartModal from './StartModal'
import { useGameMode } from 'providers/GameMode'
import { useHandleRounds } from '../hooks'
import Spinner from 'core/Spinner'

const BottomButtons = () => {
  const navigate = useNavigate()
  const [deleteModal, setDeleteModal] = useState(false)
  const [startModal, setStartModal] = useState(false)
  const handleOpenDeleteModal = () => setDeleteModal(true)
  const handleCloseDeleteModal = () => setDeleteModal(false)
  const handleOpenStartModal = () => setStartModal(true)
  const handleCloseStartModal = () => setStartModal(false)

  const { player, gameData, currentRound } = useGameMode()
  const { loading, loadingModalBtn, handleNextRound, handleDeleteGame, handleRemovePlayer, handleFinish } = useHandleRounds()

  useEffect(() => {}, [currentRound])

  const deletePhrase = `Are you sure you want to ${player?.isHost ? 'delete' : 'exit'} this game?`
  const confirmPhrase = `Yes, ${player?.isHost ? 'delete' : 'exit'} game`

  return (
      <Box sx={{ position: 'absolute', bottom: 0, px: 3, my: 3, width: 1 }}>
          <Grid container>
              <Grid item xs={3} sx={{ display: 'flex' }}>
                  <IconButton onClick={handleOpenDeleteModal}
                    sx={{ m: 'auto', height: 54, width: 54, border: '1px solid #2E2A26' }}><Box component="img" src="/trash-can.svg" /></IconButton>
              </Grid>
              <Grid item xs={9}>
                {
                  currentRound?.started && !currentRound?.finished
                    ? <Button disabled={loading} onClick={() => navigate(`/multiplayer/${currentRound.id}/scoring`)} sx={{ border: '1px solid #2E2A26', py: 1.5 }}>
                      {
                        loading
                          ? <Spinner />
                          : <Typography fontFamily="DMSansRegular" fontWeight={700} fontSize={18}>
                          Go to round
                        </Typography>
                        }
                      </Button>
                    : gameData?.round === 6
                      ? <Button disabled={loading} onClick={handleFinish} sx={{ border: '1px solid #2E2A26', py: 1.5 }}>
                        {
                          loading
                            ? <Spinner />
                            : <Typography fontFamily="DMSansRegular" fontWeight={700} fontSize={18}>
                          Finish Game
                        </Typography>
                        }
                      </Button>
                      : gameData?.round > 1
                        ? <Button disabled={loading} onClick={handleNextRound} sx={{ border: '1px solid #2E2A26', py: 1.5 }}>
                        {
                          loading
                            ? <Spinner />
                            : <Typography fontFamily="DMSansRegular" fontWeight={700} fontSize={18}>
                          Next round
                        </Typography>
                        }
                      </Button>
                        : <Button disabled={loading} onClick={handleOpenStartModal} sx={{ border: '1px solid #2E2A26', py: 1.5 }}>
                        {
                          loading
                            ? <Spinner />
                            : <Typography fontFamily="DMSansRegular" fontWeight={700} fontSize={18}>
                          Start Game
                        </Typography>
                        }
                      </Button>
                }
              </Grid>
          </Grid>

        {
            deleteModal
              ? <DeleteModal handleClick={player?.isHost ? handleDeleteGame : handleRemovePlayer} handleClose={handleCloseDeleteModal} deletePhrase={deletePhrase} confirmPhrase={confirmPhrase} loading={loadingModalBtn} />
              : null
        }
        {
            startModal
              ? <StartModal handleClose={handleCloseStartModal} />
              : null
        }
      </Box>
  )
}

export default BottomButtons
