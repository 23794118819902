import React, { useState } from 'react'
import { Typography, Box, IconButton, Grid } from '@mui/material'
import RemovePlayerModal from './RemovePlayerModal'
import { useGameMode } from 'providers/GameMode'

const PlayerTab = ({ tabPlayer, playerColor, isHost }) => {
  const [removePlayerModal, setRemovePlayerModal] = useState(false)
  const handleOpen = () => setRemovePlayerModal(true)
  const handleClose = () => setRemovePlayerModal(false)
  const { player } = useGameMode()
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', px: 3, bgcolor: '#FAF3ED', height: 83, borderBottom: '1px solid #F0D7C1' }} onClick={() => {}}>
      <Grid container>
        <Grid item xs={2}>
          <Box sx={{ display: 'flex', height: 55, width: 55, borderRadius: '50%', bgcolor: playerColor }}>
              <Typography fontFamily="KaiseiDecolBold" fontSize={25} sx={{ color: 'white', m: 'auto' }} >
                { tabPlayer?.charAt(0) }
              </Typography>
          </Box>
        </Grid>
        <Grid item xs={8} sx={{ display: 'grid' }}>
          <Typography fontFamily="KaiseiDecolBold" fontSize={14} sx={{ ml: 3, my: 'auto' }} >
              { tabPlayer } { isHost ? '(host)' : null }
          </Typography>
        </Grid>

        {
          player?.isHost && player.nickname !== tabPlayer
            ? <Grid item xs={2} sx={{ display: 'flex' }}>
            <IconButton onClick={handleOpen}
              sx={{ m: 'auto', height: 36, width: 36, border: '1px solid #2E2A26' }}><Box sx={{ width: 1 }} component="img" src="/trash-can.svg" /></IconButton>
          </Grid>
            : null
        }
        {
            removePlayerModal
              ? <RemovePlayerModal handleClose={handleClose} open={removePlayerModal} playerToRemove={tabPlayer} />
              : null
        }
      </Grid>
    </Box>
  )
}

export default PlayerTab
