import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Dialog, Slide, Typography } from '@mui/material'
import { useTheme } from '@mui/styles'
import Button from 'core/buttons/Button'
import { useWineContext } from 'providers/WinesProvider'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export const matchPercentage = (points) => {
  const fixedNum = (points / 30) * 100
  return fixedNum.toFixed()
}
const MatchResults = ({ open, setOpen, wine, points }) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { setIsWineScored } = useWineContext()
  const handleClose = () => {
    navigate(`/wine-room/${wine.wineryId}`)
    setOpen(!open)
  }

  const largeNum = points.toString().length >= 3

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', m: 'auto', height: '100%' }}>
      <Dialog
        fullScreen={theme.popUpsWidth}
        sx={{
          display: 'flex',
          width: '100vw',
          maxWidth: '450px',
          m: 'auto',

          '.MuiBackdrop-root': {
            width: '100vw',
            maxWidth: '450px',
            m: 'auto'
          },
          '.MuiDialog-container': {
            display: 'flex',
            alignItems: 'flex-end'
          },
          '.MuiPaper-root': {
            width: '100vw',
            maxWidth: '450px',
            bgcolor: '#fffdf7'

          },
          '.MuiDialog-paper::-webkit-scrollbar': {
            display: 'none'
          }
        }}
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >

        <Box component="img" src="/background.svg" alt="" sx={{ position: 'absolute', width: '100%', height: 1, minHeight: '750px', overflowY: 'hidden' }} />
        <Box sx={{
          zIndex: 10,
          height: 1,
          minHeight: '750px',
          position: 'relative',
          overFlowY: 'hidden',
          background: 'linear-gradient(to bottom, rgba(255,253,247,0), rgb(250,243,237))'
        }}>

        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', pb: 10, height: '80%', minHeight: '600px' }}>
            <Typography fontFamily="KaiseiDecolBold" fontSize={18} sx={{ my: 10, mx: 4, textAlign: 'center' }}>{wine.name}</Typography>
            <Box sx={{ height: '100%', width: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Box sx={{
                  position: 'relative',
                  bgcolor: wine.backgroundColor,
                  [theme.breakpoints.up('sm')]: { mx: 8, mt: 5, height: 250, width: 250 },
                  height: 180,
                  width: 180,
                  borderRadius: '100%'
                }}>
                    <Box sx={{
                      position: 'absolute',
                      [theme.breakpoints.up('sm')]: { width: 100, left: 75, top: -85 },
                      width: 75,
                      left: 50,
                      top: -70
                    }}>
                        <Box component="img" src={wine.image} alt="wine" sx={{
                          [theme.breakpoints.up('sm')]: { left: 0 },
                          position: 'absolute',
                          width: 1,
                          left: 4
                        }} />

                        <Box item xs={4} sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          position: 'relative',
                          width: '110%',
                          [theme.breakpoints.up('sm')]: { top: 270, left: -4 },
                          top: 185
                        }}>
                            <Box sx={{
                              position: 'absolute',
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              justifyContent: 'center'
                            }}>
                                <Typography fontFamily="KaiseiDecolBold" color="white" sx={{
                                  [theme.breakpoints.up('sm')]: {
                                    fontSize: largeNum ? 44 : 60,
                                    mt: points.toString().length <= 2 ? 0 : largeNum ? 2 : 1
                                  },
                                  fontSize: largeNum ? 38 : 44,
                                  m: 'auto',
                                  mt: points.toString().length <= 2 ? 0 : largeNum ? 1 : 1,
                                  mb: largeNum ? -1 : -2
                                }} >{points}</Typography>
                                <Typography fontFamily="MundialBold" color="white" fontSize={18} sx={{ m: 'auto' }} >Points</Typography>
                            </Box>
                            <Box component="img" src="/emptyStar.svg" alt="points" sx={{ width: 1, m: 'auto' }} />
                        </Box>
                    </Box>
                </Box>
            </Box>
                <Typography sx={{ [theme.breakpoints.up('sm')]: { mx: 8, mt: 10 }, mt: 5, textAlign: 'center' }} fontFamily="KaiseiDecolBold" fontSize={30}>{ matchPercentage(points) }% match with the expert’s review! </Typography>
        </Box>

            <Box sx={{ p: 4, bottom: 0, width: 1, height: '20%', display: 'flex' }}>
                <Button onClick={() => {
                  setIsWineScored({ name: wine.name })
                  navigate(`/wine-room/${wine.wineryId}`)
                  setOpen(!open)
                }} sx={{ alignSelf: 'flex-end' }} >
                    <Typography fontFamily="DMSansBold" fontSize={18}>
                        See Results
                    </Typography>
                </Button>
            </Box>
        </Box>
      </Dialog>
    </Box>
  )
}

export default MatchResults
