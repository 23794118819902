import React from 'react'
import { Box, Grid, Typography, IconButton } from '@mui/material'
import { styled } from '@mui/material/styles'
import ArrowIcon from '@mui/icons-material/ArrowForwardIos'
import { useTheme } from '@mui/styles'

const StyledGrid = styled(Grid)(({ theme, styleProps }) => {
  return ({
    width: '100%',
    border: '1px solid black',
    borderRadius: '15px',
    padding: theme.spacing(1.5),
    color: 'black',
    ...styleProps
  })
})

const chosenWinery = {
  border: '1px solid #CE8900',
  background: '#FAF3ED',
  color: '#CE8900'
}

const WineryButton = ({ winery, multiplayer, handleClick, isWineryChosen }) => {
  const theme = useTheme()

  return (
    <Box sx={{ my: 1.5 }}>
        <StyledGrid container styleProps={isWineryChosen && chosenWinery}
            sx={{ p: 0, cursor: 'pointer', minHeight: 82 }}
            onClick={handleClick}>

            <Grid item xs={3} sx={{ borderRight: 1, borderTopLeftRadius: '15px', borderBottomLeftRadius: '15px', bgcolor: 'white' }}>
                <Box sx={{ height: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Box component="img" src={winery.logo} alt={winery.name} sx={{ display: 'flex', borderTopLeftRadius: '15px', borderBottomLeftRadius: '15px', objectFit: 'contain', width: 1, height: 60 }} />
                </Box>
            </Grid>

            <Grid item xs={9} sx={{ p: 2 }}>
                <Grid container sx={{ height: 1 }} >
                    <Grid item xs={10} >
                        <Grid container sx={{ height: 1 }}>
                            <Grid item xs={12}>
                                <Typography component="p" variant="subtitle" fontFamily="DMSansRegular" fontSize={12}>
                                    Game {winery.index + 1}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography style={theme.buttonTitle} component="h1" fontSize={20} sx={{ color: 'rgb(46,42,38' }}> {winery.name}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={2} sx={{ m: 'auto' }}>
                        {
                            !multiplayer
                              ? <IconButton > <ArrowIcon sx={{ transform: 'scale(0.7)' }} /> </IconButton>
                              : isWineryChosen
                                ? <Box component="img" src="/goldenTick.svg" />
                                : null
                        }
                    </Grid>
                </Grid>
            </Grid>
        </StyledGrid>
    </Box>
  )
}

export default WineryButton
