import React from 'react'
import { Box, Typography } from '@mui/material'
import { useTheme } from '@mui/styles'

const HowToTest = ({ wine }) => {
  const theme = useTheme()

  return (
    <Box sx={{ mx: 4 }}>

        <Typography style={theme.title} component="h1" variant="h5" fontSize={32} sx={{ mb: 2 }}>
            Rating the <br /> wines is easy!
        </Typography>
        <Typography fontFamily='MundialLight' fontSize={18} sx={{ color: '#575757' }} component="p" variant="p" paragraph>
            Compare your scores against your friends or against our Wine Expert.
            <br /> <br />
            We’ve broken the tasting experience down into six different criteria for each wine (we call them <strong>the Pillars of Taste</strong>). Score each Pillar out of 5 to describe the wine (1 = a little, 5 = a lot).
            <br /> <br />
            For example, for <strong>Acidity:</strong> a very soft wine with little acidity would score 1. A very sharp or acidic wine that really makes your mouth water would score 5.
            <br /> <br />
            Use the sliding scale to score each of the six Pillars between 1 and 5.
        </Typography>
        <Box component="img" src={`${!wine ? '/sixBottlesRed.png' : wine.color === 'Red' ? '/sixBottlesRed.png' : '/sixBottlesNotRed.png'}`} alt="six-bottles" sx={{ width: 1, px: 4 }} />

    </Box>
  )
}

export default HowToTest
