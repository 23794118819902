import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import { Box, Typography, Slide, Dialog, Container, Grid } from '@mui/material'
import { useTheme } from '@mui/styles'

import PlayerTab from '../PlayerTab'
import Button from 'core/buttons/Button'
import ShareComponent from 'modules/game-room/game-invite/ShareComponent'
import Spinner from 'core/Spinner'

import { usePlayerGamePoints } from '../hooks'
import { useGameMode } from 'providers/GameMode'
import { URL_GAME } from 'utils'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})
export const FinalLeaderBoard = ({ open, setOpen }) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { gameData, getPoints, player, guessed, setGuessed } = useGameMode()
  const { playerPoints, checkWinner, roundWins, getCurrentPlayerPhrase } = usePlayerGamePoints(gameData?.id)
  const [shareResults, setShareResults] = useState(false)
  const [loading, setLoading] = useState(false)
  const [sharePoints, setSharePoints] = useState(null)

  useEffect(() => {
    const points = playerPoints?.players?.find(pl => pl.name === player.nickname).points
    if (points) setSharePoints(points)
  }, [playerPoints])
  const handleClose = () => { setOpen(false) }

  const { msg1, msg2 } = getCurrentPlayerPhrase()
  const playerMessage = player?.nickname === checkWinner()?.name
    ? `I just won a game of Wine Smash!
    My Final Score: ${sharePoints} 
    
    See if you can beat me. Play Wine Smash Now! 
  
    ${URL_GAME}game-mode`
    : `I just played a game of Wine Smash with my friends.

    See if you can beat me. Play Wine Smash Now! 

    ${URL_GAME}game-mode`

  const handleMobile = () => {
    if (navigator.canShare) {
      if (navigator.share) {
        navigator.share({
          title: 'wine smash game',
          text: playerMessage,
          url: `${URL_GAME}game-room/`
        })
      }
    }
  }
  const handleFinish = async () => {
    setLoading(true)
    navigate(`${pathname}/investor-deck`)
    setLoading(false)
  }

  return (
    <Box style={theme.background} sx={{ height: 1, display: 'flex', flexDirection: 'column' }}>
    <Dialog
    fullScreen={theme.popUpsWidth}
    sx={{
      display: 'flex',
      width: '100vw',
      m: 'auto',

      '.MuiBackdrop-root': {
        width: '100vw',
        m: 'auto'
      },
      '.MuiDialog-container': {
        display: 'flex',
        alignItems: 'flex-end'
      },
      '.MuiPaper-root': {
        width: '100vw',
        bgcolor: 'transparent'
      }
    }}
    open={open}
    onClose={handleClose}
    TransitionComponent={Transition}
    >
        <Container disableGutters sx={{ '&.MuiContainer-root': { width: '100vw', minHeight: 1, maxWidth: '450px', bgcolor: '#FAF3ED' } }}>
            <Box sx={{ minHeight: 500, width: 1, position: 'relative', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
                <Box sx={{ height: 0.8, position: 'absolute', zIndex: 10, width: 1, overflow: 'hidden' }}>
                    <Box component="img" src="/background.svg" alt="background" sx={{ position: 'absolute', zIndex: 10, top: -130, height: '200%', width: 1 }} />
                    <Box sx={{ position: 'absolute', height: 0.3, bottom: 0, width: 1, zIndex: 20, background: 'linear-gradient(to bottom, rgba(255,253,247,0), rgb(250,243,237))' }}></Box>
                </Box>
                <Box sx={{ position: 'absolute', height: 0.2, bottom: 0, width: 1, zIndex: 20, background: 'linear-gradient(to top, rgba(255,253,247,0), rgb(250,243,237))' }}></Box>
                <Container sx={{ zIndex: 20, display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', p: 4, px: 2, background: 'linear-gradient(to bottom, rgba(255,253,247,0), rgb(250,243,237))' }}>
                    <Typography fontFamily="MundialBold" fontWeight={600} fontSize={16} color="#2E2A26" >Final Leaderboard</Typography>
                    <Typography fontFamily="KaiseiDecolBold" fontWeight={700} fontSize={32} color="#2E2A26">{ checkWinner()?.name } won!</Typography>
                    <Box component="img" src="/multiplayer/trophy.svg" alt="trophy" mt={2} mb={3} />
                    <Typography fontFamily="MundialBold" fontWeight={700} fontSize={24} color="#2E2A26">
                        { msg1 }
                    </Typography>
                    <Typography fontFamily="DMSansMedium" fontWeight={400} fontSize={16} mt={2} color="#2E2A26">
                        { msg2 }
                    </Typography>
                </Container>
            </Box>
            <Box sx={{ borderBottom: '1.5px solid #F0D7C1', width: 1 }}></Box>

            <Container sx={{ bgcolor: '#FAF3ED', textAlign: 'right' }}>
                <Grid container px={3} >
                    <Grid item xs={4}></Grid>
                    <Grid item xs={2.5}></Grid>
                    <Grid item xs={3}>
                      <Typography fontFamily="MundialLight" fontWeight={300} fontSize={16} sx={{ lineHeight: 1, pt: 2 }} >
                          Final <br /> points
                      </Typography>
                    </Grid>
                    <Grid item xs={2.5}>
                      <Typography fontFamily="MundialLight" fontWeight={300} fontSize={16} sx={{ lineHeight: 1, pt: 2 }} >
                          Round <br /> wins
                      </Typography>
                    </Grid>
                </Grid>
                {
                    playerPoints?.players && roundWins?.length
                      ? playerPoints?.players.map((player) => <PlayerTab key={player.name} nickName={player.name} points={player.points} position={player.position} roundWins={roundWins.find(P => P.name === player.name)?.wins || 0} finalBoard />)
                      : null
                }
            </Container>
            <Box sx={{ [theme.breakpoints.up('sm')]: { px: 4 }, px: 2, bgcolor: '#FAF3ED' }}>
              <Button onClick={() => isMobile ? handleMobile() : setShareResults(true)} sx={{ my: 2 }}>
                  <Typography fontFamily="DMSansBold" fontWeight={700} fontSize={18} sx={{ color: '#2E2A26' }} >
                      Share your results
                  </Typography>
              </Button>
              <Button disabled={loading} onClick={handleFinish} sx={{ mb: 2 }}>
                {
                  loading
                    ? <Spinner />
                    : <>
                    <Typography fontFamily="DMSansBold" fontWeight={700} fontSize={18} sx={{ color: '#2E2A26' }}>
                        Finish game
                      </Typography>
                      <Box component="img" src="/buttonLongArrow.svg" alt="long-arrow" sx={{ position: 'absolute', right: 20 }} />
                    </>
                }
              </Button>
            </Box>
        {
            shareResults
              ? <ShareComponent handleClose={() => setShareResults(false)} message={playerMessage}/>
              : null
        }
        </Container>
    </Dialog>
    </Box>
  )
}

export default FinalLeaderBoard
