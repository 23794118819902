import React from 'react'
import { Box, Dialog, Slide, Container, IconButton } from '@mui/material'
import { useTheme } from '@mui/styles'
import ScoringIsEasy from './ScoringIsEasy'
import Steps from './Steps'
import Button from 'core/buttons/Button'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const HowToPlay = ({ open, handleClose, wineToScore, handleClick }) => {
  const theme = useTheme()

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', m: 'auto' }}>
     <Dialog
        fullScreen={theme.popUpsWidth}
        sx={{
          display: 'flex',
          width: '100vw',
          // maxWidth: '450px',
          m: 'auto',

          '.MuiBackdrop-root': {
            width: '100vw',
            // maxWidth: '450px',
            m: 'auto'
          },
          '.MuiDialog-container': {
            display: 'flex',
            alignItems: 'flex-end'
          },
          '.MuiPaper-root': {
            width: '100vw',
            // maxWidth: '450px',
            bgcolor: 'transparent'
          }
          // '.MuiDialog-paper': { ml:-5 },
        }}
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <Container disableGutters sx={{ '&.MuiContainer-root': { width: '100vw', maxWidth: '450px', bgcolor: 'primary.main' } }}>
        <Box sx={{ mb: wineToScore ? 10 : 4, mt: !wineToScore && 2 }}>
          {
            !wineToScore
              ? <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
              sx={{ mr: 2 }}
              >
              <Box component="img" src="/closeIcon.svg" alt="close" sx={{ }} />
            </IconButton>
          </Box>
              : null
          }
        </Box>
        <ScoringIsEasy wine={wineToScore} />
        <Steps theme={theme} />

        {
          wineToScore
            ? <Box sx={{ m: 4 }}>
            <Button onClick={handleClick} style={theme.buttonTitle}>Got it, let’s play!</Button>
          </Box>
            : null
        }

        </Container>
      </Dialog>
    </Box>
  )
}

export default HowToPlay
