import { useEffect, useState, useCallback } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import useWinesApi from 'firebaseKit/wines'
import useApi from 'api/apiRequests'
import { useGameMode } from 'providers/GameMode'
import { useCheckIsPlayer } from 'modules/game-room/hooks'
import { usePointsContext } from 'providers/PointsProvider'

const useWineHook = () => {
  const [wineToScore, setWineToScore] = useState(null)
  const { getWine } = useWinesApi()
  const { id } = useParams()

  useEffect(() => {
    const getIt = async () => {
      const wine = await getWine(id)
      setWineToScore(wine)
    }
    getIt()
  }, [])
  return { wineToScore }
}

const getDifference = (a, b) => {
  return Math.abs(a - b)
}
const compareWithExpert = (wineToScore, score) => {
  if (wineToScore && 'score' in wineToScore) {
    const expertScore = wineToScore.score
    let pointsAccum = 0
    const returnPoints = (difference) => {
      switch (difference) {
        case 0:
          pointsAccum += 5
          break
        case 0.5:
          pointsAccum += 4
          break
        case 1:
          pointsAccum += 2.5
          break
        case 1.5:
          pointsAccum += 2
          break
        default:
          pointsAccum += 0
          break
      }
    }
    for (const param in score) {
      const difference = getDifference(score[param], expertScore[param])
      returnPoints(difference)
    }
    return pointsAccum
  }
}

const checkPlayerFinished = (round, player) => {
  return round.find(pl => pl.nickName === player.nickname)?.finished
}

const useSubmitScoring = (wineToScore) => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [loadingScreen, setLoadingScreen] = useState(true)

  const { player, gameData, currentRound, getMultiplayerData, removeGameDataState } = useGameMode()
  const { addPlayerPoints } = useApi()
  const { checkIsPlayerOrHost } = useCheckIsPlayer()
  // Rate
  const [score, setScoring] = useState({})
  const [loadingPoints, setLoadingPoints] = useState(false)
  const [points, setPoints] = useState(0)
  const [notes, setNotes] = useState(null)

  const [openResults, setOpenResults] = useState(false)
  const [waitScreen, setWaitScreen] = useState(false)
  const [openHow, setOpenHow] = useState(true)
  const closeHowToPlay = () => { setOpenHow(!openHow) }

  const { setWineNotes, setWineScore, setWinePoints, setWineRating } = usePointsContext()

  useEffect(() => {
    checkIsPlayerOrHost() // Check if player is active
    if (!gameData) {
      getMultiplayerData().then(data => {
        if (player.nickname && !data) navigate('/game-mode')
        if (!data) removeGameDataState(navigate)
      })
    }
    if (currentRound?.players?.length) {
      const playerPoints = currentRound.players?.filter(pl => pl.nickName === player.nickname)
      setPoints(playerPoints?.[0]?.points)
    }
    if (!currentRound.loading && currentRound?.id && (currentRound.id !== id || currentRound.round !== gameData.round)) navigate(`/game-room/${gameData?.winery.id}`)
    if (currentRound?.round === gameData?.round && currentRound?.players && !openResults) {
      const boolean = checkPlayerFinished(currentRound?.players, player)
      // console.log('response boolean', boolean, player)
      if (boolean) { setWaitScreen(true) }
      if (currentRound.showResults) { setOpenResults(true) }
    }
    setInterval(() => setLoadingScreen(false), 3000)
  }, [currentRound, gameData])

  const submitScoring = async (rate) => {
    setLoadingPoints(true)
    const points = compareWithExpert(wineToScore, score)
    setPoints(points)

    const playerWithScoring = { isReady: true, nickName: player.nickname, score, rate, points, notes }

    const response = (await addPlayerPoints(gameData.id, currentRound.id, playerWithScoring))?.data

    // console.log('RESPONSE adding points', response)

    // Also save to localStorage so that ratings can be extracted
    // later in EmailTemplate
    setWineScore(wineToScore.name, score)
    setWineRating(wineToScore.name, rate)
    setWinePoints(wineToScore.name, points)
    setWineNotes(wineToScore.name, notes)

    setTimeout(() => {
      setLoadingPoints(false)
      // navigate to wait other players
      if ('error' in response) {
        alert("There's been an error. Please try again")
        navigate(`/game-room/${gameData?.winery.id}`)
      } else setWaitScreen(true)
      // Next view
    }, [1000])
  }
  return {
    closeHowToPlay,
    loadingPoints,
    loadingScreen,
    openHow,
    openResults,
    points,
    score,
    setNotes,
    setOpenResults,
    setScoring,
    setWaitScreen,
    submitScoring,
    waitScreen
  }
}

const finishRoundRequest = async (gameId, roundId, host) => {
  const { finishRound } = useApi()
  return (await finishRound(gameId, roundId, host))?.data
}

const showResultsRequest = async (gameId, roundId, host) => {
  const { showRoundResults } = useApi()
  return (await showRoundResults(gameId, roundId, host))?.data
}

const useResults = () => {
  const navigate = useNavigate()
  const { gameData, currentRound, player, getMultiplayerData, snapOnScoringRoom, setSnapOnScoringRoom } = useGameMode()
  const [loading, setLoading] = useState(false)
  // console.log('hook resultados', currentRound)

  const showResults = async () => {
    setLoading(true)
    await showResultsRequest(gameData.gameId, currentRound.id, player)
    setLoading(false)
  }

  useEffect(() => {
    const boolResult = currentRound?.players?.every(pl => pl.finished)
    if (boolResult) showResults()
    // if (gameData && !snapOnScoringRoom) { unsub(gameData.id, getMultiplayerData, navigate); setSnapOnScoringRoom(true) }
    // return () => unsub()
  }, [currentRound])

  return { loading, showResults }
}

export { useWineHook, compareWithExpert, useSubmitScoring, finishRoundRequest, showResultsRequest, useResults }
