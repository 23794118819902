import React from 'react'

// Multiplayer
import GameMode from 'modules/game-mode'
import GameRoom from 'modules/game-room'
import MultiplayerHome from 'modules/multiplayer/home'
import MultiWineRoom from 'modules/multiplayer/wine-room'
import MultiplayerScoring from 'modules/multiplayer/scoring'
// Single player
import Home from 'modules/home'
import WineRoom from 'modules/wine-room'
import Scoring from 'modules/scoring'
import GuessPrice from 'modules/guess-price'
import InvestorDeck from 'modules/investor-deck'

const RoutesConfig = () => {
  return [
    // {
    //   component: (props) => <GameMode {...props} />,
    //   name: 'Game Mode',
    //   path: '/game-mode'
    // },
    {
      component: (props) => <Home {...props} />,
      name: 'Home',
      path: '/home'
    },
    {
      component: (props) => <MultiplayerHome {...props}/>,
      name: 'Multiplayer Home',
      path: '/multiplayer'
    },
    {
      component: (props) => <GameRoom {...props} />,
      name: 'Game Room',
      path: '/game-room/'
    },
    {
      component: (props) => <MultiWineRoom {...props} />,
      name: 'Game Room',
      path: '/game-room/:id'
    },
    {
      component: (props) => <GuessPrice {...props} />,
      name: 'Guess price',
      path: '/game-room/:id/guess-price'
    },
    {
      component: (props) => <InvestorDeck {...props} />,
      name: 'Investor Deck',
      path: '/game-room/:id/investor-deck'
    },
    {
      component: (props) => <WineRoom {...props} />,
      name: 'Wine Room',
      path: '/wine-room/:id'
    },
    {
      component: (props) => <Scoring {...props} />,
      name: 'Scoring Room',
      path: '/rate/:id/:scoring'
    },
    {
      component: (props) => <MultiplayerScoring {...props} />,
      name: 'Scoring Room',
      path: 'multiplayer/:id/:scoring'
    },
    {
      component: (props) => <GuessPrice {...props} />,
      name: 'Guess price',
      path: '/wine-room/:id/guess-price'
    },
    {
      component: (props) => <InvestorDeck {...props} />,
      name: 'Investor Deck',
      path: '/wine-room/:id/investor-deck'
    }
  ]
}

export default RoutesConfig
