import React from 'react'
import { Box, Typography } from '@mui/material'
import Button from 'core/buttons/Button'
import { useNavigate } from 'react-router-dom'
// import { useGameMode } from 'providers/GameMode'

const PlayButtons = ({ handleOpen }) => {
  const navigate = useNavigate()
  // const { setMultiPlayer } = useGameMode()

  const setSingleplayerMode = () => {
    // setMultiPlayer({ loading: false, value: false })
    navigate('/home')
  }
  return (
    <Box sx={{ display: 'grid', mt: 4 }}>
      <Typography fontFamily="KaiseiDecol" fontWeight={700} fontSize={32} mx="auto" mb={6} mt={4}>
        Choose game mode
      </Typography>
      <Button onClick={setSingleplayerMode}>
        <Typography fontFamily="DMSansRegular" fontWeight={700} fontSize={18}>
          Solo Player
        </Typography>
      </Button>

      <Typography fontFamily="MundialRegular" fontWeight={300} fontSize={16} mx="auto" my={1.5}>
        OR
      </Typography>

      <Button onClick={handleOpen}>
        <Typography fontFamily="DMSansRegular" fontWeight={700} fontSize={18}>
          Play With Friends
        </Typography>
      </Button>
    </Box>
  )
}

export default PlayButtons
