import React, { useState } from 'react'
import WineHeader from 'core/wine-details/WineHeader'
import ArrowIcon from '@mui/icons-material/ArrowBackIos'
import { Box, Grid, IconButton, Typography, Button as MuiButton } from '@mui/material'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

const Header = ({ wine, setOpen, setNotesOpen }) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { scoring } = useParams()
  const [isMultiplayer] = useState(pathname.includes('multiplayer'))

  return (
    <Box sx={{ mt: 8 }}>
        <Grid container sx={{ px: 4 }}>
            <Grid item xs={4} sx={{ m: 'auto' }}>
              {
                !isMultiplayer
                  ? <IconButton onClick={() => {
                    scoring === 'rating'
                      ? navigate(pathname.replace('rating', 'scoring'))
                      : navigate(`/wine-room/${wine.wineryId}`)
                  }}><ArrowIcon /></IconButton>
                  : (scoring === 'rating'
                      ? <IconButton onClick={() => {
                        navigate(pathname.replace('rating', 'scoring'))
                      }}><ArrowIcon /></IconButton>
                      : null)
              }
            </Grid>
            <Grid item xs={4} sx={{ m: 'auto' }}>
                <MuiButton sx={{ height: 30, px: 1.5, py: 0, my: 'auto', border: '1px solid black', borderRadius: '50px' }} onClick={() => setNotesOpen(true)} >
                    <Typography fontFamily="DMSansMedium" fontSize={16} sx={{ color: 'black' }}> Notes </Typography>
                </MuiButton>
            </Grid>
            <Grid item xs={4} sx={{ m: 'auto' }}>
                <MuiButton sx={{ height: 30, px: 1.5, py: 0, my: 'auto', border: '1px solid black', borderRadius: '50px' }} onClick={() => setOpen(true)} >
                  <Typography fontFamily="DMSansMedium" fontSize={16} sx={{ color: 'black' }}> Taste tips </Typography>
                </MuiButton>
            </Grid>
        </Grid>
        <Box sx={{ mt: 2, mb: 4, mx: 4 }}><Typography fontSize={26} fontFamily="KaiseiDecolBold">Score your wine</Typography></Box>
        <Box></Box>

        <WineHeader wine={wine} />

    </Box>
  )
}

export default Header
