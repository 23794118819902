import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, IconButton, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useTheme } from '@mui/styles'
import HowToTest from './HowToTest'
import Steps from './Steps'

import Button from 'core/buttons/Button'

const OnBoarding = ({ tasteTips, handleClose }) => {
  const theme = useTheme()
  const navigate = useNavigate()

  return (
    <Box style={theme.background} sx={{ height: '100%', display: 'flex', flexDirection: 'column', m: 'auto' }}>
      {
        tasteTips &&
        isTasteTipsModal(handleClose)
      }

      <HowToTest title tasteTips={tasteTips}/>
      <Steps theme={theme} />
      <HowToTest details tasteTips={tasteTips}/>

      {
        !tasteTips &&
      <Box sx={{ position: 'fixed', bottom: 0, bgcolor: '#fffdf7', display: 'flex', width: 1, maxWidth: '450px' }}>
        <Button sx={{ width: '90%', m: 'auto', mb: 2 }} onClick={() => navigate('/game-mode')} >
          <Typography fontFamily="DMSansBold" fontSize={18}>
            Start
          </Typography>
        </Button>
      </Box>
      }

    </Box>
  )
}

const isTasteTipsModal = (handleClose) => {
  return (
    <Box sx={{ width: 1, mt: 4, mb: 2 }} >
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <IconButton
          edge="start"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
          sx={{ mr: 2 }}
          >
          <CloseIcon />
        </IconButton>
      </Box>
    </Box>
  )
}

export default OnBoarding
