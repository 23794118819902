import React, { useState } from 'react'
import { Grid, Typography, Button } from '@mui/material'

import HeaderImgBox from 'core/HeaderImgBox'
import HowToPlay from 'modules/how-to-play'

const Header = ({ player, game, handleClick }) => {
  const [openHow, setOpenHow] = useState(false)
  const handleClose = () => setOpenHow(false)
  const handleOpen = () => setOpenHow(true)

  return (
    <HeaderImgBox backgroundImg="/game-room-header.svg">
    <Grid container>
      <Grid item xs={6}>
        {/* <IconButton onClick={handleClick}>
          {' '}
          <ArrowIcon sx={{ height: 20, color: 'white' }} />{' '}
        </IconButton> */}
      </Grid>
      <Grid item xs={6} sx={{ display: 'flex' }}>
        <Button onClick={handleOpen} sx={{ width: 0.7, height: 30, px: 1, py: 0, m: 'auto', border: '1px solid white', borderRadius: '50px' }}>
          <Typography fontFamily="DMSansMedium" color="white" fontSize={14} sx={{ m: 0, p: 0 }}>
            How to play
          </Typography>
        </Button>
      </Grid>

    </Grid>
    <Grid container sx={{ mt: 4 }}>
      <Grid item xs={12}>
        <Typography fontFamily="KaiseiDecolReg" fontWeight={700} fontSize={32} sx={{ color: 'white' }}>
          { game?.host.host || 'Host' }&apos;s Game
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography fontFamily="MundialRegular" fontWeight={300} fontSize={20} sx={{ color: 'white' }}>
          { game?.winery?.name || 'Winery' } (Winery)
        </Typography>
      </Grid>
    </Grid>

    { openHow ? <HowToPlay open={openHow} handleClose={handleClose} /> : null }

  </HeaderImgBox>
  )
}

export default Header
