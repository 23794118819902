import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/styles'
import { Box, Dialog, Slide, Container, IconButton, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import { useGameMode } from 'providers/GameMode'

import Button from 'core/buttons/Button'
import Spinner from 'core/Spinner'
import useApi from 'api/apiRequests'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const RemovePlayerModal = ({ open, handleClose, playerToRemove }) => {
  const navigate = useNavigate()
  const { gameData } = useGameMode()
  const { removePlayerFromGame } = useApi()
  const theme = useTheme()
  const [loading, setLoading] = useState(false)

  const handleRemovePlayer = async () => {
    setLoading(true)
    const boolResponse = (await removePlayerFromGame(gameData.gameId, playerToRemove))?.data
    setLoading(false)
    if (!boolResponse) alert("There's been an error. Please try again")
  }
  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', ml: 20 }}>
      <Dialog
        fullScreen={theme.popUpsWidth}
        sx={{
          display: 'flex',
          width: '100vw',
          // maxWidth: '450px',
          m: 'auto',
          boxShadow: 'none',
          '.MuiBackdrop-root': {
            width: '100vw',
            // maxWidth: '450px',
            m: 'auto'
          },
          '.MuiDialog-container': {
            display: 'flex',
            alignItems: 'flex-end'
          },
          '.MuiPaper-root': {
            width: '100vw',
            // maxWidth: '450px',
            bgcolor: 'transparent'
          }
        }}
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <Container disableGutters
          sx={{
            '&.MuiContainer-root': {
              width: '100vw',
              maxWidth: '450px',
              height: 1,
              minHeight: 550,
              bgcolor: 'transparent'
            },
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end'
          }}
        >
          <Box sx={{
            bgcolor: 'primary.main',
            borderTopLeftRadius: '25px',
            borderTopRightRadius: '25px'
          }}>
            <Box sx={{ width: 1, mb: 0 }}>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close" sx={{ mr: 2, mt: 2 }}>
                  <CloseIcon fontSize="medium" />
                </IconButton>
              </Box>
            </Box>

            <Box sx={{
              display: 'grid',
              pb: 2,
              borderBottom: '1px solid #E0E0E0'
            }}>
                <Typography fontFamily="KaiseiDecolReg" fontWeight={700} fontSize={32}
                sx={{
                  [theme.breakpoints.up('sm')]: { mx: 10 },
                  mx: 6
                }} textAlign="center" >
                Are you sure you want to remove this player?
                </Typography>
            </Box>
            <Box sx={{
              display: 'grid',
              minHeight: 140,
              my: 4,
              [theme.breakpoints.up('sm')]: { mx: 4 },
              mx: 2,
              gap: 2
            }}>
              {
                loading
                  ? <Spinner />
                  : <>
                    <Button onClick={handleRemovePlayer}>
                      <Typography fontFamily="DMSansRegular" fontWeight={700} fontSize={18}>
                      Yes, remove player
                      </Typography>
                    </Button>
                  <Button onClick={handleClose}>
                    <Typography fontFamily="DMSansRegular" fontWeight={700} fontSize={18}>
                      No, take me back
                    </Typography>
                  </Button>
                  </>

              }

            </Box>
          </Box>
        </Container>
      </Dialog>
    </Box>
  )
}

export default RemovePlayerModal
