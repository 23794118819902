import React, { useState } from 'react'
import WineHeader from 'core/wine-details/WineHeader'
import ArrowIcon from '@mui/icons-material/ArrowBackIos'
import { Box, Grid, IconButton, Typography, Button as MuiButton } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useGameMode } from 'providers/GameMode'
import { useTheme } from '@mui/styles'

const Header = ({ wine, setOpen, setNotesOpen, onUpdateNotes }) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { scoring } = useParams()
  const { player } = useGameMode()

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <Box sx={{ [theme.breakpoints.down('sm')]: { mt: 4 }, mt: 6 }}>
        <Grid container sx={{ px: 4 }}>
            <Grid item xs={2} sx={{ m: 'auto' }}>
              {
                scoring === 'rating'
                  ? <IconButton onClick={() => {
                    navigate(pathname.replace('rating', 'scoring'))
                  }}><ArrowIcon /></IconButton>
                  : null
              }
            </Grid>
            <Grid item xs={3} sx={{ display: 'grid', m: 'auto' }}>
              {
                player?.isHost
                  ? <MuiButton sx={{ height: 30, px: 1.5, py: 0, m: 'auto', border: '1px solid black', borderRadius: '50px' }} onClick={() => navigate('/game-room')} >
                  <Typography fontFamily="DMSansMedium" fontSize={16} sx={{ color: 'black' }}> Lobby </Typography>
                </MuiButton>
                  : null
              }
            </Grid>
            <Grid item xs={3} sx={{ m: 'auto' }}>
                <MuiButton sx={{ height: 30, px: 1.5, py: 0, my: 'auto', border: '1px solid black', borderRadius: '50px' }} onClick={() => setNotesOpen(true)} >
                    <Typography fontFamily="DMSansMedium" fontSize={16} sx={{ color: 'black' }}> Notes </Typography>
                </MuiButton>
            </Grid>
            <Grid item xs={4} sx={{ display: 'grid', m: 'auto' }}>
                <MuiButton sx={{ height: 30, width: 1, px: 1.5, py: 0, m: 'auto', border: '1px solid black', borderRadius: '50px' }} onClick={() => setOpen(true)} >
                  <Typography fontFamily="DMSansMedium" fontSize={16} sx={{ color: 'black' }}> Taste tips </Typography>
                </MuiButton>
            </Grid>
        </Grid>
        <Box sx={{ mt: 2, mb: 4, mx: 4 }}><Typography fontSize={26} fontFamily="KaiseiDecolBold">Score your wine</Typography></Box>

        <WineHeader wine={wine} />

    </Box>
  )
}

export default Header
