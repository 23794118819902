import React, { useState } from 'react'
import WineDetails from 'core/wine-details/WineDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Box, Accordion, AccordionSummary, AccordionDetails, Typography, IconButton } from '@mui/material'

const Details = ({ wine, theme }) => {
  const arrowDown = 'rotate(360deg)'
  const arrowUp = 'rotate(180deg)'
  const [arrowSide, setArrowSide] = useState(arrowDown)
  return (
    <Accordion disableGutters sx={{ bgcolor: '#fffdf7' }}>
        <AccordionSummary
          sx={{
            m: 0,
            '.MuiAccordionSummary-content': { m: 0, p: 0 },
            '.MuiAccordionSummary-root': { m: 0, p: 0 }
          }}
          onClick={() => arrowSide === arrowDown ? setArrowSide(arrowUp) : setArrowSide(arrowDown) }
          // expandIcon={(<ExpandMoreIcon sx={{  }} />)}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', m: 'auto' }}>
            <Typography fontFamily="MundialRegular" fontSize={16} sx={{ color: '#2e2a26' }}>Wine Details</Typography>
            <IconButton sx={{ transform: arrowSide, transition: 'all 300ms ease-out' }}>
              <ExpandMoreIcon />
            </IconButton>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <WineDetails wine={wine} />
        </AccordionDetails>
    </Accordion>
  )
}

export default Details
