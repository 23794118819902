import React from 'react'
import { Box, Typography, Grid, Rating } from '@mui/material'

const RatingSection = ({ rate, ratePhrase, handleChange }) => {
  return (
    <Box
      sx={{ mx: 4, mt: 4, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} >
      <Typography fontFamily="MundialRegular" fontSize={24}>
        Did you like this wine?
      </Typography>

      {!ratePhrase &&
        <Typography fontFamily="MundialRegular" fontSize={14} sx={{ textAlign: 'center' }}>
          This does not impact your score, it is just to remind yourself in the bonus round
        </Typography>}

      <Typography fontFamily="KaiseiDecolBold" mt={4} fontSize={28} sx={{ textAlign: 'center' }} >
        {ratePhrase}
      </Typography>

      <Box sx={{ width: '100%', my: 2 }}>
        <Grid container spacing={2} sx={{}}>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'center', m: 'auto' }}>
              <Box sx={{ display: 'flex', alignContent: 'center' }}>
                <Rating
                  sx={{ mr: 1, transform: 'scale(2.2)' }}
                  onChange={(e, newValue) => {
                    handleChange(e.target.name, newValue)
                  }}
                  name="rating"
                  defaultValue={4}
                  value={rate}
                  min={0}
                  precision={0.5}
                  size="large"
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default RatingSection
