import { useEffect, useState } from 'react'
import { useWineryContext } from 'providers/WineryProvider'
import useWinesApi from 'firebaseKit/wines'
import { useWineContext } from 'providers/WinesProvider'
import { usePointsContext } from 'providers/PointsProvider'
import { useGameMode } from 'providers/GameMode'
import useApi from 'api/apiRequests'
import { unsub } from 'modules/game-room/hooks/FirebaseRealTimeHook'
import { useNavigate } from 'react-router-dom'
import { useCheckIsPlayer } from 'modules/game-room/hooks'

export const useWinesAndWineriesHook = (id) => {
  const [loadingWines, setLoadingWines] = useState(true)
  const [winery, setWinery] = useState(null)
  const [nextWineryToScore, setNextWineryToScore] = useState(null)
  const { wineries } = useWineryContext()
  const { wines, setWineList } = useWineContext()
  const { getWinesByWynery } = useWinesApi()

  useEffect(() => {
    if (wineries) {
      const [wineryData] = wineries.filter((Winery) => Winery.id === id)
      setWinery(wineryData)
      setNextWineryToScore(getNextWineryToScore(wineries))
      getWinesByWynery(wineryData.id).then((wineList) => setWineList(wineList))
      setTimeout(() => {
        setLoadingWines(false)
      }, [2000])
    }
    return () => setWineList(null) // Clean up wine list on unmount
  }, [wineries, id])

  return { loadingWines, setLoadingWines, wines, winery, nextWineryToScore, setNextWineryToScore }
}

export const useMultiplayerWineHook = () => {
  const {
    player, getPoints, gameData, getMultiplayerData, removeGameDataState
  } = useGameMode()
  const navigate = useNavigate()
  const { getPlayerPointsPerRound } = useApi()
  const { checkIsPlayerOrHost } = useCheckIsPlayer()
  const multiplayerwinesData = gameData?.wines
  const [wineryPoints, setWineryPoints] = useState(0)

  useEffect(() => {
    checkIsPlayerOrHost() // Check if player is active
    if (!gameData) {
      getMultiplayerData().then(data => {
        if (player.nickname && !data) navigate('/game-mode')
        if (!data) removeGameDataState(navigate)
      })
    }
    if (gameData) getPoints(gameData).then(points => setWineryPoints(points))
    // if (gameData && !snapOnWineRoom) { unsub(gameData.id, getMultiplayerData, navigate); setSnapOnWineRoom(true) }
  }, [gameData])

  const getRoundPoints = async (roundId) => gameData && (await getPlayerPointsPerRound(gameData?.id, roundId, player.nickname))?.data

  const isFullyScored = (wineId) => {
    if (multiplayerwinesData) {
      const [wine] = multiplayerwinesData.filter(wine => wine.id === wineId)
      return wine?.finished
    }
    return false
  }
  const isGameFinished = () => {
    if (multiplayerwinesData) {
      const boolean = multiplayerwinesData.every(wine => wine.finished)
      return boolean
    }
    return false
  }

  return {
    wineryPoints,
    getRoundPoints,
    isFullyScored,
    isGameFinished
  }
}

export const useSinglePlayerWineHook = (winery, setNextWineryToScore) => {
  const { wineries, setFullyScoredWinery } = useWineryContext()
  const { wines } = useWineContext()
  const {
    getWineScore, setWineToScore, setWineToRate, getWineRating,
    wineToScore, getWinePoints, RemoveWineryPoints
  } = usePointsContext()
  const [isRated, setIsRated] = useState(false)
  const [wineryPoints, setWineryPoints] = useState(0)

  // Winery points From local Storage
  const [isFullyScored, setIsFullyScored] = useState(false)
  const [scoredPlusExtraPoints, setScoredPlusExtraPoints] = useState(false)
  const [restart, setRestart] = useState(false)

  // See which one wasn't scored and send it
  useEffect(() => {
    // If user chooses to play (score & rate), by default wine to score & wine to rate will be the same wine 'toScoreAndRate', because of the sequence.
    // If user chooses just rating, in 'start-playing' modal, wine will be wine to rate 'toRate',
    if (wines) {
      const toScoreAndRate = wines.find(wine => {
        const notScored = getWineScore(wine.name)
        const rated = getWineRating(wine.name)
        rated != null && setIsRated(true)

        if (!(notScored || rated)) {
          return wine
        } else {
          setWineryPoints(prev => prev + getWinePoints(wine.name))
          return null
        }
      })
      setWineToScore(toScoreAndRate)
      setWineToRate(toScoreAndRate)
      if (!toScoreAndRate && winery) {
        setIsFullyScored(true)
        setFullyScoredWinery(winery.name)
        setScoredPlusExtraPoints(checkIsScoredPlusExtraPoints(wines))
      }
    }
    if (restart) {
      setNextWineryToScore(getNextWineryToScore(wineries))
    }
    return () => { // Clean up winery points on unmount
      setWineryPoints(0)
      setIsFullyScored(false)
      setIsRated(false)
      setScoredPlusExtraPoints(false)
    }
  }, [wines, restart])

  return { isRated, wineryPoints, isFullyScored, scoredPlusExtraPoints, wineToScore, getWinePoints, RemoveWineryPoints, restart, setRestart }
}

export const getNextWineryToScore = (wineries) => {
  return wineries.find((winery) => !('fullyScored' in winery))?.id
}

export const checkIsScoredPlusExtraPoints = (wines) => {
  const scoring = JSON.parse(localStorage.getItem('scoring')) || []
  console.log(scoring)
  const noRatedPlus = wines.find(wine => {
    if (wine.name in scoring && !('extraPoints' in scoring[wine.name])) {
      return wine
    }
    return null
  })
  if (!noRatedPlus) {
    return true
  }
  return false
}
