import { Container, Typography, Box, Grid } from '@mui/material'
import Spinner from 'core/Spinner'
import React from 'react'
import { usePlayerRoundPoints, usePlayerGamePoints } from './hooks'
import PlayerPositions from './PlayerPositions'
import PlayerTab from './PlayerTab'

const LeaderBoard = ({ gameId, roundId }) => {
  const { roundData } = usePlayerRoundPoints(gameId, roundId)
  const { playerPoints } = usePlayerGamePoints(gameId)
  // console.log('roundData roundData data', roundData)
  return (
    <Container disableGutters>
      { roundData
        ? <>
        <Box sx={{ borderTop: '1px solid #F0D7C1' }}>
          <Typography fontFamily="KaiseiDecolBold" fontSize={26} sx={{ textAlign: 'center', m: 'auto', mt: 2 }}>
            Winner of Round { roundData.round }
          </Typography>
            {
              roundData &&
              ('winner3' in roundData
                ? <Grid container sx={{ my: 2 }} >
                    <PlayerPositions playerData={roundData.winner2} position={2} />
                    <PlayerPositions playerData={roundData.winner1} position={1} />
                    <PlayerPositions playerData={roundData.winner3} position={3} />
                  </Grid>
                : 'winner2' in roundData
                  ? <Grid container sx={{ my: 2 }} >
                      <PlayerPositions playerData={roundData.winner2} position={2} />
                      <PlayerPositions playerData={roundData.winner1} position={1} />
                    </Grid>
                  : 'winner1' in roundData
                    ? <Grid container sx={{ my: 2 }} >
                        <Grid item xs={4}/>
                        <PlayerPositions playerData={roundData.winner1} position={1} />
                        <Grid item xs={4}/>
                      </Grid>
                    : null)
            }

        </Box>

        <Box sx={{ borderTop: '1px solid #F0D7C1', borderBottom: '1px solid #F0D7C1', py: 2 }}>
          <Typography fontFamily="KaiseiDecolBold" fontSize={18} sx={{ textAlign: 'center', m: 'auto', color: '#262B2E' }}>
            Overall leaderboard
          </Typography>
        </Box>

        <Container sx={{ bgcolor: '#FAF3ED' }}>
          {
            playerPoints?.players
              ? playerPoints?.players.map((player) => <PlayerTab key={player.name} nickName={player.name} points={player.points} position={player.position} />)
              : null
          }
        </Container>
      </>
        : <Spinner />
      }
    </Container>
  )
}

export default LeaderBoard
