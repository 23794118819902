import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import { useTheme } from '@mui/styles'

import Header from './Header'
import Details from 'modules/scoring/Details'
import RatingSection from 'modules/scoring/Rating'
import WineScoring from 'modules/scoring/Scoring'
import TasteTips from 'modules/scoring/TasteTips'
import Button from 'core/buttons/Button'
import Spinner from 'core/Spinner'
import MatchResults from './MatchResults'
import WaitingScreen from './components/WaitingScreen'

import { useRatingStars } from 'utils'
import { useWineHook, useSubmitScoring, useResults } from './hooks'
import { useGameMode } from 'providers/GameMode'
import { UnSubscribeMethod } from 'modules/game-room/hooks/FirebaseRealTimeHook'
import HowToPlay from 'modules/how-to-play'
import TastingNotes from 'modules/scoring/TastingNotes'

const Scoring = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { scoring } = useParams()
  const [openTips, setOpenTips] = useState(false)
  const [openNotes, setOpenNotes] = useState(false)

  const { gameData, currentRound } = useGameMode()
  const { rate, ratePhrase, handleChange } = useRatingStars()
  // Set players to this round
  const { wineToScore } = useWineHook()
  const {
    closeHowToPlay,
    loadingPoints,
    loadingScreen,
    openHow,
    openResults,
    points,
    score,
    setNotes,
    setOpenResults,
    setScoring,
    setWaitScreen,
    submitScoring,
    waitScreen
  } = useSubmitScoring(wineToScore)
  const { loading } = useResults()

  // Scroll the window to the top on initial component mount (iOS
  // issue)
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' })
  }, [])

  // finishRoundRequest by the host
  return (
    <Box style={{ ...theme.background }} sx={{ display: 'grid', mx: 'auto' }}>
      { gameData ? <UnSubscribeMethod gameData={gameData} /> : null }
      {
        !wineToScore || loadingScreen
          ? <Box sx={{ m: 'auto' }}><Spinner /> </Box>
          : <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Header wine={wineToScore} setOpen={setOpenTips} setNotesOpen={setOpenNotes} />
          {/* <Divider /> */}
          <Details wine={wineToScore} />

          {/* Body */}
          <Box sx={{ }}>

            {
              scoring === 'scoring'
                ? <>
                <WineScoring wine={wineToScore} scoring={score} setScoring={setScoring} />
              <Box sx={{ m: 4, position: 'relative' }}>
                <Button onClick={() => navigate(pathname.replace('scoring', 'rating'))} >
                  <Typography fontFamily="DMSansBold" fontSize={18}>
                    Next
                  </Typography>
                  <Box component="img" src="/buttonLongArrow.svg" alt="long-arrow" sx={{ position: 'absolute', right: 20 }} />
                </Button>
              </Box>
              </>
                : <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <RatingSection rate={rate} ratePhrase={ratePhrase} handleChange={handleChange} />
                <Box sx={{ m: 4 }}>
                  {
                    loadingPoints
                      ? <Button>
                      <Spinner />
                    </Button>
                      : <Button sx={{ borderColor: rate == null && 'gray' }} disabled={rate == null} onClick={async () => await submitScoring(rate)} >
                      <Typography fontFamily="DMSansBold" fontSize={18}>
                        Submit
                      </Typography>
                    </Button>
                  }
                </Box>
              </Box>
            }

          </Box>

          { wineToScore && openHow && gameData?.round === 1 &&
          <HowToPlay open={openHow} handleClose={closeHowToPlay} wineToScore={wineToScore} handleClick={closeHowToPlay} /> }

          {
            openNotes &&
              <TastingNotes
                open={openNotes}
                setOpen={setOpenNotes}
                wine={wineToScore}
                closeButtonLabel="Return to scoring"
                onUpdate={(notes) => setNotes(notes)}
              />
          }
          {
            openTips &&
            <TasteTips open={openTips} setOpen={setOpenTips} />
          }
          {
            wineToScore && currentRound?.players &&
          <WaitingScreen loading={loading} open={waitScreen} setOpen={setWaitScreen} wine={wineToScore} players={currentRound.players} />
          }
          {
            wineToScore && openResults &&
            <MatchResults open={openResults} setOpen={setOpenResults} wine={wineToScore} points={points} />
          }
        </Box>
      }

    </Box>
  )
}

export default Scoring
