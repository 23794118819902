import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { Box, Typography, Slide, List, Container, Dialog, IconButton, Divider } from '@mui/material'
import { useTheme } from '@mui/styles'
import Button from 'core/buttons/Button'
import { useNavigate } from 'react-router-dom'
import { useWineContext } from 'providers/WinesProvider'
import { usePointsContext } from 'providers/PointsProvider'
import { useWineryContext } from 'providers/WineryProvider'
import { JUST_RATING } from 'utils'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})
export const StartPlaying = ({ open, setOpen, openBoarding, setOpenOnBoarding }) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const handleClose = () => {
    setOpen(false)
  }
  const { wines } = useWineContext()
  const { setWineryPreferences } = useWineryContext()
  const { getWineRating, setWineToRate, wineToScore } = usePointsContext()

  const toRate = wines.find(wine => {
    const notRated = getWineRating(wine.name)
    if (!notRated) {
      return wine
    }
    return null
  })

  const justRating = () => {
    setWineToRate(toRate)
    return toRate
  }

  return (
    <Box sx={{
      height: '100%',
      display: 'flex',
      flexDirection: 'column'
    }}>

      <Dialog
        fullScreen={theme.popUpsWidth}
        sx={{
          display: 'flex',
          width: '100vw',
          // maxWidth: '450px',
          m: 'auto',

          '.MuiBackdrop-root': {
            width: '100vw'
            // maxWidth: '450px',
          },
          '.MuiPaper-root': {
            width: '100vw',
            bgcolor: 'transparent'
            // height: '70vh',
          },
          '.MuiDialog-paper': { }
        }}
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
      <Container disableGutters sx={{
        '&.MuiContainer-root': {
          display: 'flex',
          alignItems: 'flex-end',
          width: '100vw',
          maxWidth: '450px',
          height: 1,
          bgcolor: 'transparent'
        }
      }}>
        <Box sx={{
          height: '70vh',
          width: 1,
          bottom: 0,
          bgcolor: 'primary.main',
          borderTopLeftRadius: '25px',
          borderTopRightRadius: '25px'
        }}>
          <Box sx={{ bgcolor: 'primary.main' }}>

            <Box sx={{ width: 1, my: 2 }} >
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                  sx={{ mr: 2 }}
                  >
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>

            <List sx={{ px: 2, width: '100%' }}>
              <Box sx={{ }}>
                <Typography component="h1" sx={{ fontFamily: 'KaiseiDecolBold', textAlign: 'center' }} fontSize={32}>Are you playing <br/> The Tasting Game <br/> today?</Typography>
              </Box>
            </List>

        <Divider />

            <List sx={{ px: 2, width: '100%' }}>
              <Box sx={{ my: 2 }}>
                <Button onClick={() => {
                  handleClose()
                  setOpenOnBoarding(!openBoarding)
                  // navigate(`/rate/${wineToScore.id}/scoring`)
                }}
                > <Typography variant="p" fontFamily="DMSansBold" fontSize={18} >Yes, I am playing!</Typography> </Button>
              </Box>
              <Box sx={{ my: 2 }}>
                <Button onClick={() => {
                  setWineryPreferences(wineToScore.winery, JUST_RATING)
                  const justRatingWine = justRating()
                  navigate(`/rate/${justRatingWine.id}/just_rating`)
                }} > <Typography variant="p" fontFamily="DMSansBold" fontSize={18}>No, just rating</Typography> </Button>
              </Box>
            </List>
          </Box>
          </Box>
        </Container>
      </Dialog>
    </Box>
  )
}

export default StartPlaying
