import { useEffect, useState } from 'react'
import { getFirestore, doc, onSnapshot } from 'firebase/firestore'
import { useGameMode } from 'providers/GameMode'

export const UnSubscribeMethod = ({ gameData }) => {
  const { getMultiplayerData, removeGameDataState } = useGameMode()

  useEffect(() => {
    let unSubscribeMethod = () => {}
    unSubscribeMethod = onSnapshot(doc(getFirestore(), 'multiplayerGame', gameData.id), (doc) => {
      // console.log('executes game snap listener')
      if (!doc.data()) removeGameDataState()
      else getMultiplayerData()
      return doc.data()
    })
    return () => { unSubscribeMethod() }
  }, [])
  return ''
}

export const unsub = (gameId, getMultiplayerData, removeGameDataState) => onSnapshot(doc(getFirestore(), 'multiplayerGame', gameId), (doc) => {
  // console.log('executes EVENT listener')
  // if (!doc.data()) navigate('/game-mode')
  if (!doc.data()) removeGameDataState()
  else getMultiplayerData()
  return doc.data()
})
