import { useState, useEffect } from 'react'
import { usePointsContext } from 'providers/PointsProvider'
import { useMultiplayerWineHook } from 'modules/wine-room/Hooks'

export const useMultiPointsHook = (wine) => {
  const [loading, setLoading] = useState(true)
  const [points, setPoints] = useState(0)
  const [userScore, setUserScore] = useState(null)
  const [isRated, setIsRated] = useState(null)
  // Local storage for points
  const { getRoundPoints } = useMultiplayerWineHook()

  useEffect(() => {
    const getAsync = async () => {
      const roundData = await getRoundPoints(wine.id)
      if (roundData) {
        const { points, rate, score } = roundData
        setPoints(points)
        setIsRated(rate)
        setUserScore(score)
        setLoading(false)
      }
      // console.log('roundData y wine', wine, roundData)
    }
    getAsync()
  }, [wine])

  return { points, userScore, isRated, loading }
}

export const useSinglePointsHook = (wine) => {
  const [loading, setLoading] = useState(true)
  const [points, setPoints] = useState(0)
  const [userScore, setUserScore] = useState(null)
  const [isRated, setIsRated] = useState(null)
  // Local storage for points
  const { getWineRating, getWinePoints, getWineScore } = usePointsContext()

  useEffect(() => {
    setPoints(getWinePoints(wine.name))
    setUserScore(getWineScore(wine.name))
    setIsRated(getWineRating(wine.name))
    setLoading(false)
  }, [wine])

  return { points, userScore, isRated, loading }
}
