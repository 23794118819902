import React from 'react'
import { Box, Dialog, Slide } from '@mui/material'
import { useTheme } from '@mui/styles'

import WaitingForPlayers from 'core/waiting-players/WaitingForPlayers'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const WaitingGuessPriceScreen = ({ open, setOpen }) => {
  const theme = useTheme()
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', m: 'auto', height: '100%' }}>
      <Dialog
        fullScreen={theme.popUpsWidth}
        sx={{
          display: 'flex',
          width: '100vw',
          maxWidth: '450px',
          m: 'auto',

          '.MuiBackdrop-root': {
            width: '100vw',
            maxWidth: '450px',
            m: 'auto'
          },
          '.MuiDialog-container': {
            display: 'flex',
            alignItems: 'flex-end'
          },
          '.MuiPaper-root': {
            width: '100vw',
            maxWidth: '450px',
            bgcolor: '#fffdf7'

          },
          '.MuiDialog-paper::-webkit-scrollbar': {
            display: 'none'
          }
        }}
        open={open}
        TransitionComponent={Transition}
      >

        <Box component="img" src="/background.svg" alt="" sx={{ position: 'absolute', width: '100%', height: 1, minHeight: 750, overflowY: 'hidden' }} />
        <Box sx={{
          zIndex: 10,
          height: 1,
          minHeight: 750,
          position: 'relative',
          overFlowY: 'hidden',
          background: 'linear-gradient(to bottom, rgba(255,253,247,0), rgb(250,243,237))'
        }}>

        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: 1, minHeight: 750 }}>
            <Box sx={{ width: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <WaitingForPlayers content="We are waiting for all players to guess the prices" />
            </Box>
        </Box>

        </Box>
      </Dialog>
    </Box>
  )
}

export default WaitingGuessPriceScreen
