import React from 'react'
import { Box, Grid, Typography } from '@mui/material'

import Icon from '@material-ui/core/Icon'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  imageIcon: {
    //   height: '100%'
  },
  iconRoot: {
    textAlign: 'center'
  }
})

const Steps = ({ theme }) => {
  const classes = useStyles()

  return (
    <Box sx={{ py: 2, ml: 4 }}>
            {
                stepList.map((list, i) => {
                  const { step, title, details, logo } = list
                  return (
                        <Grid container key={i} >
                            <Grid item xs={2} sx={{ pt: 4 }}>
                                <Icon classes={{ root: classes.iconRoot }} sx={{}}>
                                    <img className={classes.imageIcon} src={logo}/>
                                </Icon>
                            </Grid>
                            <Grid item xs={10} sx={{ py: 2, borderTop: i === 0 ? 1 : 0, borderBottom: 1, borderColor: '#2e2a26' }}>
                                <Grid item xs={12} sx={{ }}>
                                    <Typography style={theme.paragraph} component="p" variant="subtitle">{ step }</Typography>
                                </Grid>
                                <Grid item xs={12} sx={{ pb: 1 }}>
                                    <Typography style={theme.title} component="h1" variant="h5">{ title }</Typography>
                                </Grid>
                                <Grid item xs={12} sx={{ pr: 4 }}>
                                    {
                                        details.map((detail, i) => <Typography key={i} style={theme.paragraph} component="p" variant="p" >
                                        { detail } { details.length > 1 && <><br /><br /></> }
                                    </Typography>)
                                    }
                                </Grid>
                            {
                                list.points &&
                                list.pointsRules.map((pointRule, i) => {
                                  const withDecimals = list.points[i].toString().includes('.')
                                  return (
                                    <Grid key={i} item xs={12} sx={{ display: 'flex', alignItems: 'center', pr: 4, pb: 1, position: 'relative' }}>
                                        <Box sx={{ position: 'absolute', left: withDecimals ? 5 : 13, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            <Typography fontFamily="KaiseiDecolBold" color="white" fontSize={withDecimals ? 20 : 24} mt={-0.3} >{list.points[i]}</Typography>
                                        </Box>
                                        <Box component="img" src="/emptyStar.svg" alt="point-logo" sx={{ width: 40, mr: 1.5 }} />
                                        <Typography>{pointRule}</Typography>
                                    </Grid>
                                  )
                                })
                            }
                            </Grid>
                        </Grid>
                  )
                })
            }
    </Box>
  )
}

export default Steps

const stepList = [
  {
    step: 'Wine Smash',
    title: 'Scoring the wine',
    details: ['It’s not about quality; a score of 1 isn’t necessarily better or worse than a score of 5. It’s just a way of describing the wine.', 'Then, at the end, tell us how much you liked it.'],
    logo: '/onBoardingTwo/select.svg'
  },
  {
    step: 'Scoring',
    title: 'Awarding points',
    details: ['When you’ve finished, you can compare your scores against your friends or against our Expert.', 'The more you taste, the better you’ll get!', 'Points are awarded as follows:'],
    points: [5, 4, 2.5, 2],
    pointsRules: ['Points = Exact match', 'Points = Within ½ point', 'Points = Within 1 point', 'Points = Within 1½ points '],
    pointsLogo: ['/emptyStar.svg', '/emptyStar.svg', '/emptyStar.svg', '/emptyStar.svg'],
    logo: '/onBoardingTwo/awarding.svg'
  },
  {
    step: 'Scoring',
    title: 'Bonus points',
    details: ['Bonus points are available for correctly guessing the wine’s price. The person with the most points wins.'],
    logo: '/onBoardingTwo/bonus.svg'
  },
  {
    step: "Expert's description",
    title: 'Wine Expert',
    details: ['At the end, we reveal our Wine Expert’s tasting notes. The app keeps a handy record of which wines you liked the most and you’ll hone your tasting skills.'],
    logo: '/onBoardingTwo/wineExpert.svg'
  }
]
