import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Box, Divider, Typography } from '@mui/material'
import { useTheme } from '@mui/styles'

import Header from './Header'
import Details from './Details'
import RatingSection from './Rating'
import WineScoring from './Scoring'
import TasteTips from './TasteTips'
import TastingNotes from './TastingNotes'
import Button from 'core/buttons/Button'
import Spinner from 'core/Spinner'
import MatchResults from './MatchResults'

import { useRatingStars } from 'utils'
import { useWineHook, useSubmitScoring } from './hooks'

const Scoring = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { scoring } = useParams()
  const [openTips, setOpenTips] = useState(false)
  const [openNotes, setOpenNotes] = useState(false)

  const { rate, ratePhrase, handleChange } = useRatingStars()
  const { wineToScore, wineToRate } = useWineHook()
  const {
    loadingPoints,
    openResults,
    points,
    score,
    setOpenResults,
    setNotes,
    setScoring,
    submitScoring
  } = useSubmitScoring(wineToScore, wineToRate)

  // Scroll the window to the top on initial component mount (iOS
  // issue)
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' })
  }, [])

  return (
    <Box style={theme.background} sx={{ height: '100%', display: 'flex', flexDirection: 'column', mx: 'auto' }}>
      {
        !wineToScore
          ? <Box sx={{ m: 'auto' }}><Spinner /> </Box>
          : <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <Header wine={wineToScore} setOpen={setOpenTips} setNotesOpen={setOpenNotes} />
          <Divider />
          <Details wine={wineToScore} theme={theme} />

          {/* Body */}
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>

            {
              scoring === 'scoring'
                ? <>
                <WineScoring wine={wineToScore} scoring={score} setScoring={setScoring} />
              <Box sx={{ m: 4, position: 'relative' }}>
                <Button onClick={() => navigate(pathname.replace('scoring', 'rating'))} >
                  <Typography fontFamily="DMSansBold" fontSize={18}>
                    Next
                  </Typography>
                  <Box component="img" src="/buttonLongArrow.svg" alt="long-arrow" sx={{ position: 'absolute', right: 20 }} />
                </Button>
              </Box>
              </>
                : <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: 1 }}>
                  <RatingSection rate={rate} ratePhrase={ratePhrase} handleChange={handleChange} />
                <Box sx={{ m: 4, position: 'relative' }}>
                  {
                    loadingPoints
                      ? <Button>
                      <Spinner />
                    </Button>
                      : <Button onClick={() => submitScoring(rate)} >
                      <Typography fontFamily="DMSansBold" fontSize={18}>
                        Submit
                      </Typography>
                    </Button>
                  }
                </Box>
              </Box>
            }

          </Box>

            {
              openTips &&
              <TasteTips open={openTips} setOpen={setOpenTips} />
            }
            {
              openNotes &&
                <TastingNotes
                  open={openNotes}
                  setOpen={setOpenNotes}
                  wine={wineToScore}
                  closeButtonLabel="Return to scoring"
                />
            }
            {
              wineToScore && openResults &&
              <MatchResults open={openResults} setOpen={setOpenResults} wine={wineToScore} points={points} />
            }
        </Box>
      }

    </Box>
  )
}

export default Scoring
