import React, { useEffect, useState } from 'react'
import { Box, Typography, Slide, Divider, Dialog, IconButton, Container, Grid } from '@mui/material'
import ArrowIcon from '@mui/icons-material/ArrowBackIos'
import { useTheme } from '@mui/styles'
import WineHeader from 'core/wine-details/WineHeader'
import WineDetails from 'core/wine-details/WineDetails'
import YouVsExpert from './YouVsExpert'

import { useWineContext } from 'providers/WinesProvider'
import ButtonSection from './ButtonSection'
import { getRatePhrase } from 'utils'
import LeaderBoard from 'modules/multiplayer/leaderboard'
import { useGameMode } from 'providers/GameMode'
import { useParams } from 'react-router-dom'
import useWineriesApi from 'firebaseKit/wineries'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})
export const AboutUs = ({ open, setOpen, wine, userScore, isRated, points, wineryFullyScored, scoredPlusExtraPoints }) => {
  const theme = useTheme()
  const { setIsWineScored } = useWineContext()
  const { id } = useParams()
  const { getWinery } = useWineriesApi()
  const [wineryData, setWineryData] = useState(null)

  useEffect(() => {
    getWinery(id).then((data) => setWineryData(data))
  }, [id])

  const handleClose = () => {
    setIsWineScored({ name: null })
    setOpen(false)
  }

  return (
    <Box style={theme.background} sx={{ height: '100%', display: 'flex', flexDirection: 'column', ml: 20 }}>
      <Dialog
        fullScreen={theme.popUpsWidth}
        sx={{
          display: 'flex',
          width: '100vw',
          m: 'auto',

          '.MuiBackdrop-root': {
            width: '100vw',
            m: 'auto'
          },
          '.MuiDialog-container': {
            display: 'flex',
            alignItems: 'flex-end'
          },
          '.MuiPaper-root': {
            width: '100vw',
            bgcolor: 'transparent'
          }
        }}
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
      <Container disableGutters sx={{ '&.MuiContainer-root': { width: '100vw', minHeight: 1, maxWidth: '450px', bgcolor: 'primary.main' } }}>
        <Box sx={{ bgcolor: 'primary.main' }}>
            <Box sx={{ width: 1, mt: 8, mb: !userScore ? 8 : 2, px: 2 }}>
              {!userScore && isRated
                ? (
                  <Typography fontFamily="KaiseiDecolBold" fontSize={28} sx={{ textAlign: 'center', mx: 4 }}>
                  {getRatePhrase()}
                </Typography>
                  )
                : null}
              <Box sx={{ position: 'relative', mb: 4 }}>
                <Grid container>
                  <Grid item xs={4}>
                  </Grid>
                  <Grid item xs={4}>
                  {userScore
                    ? (
                      <Typography fontFamily="MundialDemiBold" fontSize={16} sx={{ textAlign: 'center', m: 'auto' }}>
                        Your Results
                    </Typography>
                      )
                    : null}
                  </Grid>
                  <Grid item xs={4}>
                    <IconButton color="inherit" onClick={handleClose} aria-label="close" sx={{ position: 'absolute', top: -10, right: 0 }}>
                      <Box component="img" src="/closeIcon.svg" alt="close" sx={{}} />
                    </IconButton>
                  </Grid>
                </Grid>
              </Box>
            </Box>

            <WineHeader wine={wine} isRated={isRated} />
            <Divider sx={{ my: 2 }} />
            {/* If there's scoring */}
            {userScore ? <YouVsExpert wine={wine} winery={wineryData} userScore={userScore} userPoints={points} /> : null}
            <WineDetails wine={wine} isRated={isRated} />
          <ButtonSection wine={wine} scoredPlusExtraPoints={scoredPlusExtraPoints} wineryFullyScored={wineryFullyScored} handleClose={handleClose} userScore={userScore} isRated={isRated} />
        </Box>
        </Container>

      </Dialog>
    </Box>
  )
}

export default AboutUs
