import * as React from 'react'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import { Box, Typography, Slide, List, Container, ListItemText, Dialog, IconButton } from '@mui/material'
import { useTheme } from '@mui/styles'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="up" ref={ref} {...props} /* sx={{ height: '70vh' }} */ />
})
export const AboutUs = ({ open, setOpen, name, location, description }) => {
  const theme = useTheme()
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>

     <Dialog
        fullScreen={theme.popUpsWidth}
        sx={{
          display: 'flex',
          width: '100vw',
          // maxWidth: '450px',
          m: 'auto',

          '.MuiBackdrop-root': {
            width: '100vw',
            // maxWidth: '450px',
            m: 'auto'
          },
          '.MuiDialog-container': {
            display: 'flex',
            alignItems: 'flex-end'
          },
          '.MuiPaper-root': {
            width: '100vw',
            // maxWidth: '450px',
            bgcolor: 'transparent'
          }
          // '.MuiDialog-paper': { ml:-5 },
        }}
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
      <Container disableGutters sx={{ '&.MuiContainer-root': { width: '100vw', maxWidth: '450px', minHeight: 1, bgcolor: 'primary.main' } }}>
      <Box sx={{ bgcolor: 'primary.main' }}>
        <Box sx={{ width: 1, mt: 6 }} >
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
              sx={{ mr: 2 }}
              >
              <Box component="img" src="/closeIcon.svg" alt="close" sx={{ }} />
            </IconButton>
          </Box>
        </Box>

        <List sx={{ px: 4, width: '100%' }}>
          <Box sx={{ my: 2 }}>
            <Typography component="h1" sx={{ fontFamily: 'KaiseiDecolBold' }} fontSize={26}>{name}</Typography>
          </Box>
          <Box button sx={{ my: 2 }}>
            <ListItemText sx={{ }} >
              <Box sx={{ display: 'flex' }}>
                <LocationOnOutlinedIcon sx={{ mr: 1 }}/>
                <Typography component="subtitle" sx={{ fontFamily: 'MundialLight' }} fontSize={18}>{location}</Typography>
              </Box>
            </ListItemText>
          </Box>
          <Box sx={{ my: 2 }}>
            <ListItemText>
              <Typography component="p" sx={{ fontFamily: 'MundialLight' }} fontSize={18}>{description}</Typography>
            </ListItemText>
          </Box>
        </List>
        </Box>
        </Container>
      </Dialog>
    </Box>
  )
}

export default AboutUs
