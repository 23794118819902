import React from 'react'
import ReactDOM from 'react-dom'
import './theme/index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { CssBaseline, ThemeProvider } from '@mui/material'
import theme from 'theme'
import GameMode from 'providers/GameMode'

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {/* <GameMode> */}
        <App />
      {/* </GameMode> */}
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
