import React from 'react'
import { Box, Typography, Grid } from '@mui/material'

const PlayerPositions = ({ playerData, position }) => {
  const { player, points } = playerData
  return (
    <Grid item xs={4} sx={{ display: 'flex' }} >
        <Box sx={{ display: 'flex', flexDirection: 'column', width: 75, justifyContent: 'center', m: 'auto', mb: 0 }}>
            <Box component="img" src={position === 1 ? '/multiplayer/crown.svg' : position === 2 ? '/multiplayer/silverCrown.svg' : '/multiplayer/bronzeCrown.svg' } sx={{ width: position === 1 ? 60 : 40, m: 'auto', mb: 0.5 }} />
            <Box sx={{ display: 'flex', height: position === 1 ? 60 : 40, width: position === 1 ? 60 : 40, borderRadius: '50%', bgcolor: '#CE8900', m: 'auto' }}>
                <Typography fontFamily="KaiseiDecolBold" fontSize={position === 1 ? 24 : 20} sx={{ color: 'white', m: 'auto', mt: position === 1 ? 1.3 : 0.4 }} >
                    { player.charAt(0) }
                </Typography>
            </Box>
            <Typography fontFamily="MundialBold" fontSize={12.5} sx={{ m: 'auto', mt: 0.2, color: '#000000' }} >
                { player }
            </Typography>
            <Typography fontFamily="MundialBold" fontSize={12.5} sx={{ m: 'auto', mt: 0.2, color: '#000000' }} >
                { points }
            </Typography>
        </Box>
    </Grid>
  )
}

export default PlayerPositions
