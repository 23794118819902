import React, { useRef, useEffect, useState } from 'react'
import { useTheme } from '@mui/styles'
import { Box, Dialog, Slide, Container, IconButton, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import Button from 'core/buttons/Button'
// import { useGameMode } from 'providers/GameMode'
import { useNavigate } from 'react-router-dom'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const PlayWithFriendsModal = ({ handleClose }) => {
  const btnRef = useRef()
  const [focused, setFocused] = useState(false)
  const navigate = useNavigate()
  const theme = useTheme()
  // const { setMultiPlayer } = useGameMode()

  const setMultiplayerMode = () => {
    // setMultiPlayer({ loading: false, value: true })
    navigate('/multiplayer')
  }

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Dialog
        fullScreen={theme.popUpsWidth}
        onFocus={() => {
          if (!focused) {
            btnRef.current.focus()
            setFocused(true)
          }
        }}
        // onLoad={() => btnRef.current.focus()}
        sx={{
          display: 'flex',
          width: '100vw',
          // maxWidth: '450px',
          m: 'auto',
          boxShadow: 'none',
          // bgcolor: 'red',
          '.MuiBackdrop-root': {
            width: '100vw',
            // maxWidth: '450px',
            m: 'auto'
          },
          '.MuiDialog-container': {
            display: 'flex',
            alignItems: 'flex-end'
          },
          '.MuiPaper-root': {
            width: '100vw',
            // maxWidth: '450px',
            bgcolor: 'transparent'
          }
        }}
        // hideBackdrop={true}
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <Container disableGutters
          sx={{
            '&.MuiContainer-root': {
              width: '100vw',
              maxWidth: '450px',
              minHeight: '100vh',
              height: 1,
              bgcolor: 'transparent'
            },
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end'
          }}
        >
          <Box sx={{
            [theme.breakpoints.up('sm')]: { height: 0.65 },
            height: 0.7,
            bgcolor: 'primary.main',
            borderTopLeftRadius: '25px',
            borderTopRightRadius: '25px'
          }}>
            <Box sx={{ bgcolor: 'primary.main', borderTopLeftRadius: '25px', borderTopRightRadius: '25px' }}>

            <Box sx={{ width: 1, mb: 2 }}>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close" sx={{ mr: 2, mt: 2 }}>
                  <CloseIcon fontSize="medium" />
                </IconButton>
              </Box>
            </Box>

            <Box sx={{
              display: 'grid',
              [theme.breakpoints.up('sm')]: { mx: 4 },
              mx: 2
            }}>
              <Typography fontFamily="KaiseiDecol" fontWeight={700} fontSize={32} mx="auto" mb={6} mt={4}>
                Play with friends
              </Typography>
              <Typography fontFamily="MundialRegular" fontWeight={300} fontSize={16} mx="auto" my={1.5}>
                I&apos;m the host
              </Typography>
              <Button onClick={setMultiplayerMode} sx={{ mb: 4 }}>
                <Typography fontFamily="DMSansRegular" fontWeight={700} fontSize={18}>
                  Create a new game
                </Typography>
              </Button>

              <Typography fontFamily="MundialRegular" fontWeight={300} fontSize={16} mx="auto" my={1.5}>
                Looking to join a friends game
              </Typography>

              <Button ref={btnRef} sx={{ mb: 3 }} onClick={() => navigate('/game-room')}>
                <Typography fontFamily="DMSansRegular" fontWeight={700} fontSize={18}>
                  Enter Invite Code
                </Typography>
              </Button>
            </Box>

            </Box>
          </Box>
        </Container>
      </Dialog>
    </Box>
  )
}

export default PlayWithFriendsModal
