import React from 'react'
import { Grid, Box, Typography, Rating, useMediaQuery } from '@mui/material'
import { styled } from '@material-ui/styles'
import { useTheme } from '@mui/styles'

const WineBox = styled(Box)((props) => {
  return ({
    position: 'relative',
    backgroundColor: props.bgC,
    borderRadius: '100%',
    width: 75,
    height: 75
  })
})

const WineImg = styled(Box)((props) => ({
  position: 'absolute',
  width: 33,
  left: 22,
  top: -20
}))

const WineHeader = ({ wine, isRated }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <Grid container sx={{ px: 2, my: 4 }} >
      <Grid item xs={3} sx={{ px: 2, m: 'auto' }} >
        <WineBox bgC={wine.backgroundColor} >
            <WineImg component="img" src={wine.image} alt="wine" />
        </WineBox>
      </Grid>
      <Grid item xs={9} sx={{ display: 'grid', m: 'auto', pl: 2 }}>
        <Grid container>
          <Grid item xs={isMobile ? 2.5 : 2} sx={{ display: 'flex' }}>
            <Typography component="p" variant="subtitle" fontFamily="DMSansRegular" fontSize={12} sx={{ color: '#957d70', my: 'auto' }}>
                Wine { wine.index }
            </Typography>
          </Grid>
          <Grid item xs={isMobile ? 9.5 : 10} sx={{ my: 'auto' }} >
            {
              isRated != null &&
              <Box sx={{ display: 'flex', alignContent: 'center' }}>
                <Rating sx={{ transform: 'scale(0.7)', ml: -3 }}
                name="rating"
                readOnly
                value={isRated}
                precision={0.5}
                size="large"
                />
              </Box>
            }
          </Grid>
        </Grid>
        <Typography fontFamily="KaiseiDecolBold" fontSize={18}>{wine.name}</Typography>
        <Typography fontFamily="DMSansRegular" fontSize={16}>{wine.grape}  -  {wine.year}</Typography>
      </Grid>
    </Grid>
  )
}

export default WineHeader
