import React from 'react'
import { Container, Typography, Box } from '@mui/material'

import PlayerTab from './PlayerTab'

const PlayersSection = ({ gameData }) => {
  // console.log('gameData', gameData)
  return (
    <Container disableGutters sx={{ mb: 12 }}>
        <Typography fontFamily="KaiseiDecolBold" fontSize={28} sx={{ m: 3 }} >Players</Typography>
          {
            gameData?.players?.map((player) => (
              <PlayerTab key={player.nickName} tabPlayer={player.nickName} playerColor={player.color} />
            ))
          }
          {
            gameData?.host
              ? <PlayerTab tabPlayer={gameData?.host.host} playerColor={gameData?.host.color} isHost />
              : null
          }
        <InviteHelperText />
    </Container>
  )
}

const InviteHelperText = () => {
  return (
    <Box sx={{ m: 3 }}>
        <Typography component="p" variant="p" paragraph fontFamily="MundialRegular" fontWeight={300} fontSize={18} sx={{ color: '#575757', m: 'auto' }} >
        Tap the button above to Invite your friends to play Wine Smash with you.
        </Typography>
    </Box>
  )
}

export default PlayersSection
