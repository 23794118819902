import { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { usePointsContext } from 'providers/PointsProvider'
import useWinesApi from 'firebaseKit/wines'

const useWineHook = () => {
  const { wineToScore, wineToRate, setWineToScore, setWineToRate } = usePointsContext()
  const { getWine } = useWinesApi()
  const { id } = useParams()

  useEffect(() => {
    const getIt = async () => {
      const wine = await getWine(id)
      setWineToScore(wine)
      setWineToRate(wine)
    }
    getIt()
  }, [])
  return { wineToScore, wineToRate }
}

const getDifference = (a, b) => {
  return Math.abs(a - b)
}
const compareWithExpert = (wineToScore, score) => {
  if (wineToScore && 'score' in wineToScore) {
    const expertScore = wineToScore.score
    let pointsAccum = 0
    const returnPoints = (difference) => {
      switch (difference) {
        case 0:
          pointsAccum += 5
          break
        case 0.5:
          pointsAccum += 4
          break
        case 1:
          pointsAccum += 2.5
          break
        case 1.5:
          pointsAccum += 2
          break
        default:
          pointsAccum += 0
          break
      }
    }
    for (const param in score) {
      const difference = getDifference(score[param], expertScore[param])
      returnPoints(difference)
    }
    return pointsAccum
  }
}

const useSubmitScoring = (wineToScore, wineToRate) => {
  const navigate = useNavigate()
  const { scoring } = useParams()
  const { setWineRating, setWineScore, setWinePoints } = usePointsContext()
  // Rate
  const [score, setScoring] = useState({})
  const [loadingPoints, setLoadingPoints] = useState(false)
  const [points, setPoints] = useState(0)
  const [notes, setNotes] = useState(null)
  const [openResults, setOpenResults] = useState(false)

  const submitScoring = (rate) => {
    setLoadingPoints(true)
    const points = compareWithExpert(wineToScore, score)
    setPoints(points)

    if (scoring === 'just_rating') {
      setWineRating(wineToRate.name, rate)
    } else {
      setWineScore(wineToRate.name, score)
      setWineRating(wineToRate.name, rate)
      setWinePoints(wineToRate.name, points)
    }
    setTimeout(() => {
      setLoadingPoints(false)
      if (scoring === 'just_rating') {
        navigate(`/wine-room/${wineToRate.wineryId}`)
      } else {
        setOpenResults(true)
      }
      // Next view
    }, [1000])
  }
  return {
    loadingPoints,
    notes,
    openResults,
    points,
    score,
    setNotes,
    setOpenResults,
    setScoring,
    submitScoring
  }
}

export { useWineHook, compareWithExpert, useSubmitScoring }
