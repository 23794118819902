const characteristics = {
  aromatics: {
    title: 'Sweetness',
    subtitle: '',
    points: ['a dry wine, not sweet at all', 'a very sweet wine'],
    description: ['Almost all white wines are dry – that\'s to say they don\'t have any noticeable sweetness. Most grapes, such as Chardonnay and Sauvignon Blanc, are always made into dry wines. A few varieties however, like Riesling, can be made with any level of sweetness – dry, off-dry, medium sweet or sweet – and all can be delicious. Dessert wines are usually intensely sweet, and a luxurious treat for anyone with a sweet tooth.']
  },
  body: {
    title: 'Body',
    subtitle: 'How ‘thick’ does it feel?',
    points: ['Light-bodied, moves quickly in your mouth like water', 'Full-bodied, feels heavy and slow-moving in your mouth'],
    description: ['When you roll the wine around your mouth, think about its density. Is it light-bodied, moving quickly in your mouth like water? Or is it full-bodied, moving more slowly, with a heavier or oilier consistency? It is like skimmed milk (light-bodied) full-fat (medium-bodied) or closer to cream (full-bodied)? There are good light-bodied wines just as there are good full-bodied ones – but you might prefer one or the other.']
  },
  ripeness: {
    title: 'Ripeness',
    subtitle: 'How ripe does the wine taste?',
    points: ['Only just ripe', 'Very ripe indeed'],
    description: ['Hot climates make for very ripe grapes – the riper the grape, the more sugar it has to turn into alcohol, and the more it creates concentrated fruity flavours. But if the grapes get too ripe, they can taste unpleasantly cooked, dried or jammy. Cool climates take longer to ripen grapes, and they might only just reach ripeness. Expect more delicate fresh-fruit flavours like green apple and citrus, and sometimes floral aromas.']
  },
  oak: {
    title: 'Oak',
    subtitle: 'How oaky is the wine?',
    points: ['No oak used', 'A very oaky wine'],
    description: ['Winemakers have used oak barrels to mature wines (both reds and whites) for thousands of years. New barrels can give a strong flavour to the wine, akin to vanilla, cinnamon, clove, coconut, smoke or cola. They can also give the wine a smooth, silky texture.', 'Old oak barrels don’t affect the flavour so much, but they can soften the texture of a wine.', 'Some winemakers prefer not to use oak barrels at all – it’s a matter of taste, and barrels are just one of the many tools winemakers can use to create the style of wine they are looking for.']
  },
  alcohol: {
    title: 'Alcohol',
    subtitle: 'How much can you feel it?',
    points: ['A very low-alcohol wine', 'A very high-alcohol wine'],
    description: ['Would we drink wine if it didn’t contain alcohol? Who knows, but it wouldn’t be as much fun! Most wines contain between 12% and 15% alcohol, but what matters most is how the wine tastes. Low alcohol is rarely a problem, but you can sometimes feel a warming sensation after swallowing a high-alcohol wine that some people don’t like. If the wine tastes unbalanced by high levels of alcohol, that’s not very pleasant.']
  },
  tannins: {
    title: 'Tannins',
    subtitle: 'How ‘drying’ does it feel?',
    points: ['Very light in tannin', 'Very powerfully tannic'],
    description: ['If there’s one bit of jargon that’s worth learning when it comes to wine, it’s tannin. Tannin is a compound found in grapes but in itself is tasteless. Instead it gives a sensation, a kind of drying, gripping feeling that makes your tongue feel like its sticking to the roof of your mouth. You’ll know it when you feel it – it’s more prevalent in red wines, and it creates a drying sensation in your mouth after you swallow.']
  },
  acidity: {
    title: 'Acidity',
    subtitle: '',
    points: ['A wine with very low acidity', 'A wine with very high acidity'],
    description: ['Like all fruits, all wines have acidity. It’s what makes them taste refreshing. Take a sip from a glass – if it makes your mouth water, that’s a natural reaction to high acidity.', 'The level of acidity can vary greatly between different wines. They range from very tart, cool climate white wines that make your mouth pucker up and your eyes blink; to very soft, hot climate red wines, where the acidity is only very mild. Either is fine, as long as the wine feels balanced.']
  },
  sweetness: {
    title: 'Acidity',
    subtitle: '',
    points: ['a dry wine, not sweet at all', 'a very sweet wine'],
    description: ['Almost all white wines are dry – in other words, they don’t have any noticeable sweetness. Most grapes, such as Chardonnay and Sauvignon Blanc, are almost always made into dry wines. A few varieties however, like Riesling, can be made with any level of sweetness – dry, off-dry, medium sweet or sweet – and all stages can be delicious. Dessert wines are usually intensely sweet, and a luxurious treat for anyone with a sweet tooth.']
  }
}

export default characteristics
