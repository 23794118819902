import * as React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import { Box, Typography, Slide, List, ListItemText, Dialog, IconButton, Container } from '@mui/material'
import { useTheme } from '@mui/styles'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})
export const AboutExpert = ({ open, setOpen, expertName, expertDetails }) => {
  const theme = useTheme()
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>

     <Dialog
        fullScreen={theme.popUpsWidth}
        sx={{
          display: 'flex',
          width: '100vw',
          // maxWidth: '450px',
          m: 'auto',

          '.MuiBackdrop-root': { display: 'none' },
          '.MuiDialog-container': {
            display: 'flex',
            alignItems: 'flex-end'
          },
          '.MuiPaper-root': {
            width: '100vw',
            // maxWidth: '450px',
            bgcolor: 'transparent'
          }
          // '.MuiDialog-paper': { ml:-5 },
        }}
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <Box sx={{ m: 'auto', maxWidth: '450px', bgcolor: 'primary.main' }}>

        <Container disableGutters sx={{ '&.MuiContainer-root': { width: '100vw', minHeight: '100vh', maxWidth: '450px', bgcolor: 'primary.main' } }}>

        <Box sx={{ width: 1, mt: 4, mb: 2 }} >
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
              sx={{ mr: 2 }}
              >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>

        <List sx={{ px: 4, width: '100%' }}>
          <Box sx={{ mb: 2 }}>
            {expertName && expertDetails
              ? <Typography component="h1" sx={{ fontFamily: 'KaiseiDecolBold' }} fontSize={26}>{expertName}</Typography>
              : <Typography component="h1" sx={{ fontFamily: 'KaiseiDecolBold' }} fontSize={26}>Matt Walls</Typography>}
          </Box>
          <Box button sx={{ my: 2 }}>
            <ListItemText sx={{ }} >
              <Box sx={{ display: 'flex' }}>
                <Typography component="subtitle" sx={{ fontFamily: 'MundialLight' }} fontSize={18}>Our Wine Expert</Typography>
              </Box>
            </ListItemText>
          </Box>
          <Box sx={{ my: 2 }}>
            <ListItemText>
              {expertName && expertDetails
                ? <>{expertDetails
                  .split('\n')
                  .filter((paragraph) => paragraph && paragraph.length > 0)
                  .map((paragraph, idx) =>
                    <Typography key={idx} component="p" sx={{ py: 2, fontFamily: 'MundialLight' }} fontSize={18}>{paragraph}</Typography>
                  )}</>
                : <>
                    <Typography component="p" sx={{ py: 2, fontFamily: 'MundialLight' }} fontSize={18}>Our Director of Wine, Matt Walls, started out selling super strength lager and cigarettes to day drinkers in Brighton.</Typography>
                    <Typography component="p" sx={{ py: 2, fontFamily: 'MundialLight' }} fontSize={18}>That was over 20 years ago, and he’s since worked in every imaginable kind of wine business. He’s visited dozens of different wine regions along the way, writing articles and books about the people, places and wines he’s encountered on his travels. He’s now an award-winning freelance journalist and taster, working closely with Decanter magazine as a contributing editor and panel chair for the Rhône at the Decanter World Wine Awards.</Typography>
                    <Typography component="p" sx={{ py: 2, fontFamily: 'MundialLight' }} fontSize={18}>Matt tastes every wine at Wine Smash to make sure only great bottles get through the doors. He rates each one, so you can compare your impressions against an expert’s.</Typography>
                    <Typography component="p" sx={{ py: 2, fontFamily: 'MundialLight' }} fontSize={18}>“You don’t need some kind of special gift in order to taste and talk about wine,” says Matt, “anyone can do it. And you don’t need any special skills or equipment to get involved, just pull the cork, pour and enjoy. My mission has always been to help other people get the same enjoyment out of wine that I do.”</Typography>
                  </>}
            </ListItemText>
          </Box>
        </List>

      </Container>
      </Box>

      </Dialog>
    </Box>
  )
}

export default AboutExpert
