import React, { useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import { useTheme } from '@mui/styles'

const Splash = () => {
  const theme = useTheme()
  const [move, setMove] = React.useState(false)
  useEffect(() => {
    setMove(true)
  }, [])

  return (
    <Box style={theme.background} sx={{ position: 'relative', display: 'flex', flexDirection: 'column', m: 'auto', overflow: 'hidden' }}>

      <Box component="img" src="/background.svg" alt="" sx={{ position: 'absolute', width: '100%', height: '100%', transform: 'scale(1.25)' }} />

    <Box sx={{ zIndex: 10, height: '10vh', [theme.breakpoints.up('sm')]: { height: '20vh' } }}></Box>
    <Box sx={{
      zIndex: 10,
      height: '90vh',
      [theme.breakpoints.up('sm')]: { height: '80vh' },
      background: 'linear-gradient(to bottom, rgba(255,253,247,0), rgb(250,243,237))'
    }}>

      <Box sx={{ width: '100%', display: 'flex' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', m: 'auto', mt: 5, textAlign: 'center', px: 5 }}>
          <Typography component="p" variant="subtitle" sx={[{
            transition: (theme) => theme.transitions.create('all', {
              duration: theme.transitions.duration.long
            }),
            opacity: 0,
            transform: 'translate(0px, -20px)',
            fontFamily: 'MundialRegular',
            fontWeight: 600,
            color: 'rgb(38,43,46)',
            fontSize: 16
          },
          move && { opacity: 1, transform: 'translate(0px, 0px)' }]}>Welcome to</Typography>

            <Box sx={{ height: 240, display: 'flex', m: 'auto' }}>
            <Box component="img" src="/wineSmash.svg" sx={[
              {
                width: 60,
                m: 'auto',
                py: 2,
                transition: (theme) => theme.transitions.create('all', {
                  duration: theme.transitions.duration.long
                })
              },
              move && { transform: 'scale(5)' }
            ]}></Box>
          </Box>

          <Typography component="h1" variant="h3" sx={[{
            transition: (theme) => theme.transitions.create('all', {
              duration: theme.transitions.duration.long
            }),
            fontFamily: 'KaiseiDecolMed',
            transform: 'translate(0px, 20px)',
            pt: 4
          },
          move && { transform: 'translate(0px, 0px)' }
          ]} >Only Great Wines</Typography>
          <Typography sx={[{
            transition: (theme) => theme.transitions.create('all', {
              duration: theme.transitions.duration.long
            }),
            pt: 2,
            opacity: 0,
            transform: 'translate(0px, 15px)',
            textAlign: 'center',
            fontFamily: 'MundialLight',
            fontSize: 20
          },
          move && { opacity: 1, transform: 'translate(0px, 0px)' }
          ]}>Grab a glass and come experience <br/> Wine Tasting with us!</Typography>
        </Box>
      </Box>

    </Box>
    </Box>
  )
}

export default Splash
