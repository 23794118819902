import React, { useState, useEffect } from 'react'
import { useTheme } from '@mui/styles'
import { Box } from '@mui/material'
import PlayButtons from './PlayButtons'
import PlayWithFriendsModal from './PlayWithFriendsModal'
import WelcomeHeader from 'core/WelcomeHeader'
// import { useGameMode } from 'providers/GameMode'

const GameMode = () => {
  const theme = useTheme()
  // const { gameData, currentRound, player, setCurrentRound } = useGameMode()
  const [openModal, setopenModal] = useState(false)
  const handleOpen = () => setopenModal(true)
  const handleClose = () => setopenModal(false)

  // useEffect(() => {
  //   setCurrentRound({ loading: false })
  // }, [])
  // console.log('game mode---', gameData, currentRound, player)
  return (
    <Box style={theme.background} sx={{ display: 'flex', flexDirection: 'column', m: 'auto', height: 1 }} >

      <Box sx={{ [theme.breakpoints.up('sm')]: { mx: 4 }, mx: 2, my: 4 }}>
        <WelcomeHeader />

        <PlayButtons handleOpen={handleOpen} />

      </Box>
        {
          openModal
            ? <PlayWithFriendsModal handleClose={handleClose} />
            : null
        }
    </Box>
  )
}

export default GameMode
